import { Component, EventEmitter, Output, Input, OnChanges, SimpleChanges, OnInit, ViewChild, ElementRef, forwardRef, OnDestroy } from '@angular/core';
import { BodyService } from '../../services';
import { ControlValueAccessor, Validator, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl, NgModel } from '@angular/forms';
import { NgForm } from '@angular/forms';
import { HashTable } from '../../../Helper/index';

@Component({
  selector: 'list',
  templateUrl: './list.component.html'
})
export class ListComponent implements OnChanges, OnInit {
  @Input() displayingText: string;
  @Input() data: HashTable<any>[];
  @Input() isReadOnly = false;
  @Output() itemClickRequest = new EventEmitter<any>();
  @Output() itemDoubleClickRequest = new EventEmitter<any>();

  currentItem: HashTable<any>;
  currentDisplayingTextKey: string;


  constructor() {
    this.data = [];
    this.currentDisplayingTextKey = '';
  }

  ngOnInit() {
    this.currentDisplayingTextKey = this.displayingText;
  }

  ngOnChanges(changes: SimpleChanges) {

  }

  ngOnDestroy() {
  }

  getDisplayingText = (item: HashTable<any>): string => {
    if (!item)
      return '';
    return item[this.currentDisplayingTextKey];
  }

  isDisplayingText = (item: HashTable<any>): boolean => {
    return !item || item[this.currentDisplayingTextKey] != '';
  }


  onSelectItem = (selectedModel: HashTable<any>): void => {
    if (this.isReadOnly)
      return;

    if (this.currentItem != selectedModel) {
      this.currentItem = selectedModel;

      this.itemClickRequest.emit(this.currentItem);
    }
  }

  doubleClick(selectedModel: HashTable<any>) {
    if (this.currentItem != selectedModel) {
      this.currentItem = selectedModel;
    }

    this.itemDoubleClickRequest.emit(this.currentItem);
  }
}
