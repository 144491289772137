﻿//------------------------------------------------------------------------------
//  This code was generated by typescript template tool 'Typewriter'.
//
//  Changes to this file may cause incorrect behavior and will be lost if
//  the code is regenerated.
//------------------------------------------------------------------------------



export class UserPositionViewModel   {
    
    id: number;
    positionId: number;
    positionName: string;
    personId: number;
    personName: string;
    startDate: any;
    endDate?: any;
}




  
