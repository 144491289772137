import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from "@angular/router";

import { LayoutAdministratorComponent } from './layout-admin.component';
import { HomeComponent } from './home/home.component';
import { UsersComponent } from './users/users.component';
import { SalaryComponent } from './salary/salary.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { AuthGuard } from '../../services/index';
import * as components from '../../components';
//import { DropDownComponent } from '../../components/drop-down/drop-down.component';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TabsModule } from 'ngx-bootstrap/tabs'
import { ToothTypeSelectorComponent } from './tooth-type-selector/tooth-type-selector.component';
import { RegionsComponent } from './regions/regions.component';
import { ClinicModalComponent } from '../../components/clinic-modal/clinic-modal.component';
import { UserModalComponent } from '../../components/user-modal/user-modal.component';
import { CurrencyComponent } from './currency/currency.component';
import { WorkTypeComponent } from './work-type/work-type.component';
import { WorkComponent } from './work/work.component';
import { ProtezPageComponent } from './protez-page/protez-page.component';
import { RegionProtezComponent } from './region-protez/region-protez.component';
import { SalaryBookComponent } from './salary-book/salary-book.component';
import { ArchiveComponent } from './archive/archive.component';
import { PositionComponent } from './position/position.component';
import { OrderDiaryComponent } from './order-diary/order-diary.component';
import { HttpClientModule } from '@angular/common/http';
import { JobStateComponent } from './job-state/job-state.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JobTimerComponent } from './job-timer/job-timer.component';
import { CommonModule } from '@angular/common';
import { JobTimerItemComponent } from './job-timer-item/job-timer-item.component';
import { OtherSettingsComponent } from './other-settings/other-settings.component';

const layoutTechincianRoutes: Routes = [{
  path: 'administrator',
  component: LayoutAdministratorComponent,
  canActivate: [AuthGuard],
  children: [
    { path: '', component: HomeComponent, data: { title: 'Заказы' } },
    { path: 'users', component: UsersComponent, data: { title: 'Пользователи' } },
    { path: 'order-diary', component: OrderDiaryComponent, data: { title: 'Ежедневник' } },
    { path: 'salary', component: SalaryComponent, data: { title: 'Начисление/Выдача зарплат' } },
    { path: 'currency', component: CurrencyComponent, data: { title: 'Валюта' } },
    { path: 'work-type', component: WorkTypeComponent, data: { title: 'Цех' } },
    { path: 'position', component: PositionComponent, data: { title: 'Должность' } },
    { path: 'work', component: WorkComponent, data: { title: 'Work' } },
    //{ path: 'work-shop/:id', component: WorkShopPositionComponent, data: { title: 'Должность' } },
    //{ path: 'work-shop/:id/user-position/:pid', component: UserPositionComponent, data: { title: 'Пользователи в должности' } }
    { path: 'protez', component: ProtezPageComponent, data: { title: 'Протезы' } },
    { path: 'region-protez', component: RegionProtezComponent, data: { title: 'Индивидуальные цены' } },
    { path: 'salary-book', component: SalaryBookComponent, data: { title: 'Зарплатный лист' } },
    { path: 'job-state', component: JobStateComponent, data: { title: 'Справочник состояний' } },
    { path: 'job-timer', component: JobTimerComponent, data: { title: 'Таймер' } },
    { path: 'job-timer-item', component: JobTimerItemComponent, data: { title: 'Таймер' } },
    { path: 'job-timer-item/:id', component: JobTimerItemComponent, data: { title: 'Таймер' } },
    { path: 'other-settings', component: OtherSettingsComponent, data: { title: 'Разное' } },
    { path: 'archive', component: ArchiveComponent, data: { title: 'Архив' } }
    //,
    //{ path: 'region', component: RegionsComponent, data: { title: 'Region' } }
  ]
}];


let imports: any = [
  BrowserModule,
  FormsModule,
  HttpClientModule,
  RouterModule.forChild(layoutTechincianRoutes)
];

//add all modules for component
imports.push(...Object.keys(components).map(key => components[key]));

@NgModule({
  declarations: [
    LayoutAdministratorComponent,
    HomeComponent,
    NavMenuComponent,
    UsersComponent,
    SalaryComponent,
    ToothTypeSelectorComponent,
    RegionsComponent,
    ClinicModalComponent,
    UserModalComponent,
    CurrencyComponent,
    WorkTypeComponent,
    WorkComponent,
    OrderDiaryComponent,
    PositionComponent,
    ProtezPageComponent,
    RegionProtezComponent,
    SalaryBookComponent,
    ArchiveComponent,
    JobStateComponent,
    JobTimerComponent,
    JobTimerItemComponent,
    OtherSettingsComponent
  ],
  imports: [imports,
    CommonModule,
    ColorPickerModule,
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    TabsModule.forRoot()],
  providers: [],
  exports: [
    ColorPickerModule,
    LayoutAdministratorComponent,
    HomeComponent,
    NavMenuComponent,
    UsersComponent,
    SalaryComponent,
    ToothTypeSelectorComponent,
    RegionsComponent,
    CurrencyComponent,
    WorkTypeComponent,
    WorkComponent,
    ProtezPageComponent,
    RegionProtezComponent,
    SalaryBookComponent,
    ArchiveComponent]
})
export class LayoutAdministratorModule { }
