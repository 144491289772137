import { Injectable } from '@angular/core';

@Injectable()
export class InputValidationService {

  constructor() {
  }

  private getChar(event: any) {
    if (event.which == null) { // IE
      if (event.keyCode < 32) return null; // special character
      return String.fromCharCode(event.keyCode)
    }

    if (event.which != 0 && event.charCode != 0) { // all except IE
      if (event.which < 32) return null; // special character
      return String.fromCharCode(event.which); // rest
    }

    return null; // special character
  }
  validateInput(event: any, maxValue: number, allowDots: boolean) {
    if (event.srcElement.validity.badInput) {
      event.preventDefault ? event.preventDefault() : event.returnValue = false;
      return;
    }

    const valuepattern = allowDots ? /^[^\D.0][0-9.]*$/ : /^[^\D.0][0-9]*$/;
    let inputValue = event.srcElement.value + this.getChar(event);

    if (!valuepattern.test(inputValue) || inputValue.length > maxValue) {
      event.preventDefault ? event.preventDefault() : event.returnValue = false;
    }
  }

  validateInputWhichAllowsZero(event: any, maxValue: number, allowDots: boolean) {
    if (event.srcElement.validity.badInput) {
      event.preventDefault ? event.preventDefault() : event.returnValue = false;
      return;
    }

    const valuepattern = allowDots ? /^[^\D.][0-9.]*$/ : /^[^\D.][0-9]*$/;
    let inputValue = event.srcElement.value + this.getChar(event);

    if (!valuepattern.test(inputValue) || inputValue.length > maxValue) {
      event.preventDefault ? event.preventDefault() : event.returnValue = false;
    }
  }
}
