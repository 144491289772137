﻿//------------------------------------------------------------------------------
//  This code was generated by typescript template tool 'Typewriter'.
//
//  Changes to this file may cause incorrect behavior and will be lost if
//  the code is regenerated.
//------------------------------------------------------------------------------

import { IBaseModel } from "./Dental-DataModel-Interfaces-IBaseModel";

export class JobView  implements IBaseModel {
    
    jobId: number;
    number: number;
    doctorId: number;
    personId?: number;
    doctorFirstName: string;
    doctorLastName: string;
    doctorMidleName: string;
    technicFirstName: string;
    technicLastName: string;
    technicMidleName: string;
    dueDate: any;
    compulsory: boolean;
    colorKey: string;
    colorId?: number;
    patient: string;
    technicanComment: string;
    doctorComment: string;
    clinicName: string;
    clinicId: number;
    startDate: any;
    clinicPrefix: string;
    currencyId: number;
    price: number;
    currencyName: string;
    endDate?: any;
    archived: boolean;
    archivedDate?: any;
    workName: string;
    workId?: number;
    jobStateId?: number;
    jobStateName: string;
    stateColor: string;
    rowColor: string;
    isSent: boolean;
    comments: string;
}




  
