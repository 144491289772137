import { Component, EventEmitter, Output, Input, OnChanges, SimpleChanges, OnInit, ViewChild, ElementRef, forwardRef, OnDestroy } from '@angular/core';
import { BodyService } from '../../services';
import { ControlValueAccessor, Validator, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl, NgModel } from '@angular/forms';
import { NgForm } from '@angular/forms';
import { HashTable } from '../../../Helper/index';
import { ProtezView, BaseDropDownViewModel, Protez } from '../../../models';

export class ToothModel {
  name: string;
  state: number;
  position: number;
  type?: Protez;
  realPosition: number;
}

@Component({
  selector: 'tooth',
  templateUrl: './tooth.component.html'
})
export class ToothComponent implements OnChanges, OnInit {
  @Input() tooth: ToothModel;
  @Input() isEditable: boolean;
  @Input() toothType: Protez;
  @Output() change: EventEmitter<ToothModel> = new EventEmitter<ToothModel>();

  currentType: Protez; 
  @Input() isInValid: boolean;

  currentElement: Element;
  emptyOne: Protez = {
    id: 0,
    name: '',
    protezTypeId: 0
  };
  constructor() {
    this.tooth = {
      type: {}
    } as ToothModel;
    this.currentType = {} as Protez; 
    this.isInValid = false;
  }

  ngOnInit() {
    
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["toothType"] && changes["toothType"].currentValue) {
      this.currentType = this.tooth.type.id ? this.tooth.type: this.toothType;
    }
  }

  onToothStateChange = (state: number) => {
    this.tooth.state = state;
    if (this.tooth.state == 0)
      this.currentType = this.toothType;
    else {
      this.tooth.type = this.currentType;
    }

    this.change.emit(this.tooth);
  }

  getStateText = (): string => {
    switch (this.tooth.state) {
      case 0: return '-';
      case 1: return 'x';
      case 2: return this.tooth.position.toString();
    }
  }
   
  hasState = (state: number): boolean => this.tooth.state == state;
}
