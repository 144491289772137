﻿//------------------------------------------------------------------------------
//  This code was generated by typescript template tool 'Typewriter'.
//
//  Changes to this file may cause incorrect behavior and will be lost if
//  the code is regenerated.
//------------------------------------------------------------------------------


import { Injectable } from "@angular/core";
import { ResponseContentType, Response } from "@angular/http";
import { Observable } from 'rxjs';
import { BasApiService } from '../base.api.service';

import * as ApiTypes from "../../models";

@Injectable()
export class DictionaryApiService {
  
  constructor(public baseService: BasApiService) {
   
  }
  
  getClinicList = (): Observable<ApiTypes.RegionDictionaryViewModel[]> =>
    this.baseService.get<ApiTypes.RegionDictionaryViewModel[]>(`api/Dictionary/getcliniclist`, {}, true, true);   
  
  getPersonTypes = (): Observable<ApiTypes.BaseDropDownViewModel<number>[]> =>
    this.baseService.get<ApiTypes.BaseDropDownViewModel<number>[]>(`api/Dictionary/getpersontypelist`, {}, true, true);   
  
  getProtezes = (): Observable<ApiTypes.BaseDropDownViewModel<number>[]> =>
    this.baseService.get<ApiTypes.BaseDropDownViewModel<number>[]>(`api/Dictionary/getprotezlist`, {}, true, true);   
  
  getProtezesWithUkrPrices = (): Observable<ApiTypes.ProtezPriceViewModel[]> =>
    this.baseService.get<ApiTypes.ProtezPriceViewModel[]>(`api/Dictionary/getprotezlist_with_ukr_price`, {}, true, true);   
  
  getCurrencyTypes = (): Observable<ApiTypes.BaseDropDownViewModel<number>[]> =>
    this.baseService.get<ApiTypes.BaseDropDownViewModel<number>[]>(`api/Dictionary/getcurrencylist`, {}, true, true);   
  
  getCurrencies = (): Observable<ApiTypes.CurrencyViewModel[]> =>
    this.baseService.get<ApiTypes.CurrencyViewModel[]>(`api/Dictionary/getcurrencies`, {}, true, true);   
  
  getColors = (): Observable<ApiTypes.Color[]> =>
    this.baseService.get<ApiTypes.Color[]>(`api/Dictionary/getcolors`, {}, true, true);   
  
  getProtezTypes = (): Observable<ApiTypes.ProtezType[]> =>
    this.baseService.get<ApiTypes.ProtezType[]>(`api/Dictionary/getproteztypes`, {}, true, true);   
  
  getWorks = (): Observable<ApiTypes.BaseDropDownViewModel<number>[]> =>
    this.baseService.get<ApiTypes.BaseDropDownViewModel<number>[]>(`api/Dictionary/getworks`, {}, true, true);   
  
  getWorkTypes = (): Observable<ApiTypes.BaseDropDownViewModel<number>[]> =>
    this.baseService.get<ApiTypes.BaseDropDownViewModel<number>[]>(`api/Dictionary/getworktypes`, {}, true, true);   
  
  getPositions = (): Observable<ApiTypes.BaseDropDownViewModel<number>[]> =>
    this.baseService.get<ApiTypes.BaseDropDownViewModel<number>[]>(`api/Dictionary/getpositions`, {}, true, true);   
  
  }

