﻿//------------------------------------------------------------------------------
//  This code was generated by typescript template tool 'Typewriter'.
//
//  Changes to this file may cause incorrect behavior and will be lost if
//  the code is regenerated.
//------------------------------------------------------------------------------

import { FilteredGridRequestViewModel } from "./Dental-WebCore-Models-FilteredGridRequestViewModel";
import { WorkShopPositionViewFilterModels } from "./Dental-WebCore-Models-FilterModels-WorkShopPositionViewFilterModels";

export class WorkShopPositionQueryModel extends FilteredGridRequestViewModel<WorkShopPositionViewFilterModels>  {
    
}




  
