import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { GridComponent } from "./grid.component";
import { FormsModule } from '@angular/forms';
import { GridPagerComponent } from "./grid-pager.component";
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [GridComponent, GridPagerComponent],
  imports: [RouterModule, CommonModule, FormsModule, TranslateModule],
    exports: [GridComponent, GridPagerComponent]
})

export class GridModule { }
