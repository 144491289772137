﻿//------------------------------------------------------------------------------
//  This code was generated by typescript template tool 'Typewriter'.
//
//  Changes to this file may cause incorrect behavior and will be lost if
//  the code is regenerated.
//------------------------------------------------------------------------------

import { IModel } from "./Dental-DataModel-Interfaces-IModel";
import { Person } from "./Dental-DataModel-Models-Person";

export class Region  implements IModel {
    
    id: number;
    prefix: string;
    email: string;
    address: string;
    phone: string;
    advanced: string;
    name: string;
    currencyId: number;
    persons: Person[];
}




  
