import { Component, EventEmitter, Output, Input, OnChanges, SimpleChanges, OnInit, ViewChild, ElementRef, forwardRef, OnDestroy } from '@angular/core';
import { BodyService } from '../../services';
import { ControlValueAccessor, Validator, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl, NgModel } from '@angular/forms';
import { NgForm } from '@angular/forms';
import { HashTable } from '../../../Helper/index';
import { Event } from '@angular/router';

@Component({
  selector: 'check-list',
  templateUrl: './check-list.component.html'
})
export class CheckListComponent implements OnChanges, OnInit {
  @Input() displayingText: string;
  @Input('name') componentName: string;
  @Input() selectedItems: HashTable<any>[];
  @Input() data: HashTable<any>[];
  @Input("checkAll") checkAllShow: boolean;
  @Input() title: string;
  @Output() itemClickRequest = new EventEmitter<HashTable<any>[]>();

  curentData: HashTable<any>[];
  currentItem: HashTable<any>;
  currentDisplayingTextKey: string;
  checkAll: boolean;

  constructor() { 
    this.curentData = [];
    this.currentDisplayingTextKey = '';
    this.checkAll = false;
  }

  ngOnInit() {
    this.currentDisplayingTextKey = this.displayingText; 
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["selectedItems"] && changes["selectedItems"].currentValue) { 
      this.initCheck(changes["selectedItems"].currentValue);
    }

    if (changes["data"] && changes["data"].currentValue) {
      this.curentData = [];
      this.curentData.push(...changes["data"].currentValue);
      this.curentData.forEach(s => s.check = false);
      this.initCheck(this.selectedItems);      
    }
  
  }

  initCheck = (selectedData: HashTable<any>[]): void => {
    if (!selectedData || !this.curentData || this.curentData.length == 0 || selectedData.length == 0)
      return;

    selectedData.forEach(item => {
      this.curentData.filter(s => s == item).map(s => s.check = true);
    });
  }

  ngOnDestroy() {
  }

  getDisplayingText = (item: HashTable<any>): string => {
    if (!item)
      return '';
    return item[this.currentDisplayingTextKey];
  }

  isDisplayingText = (item: HashTable<any>): boolean => {
    return !item || item[this.currentDisplayingTextKey] != '';
  }


  onSelectItem = (selectedModel: HashTable<any>, $event: Event): void => {
    this.sendData();
  }

  onSelectItemAll = ($event: Event): void => {
    this.curentData.forEach(s => s.check = this.checkAll);   
    this.sendData();
  }

  sendData = (): void => {
    let data = [];
    data.push(...this.curentData.filter(s => s.check));
    this.itemClickRequest.emit(data);
  }
}
