﻿//------------------------------------------------------------------------------
//  This code was generated by typescript template tool 'Typewriter'.
//
//  Changes to this file may cause incorrect behavior and will be lost if
//  the code is regenerated.
//------------------------------------------------------------------------------

import { IBaseModel } from "./Dental-DataModel-Interfaces-IBaseModel";

export class PersonView  implements IBaseModel {
    
    personId: number;
    firstName: string;
    lastName: string;
    middleName: string;
    address: string;
    phone: string;
    email: string;
    regionId?: number;
    dateCreation: any;
    regionName: string;
    regionAddress: string;
    loginName: string;
    personTypeId: number;
    personType: string;
    isFired: boolean;
    firedDate?: any;
    birthdayDate?: any;
    description: string;
    workTypeId?: number;
    workTypeName: string;
    positionId?: number;
    positionName: string;
    baseCurrency?: number;
    prefix: string;
    viberCode: string;
    viberUserId: string;
    sendViberMessage: boolean;
}




  
