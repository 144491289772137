import { Injectable, HostListener, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';

@Injectable()
export class BodyService {
  private screen: number;
  private screenWidth: Subject<number> = new Subject<number>();
  private click: Subject<MouseEvent> = new Subject<MouseEvent>();

  constructor() {
  }

  public getClick(): Observable<MouseEvent> {
    return this.click.asObservable();
  }

  public emitClick(event: MouseEvent) {
    this.click.next(event);
  }
}

