import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from "@angular/router";
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { LayoutAuthComponent } from './layout-auth.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { PriceComponent } from './price/price.component';
import { GalleryComponent } from './gallery/gallery.component';
import { VacancyComponent } from './vacancy/vacancy.component';
import { ContactsComponent } from './contacts/contacts.component';
import { LoginFormComponent, SliderComponent, MainHeaderComponent } from './components';
import { FormsModule } from '@angular/forms';
import { AuthGuard } from '../../services/index';
import { HttpClientModule } from '@angular/common/http';

const layoutAuthRoutes: Routes = [{
  path: '',
  component: LayoutAuthComponent,
  canActivate: [AuthGuard],
  children: [
    { path: '', component: HomeComponent, data: { title: 'Home' } },
    { path: 'price', component: PriceComponent, data: { title: 'Price' } },
    { path: 'gallery', component: GalleryComponent, data: { title: 'Gallery' } },
    { path: 'vacancy', component: VacancyComponent, data: { title: 'Vacancy' } },
    { path: 'contacts', component: ContactsComponent, data: { title: 'Contacts' } }
  ]
}];

@NgModule({
  declarations: [
    LayoutAuthComponent,
    HomeComponent,
    PriceComponent,
    GalleryComponent,
    VacancyComponent,
    NavMenuComponent,
    ContactsComponent,
    LoginFormComponent,
    SliderComponent,
    MainHeaderComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterModule.forChild(layoutAuthRoutes),
    CommonModule,
    TranslateModule,
    FormsModule
  ],
  providers: [],
  exports: [
    LayoutAuthComponent,
    HomeComponent,
    PriceComponent,
    GalleryComponent,
    VacancyComponent,
    NavMenuComponent,
    ContactsComponent,
    LoginFormComponent,
    SliderComponent,
    MainHeaderComponent
  ]
})
export class LayoutAuthModule { }
