﻿//------------------------------------------------------------------------------
//  This code was generated by typescript template tool 'Typewriter'.
//
//  Changes to this file may cause incorrect behavior and will be lost if
//  the code is regenerated.
//------------------------------------------------------------------------------

import { GridRequestModel } from "./Dental-WebCore-Models-GridRequestModel";

export class BaseViewGridModel<T> extends GridRequestModel  {
    
    viewModelList: T[];
    viewModelItem: T;
    totalCount: number;
}




  
