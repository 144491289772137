import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { commonSetting } from '../../services/common.settings';
import { Subscription, fromEvent } from 'rxjs';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.less']
})
export class DatePickerComponent implements OnInit {
  @Input() placeholder = '';
  @Input() showTodayBtn = true;
  @Input() dtValue = new Date();
  @Input() readonly = false;
  @Output() dtValueChange = new EventEmitter<any>();

  themeName = commonSetting.datePickerName;
  isOpen: boolean = false;
  customButtons$;
  customButtonsSubscription: Subscription;
  isShowed = false;
  /* dpCustomButtons: string = `<div class="d-flex justify-content-center mt-n1">
                <button id="dpTodayButton" class="two btn btn-success dpCustomButton">Today</button>
                <button id="dpResetButton" class="two btn btn-primary dpCustomButton">Reset</button>
                <button id="dpCloseButton" class="two btn btn-danger dpCustomButton">Close</button>
                </div>`; */
  dpCustomButtons: string = `<div class="d-flex justify-content-center mt-n1">
                  <button id="dpTodayButton" class="two btn btn-primary dpCustomButton">Сегодня</button>
                  </div>`;

  constructor() { }

  ngOnInit() {
  }

  onChage = (val: any) => {
    this.onDateChange(val);
  }

  private onDateChange = (val: any) => {
    if (!this.isShowed)
      return;
    this.dtValue = val;
    this.dtValueChange.emit(this.dtValue);
  }

  onShown(value: string): void {
    this.isShowed = true;
    if (!this.showTodayBtn)
      return;

    var d1 = document.querySelector('.bs-datepicker-container');

    d1.insertAdjacentHTML('beforeend', this.dpCustomButtons);

    const dpTodayButton = document.getElementsByClassName('dpCustomButton');
    this.customButtons$ = fromEvent(dpTodayButton, 'click')

    this.customButtonsSubscription = this.customButtons$.subscribe((e: any) => {
      console.log(e.target.id);
      this.circulateEvent(e);
      this.hideDatePicker();
    })
  }

  circulateEvent(e) {
    switch (e.target.id) {
      case 'dpTodayButton':
        this.setDate(new Date());
        this.hideDatePicker();
        break;

      case 'dpResetButton':
        this.setDate(null);
        this.hideDatePicker();
        console.log("Reset button clicked");
        break;

      case 'dpCloseButton':
        this.hideDatePicker();
        console.log("Close button clicked");
        break;

    }
  }

  setDate(val: any) {
    this.onDateChange(val);
  }

  hideDatePicker() {
    this.isOpen = false;
  }

}
