import { Component, OnInit } from '@angular/core';
import { JobTimerApiService } from '../../../../api/index';
import { BaseViewGridModel, FilteredGridRequestViewModel, GridRequestModel, JobTimerViewFilterModel, JobTimerViewModel } from '../../../../models/index';
import { HashTable } from '../../../../Helper/index';
import { ControlType, IGridOptions } from '../../../components/grid/grid.component';
import { NotificationService } from '../../../services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-job-timer',
  templateUrl: './job-timer.component.html',
  styleUrls: ['./job-timer.component.less']
})
export class JobTimerComponent implements OnInit {
  jobTimerRequestModel: FilteredGridRequestViewModel<JobTimerViewFilterModel>;
  gridData: BaseViewGridModel<JobTimerViewModel>;
  fieldOptions: HashTable<IGridOptions>;
  showDeleteDialog: boolean;
  selectedModel: JobTimerViewModel;

  constructor(private jobTimerService: JobTimerApiService,
    private notify: NotificationService,
    private router: Router) {
    this.jobTimerRequestModel = {} as FilteredGridRequestViewModel<JobTimerViewFilterModel>;

    this.gridData = {
      viewModelList: []
    } as BaseViewGridModel<JobTimerViewModel>;

    this.fieldOptions = {
      protezName: {
        title: 'Протез'
      },
      workTypeName: {
        title: 'Цех'
      },
      positionName: {
        title: 'Должность'
      },
      jobStateName: {
        title: 'Состояние'
      },
      timeHour: {
        title: 'Таймер (часов)'
      },
      edit: {
        fieldType: {
          type: ControlType.button,
          defaultText: 'Изменить',
          class: 'btn btn-info',
          click: (item: JobTimerViewModel) => this.editButtonClick(item)
        },
        order: false
      },
      delete: {
        fieldType: {
          type: ControlType.button,
          defaultText: 'Удалить',
          class: 'btn btn-danger',
          click: (item: JobTimerViewModel) => this.deleteButtonClick(item)
        },
        order: false
      }
    };
  }

  ngOnInit() {
    this.initGrid();
  }

  initGrid = () => {
    this.jobTimerService.getAll(this.jobTimerRequestModel).subscribe(s => {
      this.gridData = { ...s };
      console.log(this.gridData);
    });
  }

  deleteButtonClick = (model: JobTimerViewModel) => {
    this.showDeleteDialog = true;
    this.selectedModel = { ...model };
  }

  editButtonClick = (model: JobTimerViewModel) => {
    this.router.navigate([`/administrator/job-timer-item/${model.id}`])
  }

  gridRequestEvent = (model: GridRequestModel) => {
    this.jobTimerRequestModel = model as FilteredGridRequestViewModel<JobTimerViewFilterModel>;
    this.initGrid();
  }

  rowClickEvent = (model: JobTimerViewModel) => {
    /*  this.selectedModel = { ...model };
     this.showDialog = true; */
  }

  approveDelete = (model: JobTimerViewModel) => {
    if (!this.selectedModel) {
      this.showDeleteDialog = false;
      return;
    }

    this.jobTimerService.delete(this.selectedModel.id).subscribe(_ => {
      this.notify.showInfo("Запись удалена!");
      this.showDeleteDialog = false;
      this.initGrid();
    });
  }

  closeDeleteDialog = ($event) => this.showDeleteDialog = false;

  /*   onNextStep = (stepNum: number) => {
      this.currentStepNum = stepNum;
    }
  
    onPrevSterp = (stepNum: number) => {
      this.currentStepNum = stepNum;
    } */
}
