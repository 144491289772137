import { Component, EventEmitter, Output, Input, OnChanges, SimpleChanges, OnInit, ViewChild, ElementRef, forwardRef, OnDestroy } from '@angular/core';
import { ProtezView, Protez } from '../../../models';

class ExtProtezView extends ProtezView {
  vertical: number;
  horisontal: number;
  toothNumber: number;
}


class Position {
  vertical: number;
  horisontal: number;
}

@Component({
  selector: 'removable-protez',
  templateUrl: './removable-protez.component.html'
})
export class RemovaleProtezComponent implements OnChanges, OnInit {
  @Input() protezData: ProtezView[];
  @Input() toothTypeData: Protez[];
  @Output() change: EventEmitter<ProtezView[]> = new EventEmitter<ProtezView[]>();

  toothTypes: Protez[];
  protezes: Protez[];
  newItem: Protez;
  oldItem: Protez;

  constructor() {
    this.toothTypes = [];
    this.protezes = [];
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["protezData"] && changes["protezData"].currentValue) {
      this.protezes = [];
      this.protezes.push(...this.protezData.filter(s => s.protezTypeId == 1).map(s => {
        return {
          id: s.protezId,
          protezTypeId: s.protezTypeId,
          name: s.protezName
        } as Protez;
      }));

      this.protezes.forEach(item => {
        this.toothTypes = this.toothTypes.filter(s => s.id != item.id)
      });
    }

    if (changes["toothTypeData"] && changes["toothTypeData"].currentValue) {
      this.toothTypes = [];
      this.toothTypes.push(...this.toothTypeData.filter(s => s.protezTypeId == 1));
    }
  }

  add = (): void => {
    if (!this.newItem)
      return;

    this.protezes.push(this.newItem);
    this.toothTypes = this.toothTypes.filter(s => s.id != this.newItem.id);
    this.newItem = null;
    this.sendChanges();
  }

  remove = (): void => {
    if (!this.oldItem)
      return;

    this.toothTypes.push(this.oldItem);
    this.toothTypes.sort((a, b) => {
      if (a.id < b.id)
        return -1;
      if (a.id > b.id)
        return 1;
      return 0;
    });
    this.protezes = this.protezes.filter(s => s.id != this.oldItem.id);
    this.oldItem = null;
    this.sendChanges();
  }

  onDoubleClickOldItem = (item: Protez): void => {
    this.oldItem = item;
    this.remove();
  }

  onDoubleClickNewItem = (item: Protez): void => {
    this.oldItem = item;
    this.add();
  }


  onSelectOldItem = (item: Protez): void => {
    this.oldItem = item;
  }

  onSelectNewItem = (item: Protez): void => {
    this.newItem = item;
  }

  sendChanges = (): void => {
    let values: ProtezView[] = this.protezes.map(s => {
      return {
        protezName: s.name,
        protezTypeId: s.protezTypeId,
        protezId: s.id,
        position: -1
      } as ProtezView;
    });

    this.change.emit(values);
  }
}
