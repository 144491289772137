﻿//------------------------------------------------------------------------------
//  This code was generated by typescript template tool 'Typewriter'.
//
//  Changes to this file may cause incorrect behavior and will be lost if
//  the code is regenerated.
//------------------------------------------------------------------------------

import { Person } from "./Dental-DataModel-Models-Person";

export class PersonModel extends Person  {
    
    fullName: string;
    login: string;
}




  
