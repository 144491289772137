import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from "@angular/router";

import { LayoutTaskmasterComponent } from './layout-taskmaster.component'; 
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { AuthGuard } from '../../services/index';
import * as components from '../../components';
import { TechincianComponent } from './techincian/techincian.component';
import { HomeComponent } from '../techincian/home/home.component'; 
import { HomeModule } from '../techincian/home/home.module'; 
import { HttpClientModule } from '@angular/common/http';

const layoutTaskmasterRoutes: Routes = [{
  path: 'taskmaster',
  component: LayoutTaskmasterComponent,
  canActivate: [AuthGuard],
  children: [
    { path: '', component: HomeComponent, data: { title: 'Работы', headerTitle: 'Пользователь - Бригадир - Работы' } },
    { path: 'techincian', component: TechincianComponent, data: { title: 'Рабы' } }
  ]
}];


let imports: any = [
  BrowserModule,
  FormsModule,
  HomeModule,
  HttpClientModule, 
  RouterModule.forChild(layoutTaskmasterRoutes)
];

//add all modules for component
imports.push(...Object.keys(components).map(key => components[key]));

@NgModule({
  declarations: [
    LayoutTaskmasterComponent,
    TechincianComponent,
    NavMenuComponent
  ],
  imports: [imports],
  providers: [],
  exports: [LayoutTaskmasterComponent, NavMenuComponent, TechincianComponent]
})
export class LayoutTaskmasterModule { }
