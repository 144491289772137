import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router"; 
import { CommonModule } from "@angular/common";
import { SimpleCalendarInlineComponent } from "./simple-calendar-inline.component";
@NgModule({
  declarations: [ SimpleCalendarInlineComponent],
  imports: [ CommonModule],
  exports: [ SimpleCalendarInlineComponent]
})

export class  SimpleCalendarInlineModule { }
