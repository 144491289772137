//import * as Instascan from "instascan";
export const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

export class Camera {
  id: string;
  name: string;
  _stream: MediaStream;
  facingMode: string;
  constraints: MediaStreamConstraints;

  constructor(id, name, facingMode) {
    this.id = id;
    this.name = name;
    this.facingMode = facingMode;
    this._stream = null;
    this.constraints = iOS ? {
      audio: false,
      video: {
        facingMode: this.facingMode
      }
    } : {
        audio: false,
        video: {
          deviceId: this.id
        }
      }
  }

  async start(): Promise<MediaStream> {
    this._stream = await Camera._wrapErrors(async () => {
      return await navigator.mediaDevices.getUserMedia(this.constraints);
    });

    return this._stream;
  }

  stop() {
    if (!this._stream) {
      return;
    }

    for (let stream of this._stream.getVideoTracks()) {
      stream.stop();
    }

    this._stream = null;
  }

  static cameraName = (label: string): string => {
    let clean = label.replace(/\s*\([0-9a-f]+(:[0-9a-f]+)?\)\s*$/, '');
    return clean || label || null;
  }

  static async getCameras() {
    await this._ensureAccess();

    if (iOS) {
      return <Camera[]>[new Camera(null, 'Selfi camera', 'user'),
      new Camera(null, 'Rear camera', 'environment')];
    }
    else {
      let devices = await navigator.mediaDevices.enumerateDevices();
      return devices
        .filter(d => d.kind === 'videoinput')
        .map(d => new Camera(d.deviceId, Camera.cameraName(d.label), undefined));
    }
  }

  static async _ensureAccess() {
    return await this._wrapErrors(async () => {
      let access = await navigator.mediaDevices.getUserMedia({ video: true });
      for (let stream of access.getVideoTracks()) {
        stream.stop();
      }
    });
  }

  static async _wrapErrors(fn) {
    try {
      return await fn();
    } catch (e) {
      if (e.name) {
        
        throw e;
        //throw new Instascan.MediaError(e.name);
      } else {
        throw e;
      }
    }
  }
}
