import { Component, OnInit } from '@angular/core';
import { JobApiService, PersonApiService, DictionaryApiService, ProtezPriceApiService } from '../../../../api/index';
import { FilteredGridRequestViewModel, BaseViewGridModel, GridRequestModel, JobViewModel, GridCellEvent, BaseDropDownViewModel, Person, ProtezView, SearchFilter, JobViewFilterModels, OrderModel, Protez, Color, Currency, ProtezPrice, PersonModel, RegionDictionaryViewModel } from '../../../../models/index';
import { HashTable, fullDoctorName, fullPersonName, fullName, Helper, Tuple } from '../../../../Helper/index';
import { NotificationService } from '../../../services';
import { DatePipe } from '@angular/common';
import { IGridOptions, ControlType } from '../../../components/grid/grid.component';
import { Observable, forkJoin } from 'rxjs';
import { forEach } from '@angular/router/src/utils/collection';
import { commonSetting } from '../../../services/common.settings';

class ExtJobView extends JobViewModel {
  doctorFullName: string;
  technicianFullName: string;
  fulNumber: string;
  protez: string;
  enrichedDueDate: string;
  startDateStr: string;
  removableProtez: string;
  generate: string;
}

class OrderResultModel {
  removablePreotezes: ProtezView[];
  fixedPreotezes: ProtezView[];
  job: ExtJobView;
  technicanComment: string;
  doctorComment: string;
  сomments: string;
  dueDate: Date;
  colorId: number;
  compulsory: boolean;
  price: number;
  currencyId: number;
  workId: number;
  patient: string;
  registeredPersonsNames: PersonModel[];
  fulNumber: string;
}

@Component({
  selector: 'archive',
  templateUrl: './archive.component.html',
  styleUrls: ['./archive.component.less'],
  providers: [DatePipe]
})
export class ArchiveComponent implements OnInit {

  jobs: BaseViewGridModel<ExtJobView>;
  fieldOptions: HashTable<IGridOptions>;
  gridRequestModel: FilteredGridRequestViewModel<JobViewFilterModels>;
  showDialog: boolean;
  selectedModel: OrderModel;
  hasntSalary: boolean;
  clinicList: RegionDictionaryViewModel[];
  clinicListDialog: BaseDropDownViewModel<number>[];

  selectedClinkItem: RegionDictionaryViewModel;
  selectedClinkDialogItem: BaseDropDownViewModel<number>;
  doctorsList: Person[];
  selectedDoctorItem: Person;
  selectedColor: Color;
  selectedCurrency: BaseDropDownViewModel<number>;
  selectedWork: BaseDropDownViewModel<number>;
  selectedProtezViewList: ProtezView[];
  protezTypes: Protez[];
  protezType: Protez;
  orderResult: OrderResultModel;
  colorsList: Color[];
  currencyList: BaseDropDownViewModel<number>[];
  workList: BaseDropDownViewModel<number>[];
  protezPricerList: HashTable<number>;
  helper: Helper;
  jobSumm: Tuple<string, number>[];

  serachValues = [];
  themeName = commonSetting.datePickerName;

  // filter: 
  constructor(
    private service: JobApiService,
    private personService: PersonApiService,
    private notify: NotificationService,
    private dictionaryApiService: DictionaryApiService,
    private datePipe: DatePipe,
    private personApiService: PersonApiService,
    private protezPriceApiService: ProtezPriceApiService
  ) {
    this.helper = new Helper();

    this.jobs = {
      viewModelList: []
    } as BaseViewGridModel<ExtJobView>;

    this.hasntSalary = true;
    this.fieldOptions = {
      fulNumber: {
        title: 'Номер наряда',
        order: false
      },
      startDateStr: {
        title: 'Нач. Дата',
        order: false
      },
      clinicName: {
        title: 'Клиника',
        order: false
      },
      enrichedDueDate: {
        title: 'Срок',
        order: false
      },
      doctorFullName: {
        title: 'Доктор',
        order: false
      },
      patient: {
        title: 'Пациент',
        order: false
      },
      doctorComment: {
        title: 'Коментарий врача',
        order: false
      },
      technicanComment: {
        title: 'Коментарий техника',
        order: false
      },
      protez: {
        title: 'Протез',
        order: false
      },
      technicianFullName: {
        title: 'Техник',
        order: false
      },
      compulsory: {
        hide: true,
        color: 'custom-color',
        title: 'Приоритет'
      },
      price: {
        title: "Цена",
        order: false
      },
      currencyName: {
        title: "Валюта",
        order: false
      },
      workName: {
        title: "Тип работ"
      },
      endDate: {
        title: "Заказ сделан",
        order: false
      },
      archivedDate: {
        title: "Добавлен в архив",
        order: false,
        fieldType: {
          value: (item: ExtJobView) => this.transformDate(this.helper.convertUtcStringToLocaleDate(item.archivedDate)),
          type: ControlType.customValue
        }
      },
      generate: {
        fieldType: {
          type: ControlType.link,
          defaultText: 'Распечатать заказ',
          value: (item: ExtJobView) =>
            `api/job/generateOrder/${item.jobId}`
        },
        order: false
      }
    };
    this.gridRequestModel = {
      filter: {
        filterModel: {}
      },
      orderBy: 2,
      orderName: "archivedDate",
      pageSize: 50
    } as FilteredGridRequestViewModel<JobViewFilterModels>;

    this.showDialog = false;
    this.selectedProtezViewList = [];
    this.selectedModel = {} as OrderModel;
    this.clinicList = [] as RegionDictionaryViewModel[];
    this.clinicListDialog = [] as BaseDropDownViewModel<number>[];
    this.doctorsList = [];
    this.selectedClinkItem = {} as RegionDictionaryViewModel;
    this.selectedClinkDialogItem = {} as BaseDropDownViewModel<number>;
    this.selectedDoctorItem = {} as Person;
    this.selectedColor = {} as Color;
    this.selectedCurrency = {} as BaseDropDownViewModel<number>;
    this.selectedWork = {} as BaseDropDownViewModel<number>;
    this.protezTypes = [];
    this.orderResult = this.setDefaultOrderResult();
    this.colorsList = [];
    this.currencyList = [];
    this.workList = [];
    this.protezPricerList = {};
  }

  setDefaultOrderResult = (): OrderResultModel => {
    let dueDate = new Date().setHours(0, 0, 0, 0);

    return {
      fixedPreotezes: [],
      removablePreotezes: [],
      job: {},
      registeredPersonsNames: [],
      dueDate: new Date(dueDate)
    } as OrderResultModel;
  }

  ngOnInit() {
    this.initGrid();
    forkJoin(
      this.dictionaryApiService.getClinicList(),
      this.service.getProtezes(),
      this.dictionaryApiService.getColors(),
      this.dictionaryApiService.getCurrencyTypes(),
      this.dictionaryApiService.getWorks()
    ).subscribe(pair => {
      let clinics = pair[0] as RegionDictionaryViewModel[];
      this.clinicList =  [] as RegionDictionaryViewModel[];
      this.clinicList.push({ id: 0, name: "Все", currencyId: -1, currencyName: '' });
      this.clinicList.push(...clinics);
      this.selectedClinkItem = this.clinicList[0] || {} as RegionDictionaryViewModel;
      this.clinicListDialog = [] as BaseDropDownViewModel<number>[];
      this.clinicListDialog.push({ id: 0, name: "Выберите клинику" });
      this.clinicListDialog.push(...clinics);

      let protezTyeps = pair[1] as Protez[];
      this.protezTypes = [];
      this.protezTypes.push(...protezTyeps)

      let colors = pair[2] as Color[];
      this.colorsList = [];
      this.colorsList.push({ id: null, key: "", colorCode: "" });
      this.colorsList.push(...colors);

      let currencies = pair[3] as BaseDropDownViewModel<number>[];
      this.currencyList = [];
      this.currencyList.push(...currencies);

      let works = pair[4] as BaseDropDownViewModel<number>[];
      this.workList = [];
      this.workList.push(...works);
    });

    this.getSerachValues();
  }

  initGrid = () => {
    this.gridRequestModel.filter.filterModel.archived = true;
    this.gridRequestModel.orderBy = 2;
    this.gridRequestModel.orderName = "archivedDate";
    this.service.getAll(this.gridRequestModel).subscribe((data) => {
      this.jobs = { ...data } as BaseViewGridModel<ExtJobView>;
      this.jobs.viewModelList.forEach(s => {
        s.doctorFullName = fullDoctorName(s);
        s.technicianFullName = fullName(s.technicLastName, s.technicFirstName, s.technicMidleName);

        let grouped: HashTable<string[]> = {};
        s.protezes && s.protezes.forEach((val) => {
          if (!grouped[val.protezName]) {
            grouped[val.protezName] = [];
          }
          grouped[val.protezName].push(val.protezName);
        });
        s.fulNumber = `${s.clinicPrefix}-${s.number}`;
        s.protez = Object.keys(grouped).map((key) => `${key}-${grouped[key].length}шт`).join('; ').trim();
        s.enrichedDueDate = this.transformDate(this.helper.convertUtcStringToLocaleDate(s.dueDate));
        s.startDateStr = this.transformDate(this.helper.convertUtcStringToLocaleDate(s.startDate));
      });
    });
  }

  transformDate = (date): string =>
    this.datePipe.transform(date, 'dd.MM.yyyy HH:mm');

  gridRequestEvent = (model: GridRequestModel) => {
    this.gridRequestModel = { ...model as FilteredGridRequestViewModel<JobViewFilterModels>, filter: this.gridRequestModel.filter };
    this.initGrid();
  }

  cellClickEvent = (model: GridCellEvent<ExtJobView>) => {
    if ((model.click.srcElement as any).localName == 'a')
      return;

    this.selectedColor = this.colorsList.find(s => s.key == model.data.colorKey) || {} as Color;
    this.selectedCurrency = this.currencyList.find(s => s.id == model.data.currencyId) || this.currencyList[0] || {} as BaseDropDownViewModel<number>;
    this.selectedWork = this.workList.find(s => s.id == model.data.workId) || this.workList[0] || {} as BaseDropDownViewModel<number>;

    this.orderResult = {
      job: { ...model.data },
      removablePreotezes: model.data.protezes.filter(s => s.protezTypeId == 1),
      fixedPreotezes: model.data.protezes.filter(s => s.protezTypeId == 2),
      technicanComment: model.data.technicanComment,
      doctorComment: model.data.doctorComment,
      сomments: model.data.comments,
      colorId: this.selectedColor.id,
      currencyId: this.selectedCurrency.id,
      workId: this.selectedWork.id,
      price: model.data.price,
      compulsory: model.data.compulsory,
      dueDate: new Date(model.data.dueDate),
      patient: model.data.patient,
      registeredPersonsNames: [],
      fulNumber: model.data.fulNumber
    } as OrderResultModel;

    this.selectedClinkDialogItem = this.clinicListDialog.find(s => s.id == model.data.clinicId) || this.clinicListDialog[0] || {} as BaseDropDownViewModel<number>;
    this.orderResult.job.clinicId = this.selectedClinkDialogItem.id;

    forkJoin(
      this.personApiService.getPersonsByClinikId(this.selectedClinkDialogItem.id),
      this.service.getByJobId(model.data.jobId),
      this.personService.getRegisteredPersosnsByJobId(model.data.jobId)
    ).subscribe(pair => {
      this.doctorsList = [];
      this.doctorsList.push({ id: null, lastName: "" } as Person);
      this.doctorsList.push(...pair[0]);
      this.selectedDoctorItem = this.doctorsList.find(s => s.id == model.data.doctorId) || this.doctorsList[0];
      this.selectedModel = pair[1];
      this.orderResult.registeredPersonsNames = pair[2];
      this.showDialog = true;
    });

  }

  closeDialog = ($event) => this.showDialog = false;

  OnCompanyChange = ($event: RegionDictionaryViewModel /*BaseDropDownViewModel<number>*/) => {
    /* debugger;
    this.selectedClinkItem.id = $event.id; */
    this.selectedClinkItem = $event;
  }

  onSearchClick = () => {
    this.gridRequestModel.filter.filterModel.clinicId = this.selectedClinkItem.id == 0 ? null : this.selectedClinkItem.id;
    console.log(this.gridRequestModel);
    this.initGrid();
  }

  getSerachValues = () => {
    this.service.getPatienSearchList().subscribe(s => {
      this.serachValues = [...s];
    });
  }

  onSerachTextChanged = (value: any) => {
    this.gridRequestModel.filter.searchText = value;
  }

  promptClick = (value: any) => {
    this.gridRequestModel.filter.searchText = value;
    this.onSearchClick();
  }

  gridCheckedItemsEvent = (models: ExtJobView[]) => {
    this.gridRequestModel.selectedItems = [];
    this.gridRequestModel.selectedItems.push(...models.map(s => ({
      jobId: s.jobId
    } as JobViewFilterModels)));

    let groupedValues = this.helper.groupBy(models, s => s.currencyName);
    let groupResult = this.helper.aggregateGroup(groupedValues, 0, (value, item) => value + item.price);

    this.jobSumm = this.helper.mapGroupToObjectArray(groupResult);
  }

}
