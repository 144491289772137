﻿//------------------------------------------------------------------------------
//  This code was generated by typescript template tool 'Typewriter'.
//
//  Changes to this file may cause incorrect behavior and will be lost if
//  the code is regenerated.
//------------------------------------------------------------------------------


import { Injectable } from "@angular/core";
import { ResponseContentType, Response } from "@angular/http";
import { Observable } from 'rxjs';
import { BasApiService } from '../base.api.service';
import {GridApiService} from './grid.api.service';
import * as ApiTypes from "../../models";

@Injectable()
export class JobApiService extends GridApiService<ApiTypes.JobViewModel,ApiTypes.JobViewFilterModels>{
  
  constructor(public baseService: BasApiService) {
   super(baseService);
    this.apiKey = "api/job";
  }
  
  registerOrder = (orderId: number): Observable<string> =>
    this.baseService.post<string>(`api/Job/register/${orderId}`, null, {}, true, true);   
  
  generateOrder = (orderId: number): Observable<void> =>
    this.baseService.get<void>(`api/Job/generateOrder/${orderId}`, {}, true, true);   
  
  getByJobId = (jobId: number): Observable<ApiTypes.OrderModel> =>
    this.baseService.get<ApiTypes.OrderModel>(`api/Job/${jobId}`, {}, true, true);   
  
  viewOrder = (orderId: number): Observable<ApiTypes.JobViewModel> =>
    this.baseService.post<ApiTypes.JobViewModel>(`api/Job/view/${orderId}`, null, {}, true, true);   
  
  createOrder = (order: ApiTypes.OrderModel): Observable<void> =>
    this.baseService.post<void>(`api/Job/create`, order, {}, true, true);   
  
  getProtezes = (): Observable<ApiTypes.Protez[]> =>
    this.baseService.get<ApiTypes.Protez[]>(`api/Job/protezes`, {}, true, true);   
  
  getAllForDepartment = (queryModel: ApiTypes.FilteredGridRequestViewModel<ApiTypes.JobViewFilterModels>): Observable<ApiTypes.BaseViewGridModel<ApiTypes.JobViewModel>> =>
    this.baseService.post<ApiTypes.BaseViewGridModel<ApiTypes.JobViewModel>>(`api/Job/department`, queryModel, {}, true, true);   
  
  archived = (queryModel: ApiTypes.FilteredGridRequestViewModel<ApiTypes.JobViewFilterModels>): Observable<void> =>
    this.baseService.put<void>(`api/Job/archived`, queryModel, {}, true, true);   
  
  deleteOrder = (queryModel: ApiTypes.FilteredGridRequestViewModel<ApiTypes.JobViewFilterModels>): Observable<void> =>
    this.baseService.post<void>(`api/Job`, queryModel, {}, true, true);   
  
  deleteLastTechnic = (orderId: number): Observable<void> =>
    this.baseService.delete<void>(`api/Job/delete/technic/${orderId}`, {}, true, true);   
  
  getOrderByDate = (orderDate: any): Observable<any[]> =>
    this.baseService.post<any[]>(`api/Job/getorderbydate?orderDate=${orderDate}`, null, {}, true, true);   
  
  getPatienSearchList = (): Observable<any[]> =>
    this.baseService.get<any[]>(`api/Job/getpatiensearchlist`, {}, true, true);   
  
  }

