import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'contacts',
  templateUrl: './contacts.component.html'
})
export class ContactsComponent{
  
  messages: string[];
  constructor() {
    this.messages = [];
  }

  doMessage = (text: string) => {
    this.messages.push(text);
   }
}
