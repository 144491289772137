﻿//------------------------------------------------------------------------------
//  This code was generated by typescript template tool 'Typewriter'.
//
//  Changes to this file may cause incorrect behavior and will be lost if
//  the code is regenerated.
//------------------------------------------------------------------------------


import { Injectable } from "@angular/core";
import { ResponseContentType, Response } from "@angular/http";
import { Observable } from 'rxjs';
import { BasApiService } from '../base.api.service';
import {GridApiService} from './grid.api.service';
import * as ApiTypes from "../../models";

@Injectable()
export class RegionApiService extends GridApiService<ApiTypes.RegionViewModel,ApiTypes.RegionViewFilterModels>{
  
  constructor(public baseService: BasApiService) {
   super(baseService);
    this.apiKey = "api/region";
  }
  
  save = (model: ApiTypes.RegionViewModel): Observable<void> =>
    this.baseService.post<void>(`api/Region`, model, {}, true, true);   
  
  getNewPrefix = (): Observable<string> =>
    this.baseService.get<string>(`api/Region`, {}, true, true);   
  
  }

