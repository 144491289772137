import { Component, OnInit } from '@angular/core';
import { JobApiService, PersonApiService, DictionaryApiService, ProtezPriceApiService, JobStateApiService } from '../../../../api/index';
import { FilteredGridRequestViewModel, BaseViewGridModel, GridRequestModel, JobViewModel, GridCellEvent, BaseDropDownViewModel, Person, ProtezView, SearchFilter, JobViewFilterModels, OrderModel, Protez, Color, Currency, ProtezPrice, Work, PersonModel, RegionDictionaryViewModel, JobStateViewFilterModels } from '../../../../models/index';
import { HashTable, fullDoctorName, fullPersonName, fullName, Helper, KeyValuePair, Tuple } from '../../../../Helper/index';
import { NotificationService } from '../../../services';
import { DatePipe } from '@angular/common';
import { IGridOptions, ControlType } from '../../../components/grid/grid.component';
import { Observable, forkJoin } from 'rxjs';
import { ExtJobView } from '../../../../models/custom/ExtJobView';
import { OrderResultModel } from '../../../../models/custom/OrderResultModel';

/* class ExtJobView extends JobViewModel {
  doctorFullName: string;
  technicianFullName: string;
  fulNumber: string;
  protez: string;
  enrichedDueDate: string;
  startDateStr: string;
  removableProtez: string;
  generate: string;
} */

/* class OrderResultModel {
  removablePreotezes: ProtezView[];
  fixedPreotezes: ProtezView[];
  job: ExtJobView;
  technicanComment: string;
  doctorComment: string;
  dueDate: Date;
  colorId: number;
  compulsory: boolean;
  price: number;
  patient: string;
  workId: number;
  registeredPersonsNames: PersonModel[];
  fulNumber: string;
} */

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  providers: [DatePipe]
})
export class HomeComponent implements OnInit {

  jobs: BaseViewGridModel<ExtJobView>;
  jobStateGridRequestModel: FilteredGridRequestViewModel<JobStateViewFilterModels>;
  fieldOptions: HashTable<IGridOptions>;
  gridRequestModel: FilteredGridRequestViewModel<JobViewFilterModels>;
  showDialog: boolean;
  selectedModel: OrderModel;
  hasntSalary: boolean;
  clinicList: RegionDictionaryViewModel[];
  clinicListDialog: RegionDictionaryViewModel[];
  showQrScaner: boolean;
  currentPriceListCurrency: number;
  selectedClinkItem: RegionDictionaryViewModel;
  selectedClinkDialogItem: RegionDictionaryViewModel;
  doctorsList: Person[];
  selectedDoctorItem: Person;
  selectedColor: Color;
  selectedWork: BaseDropDownViewModel<number>;
  selectedProtezViewList: ProtezView[];
  protezTypes: Protez[];
  protezType: Protez;
  orderResult: OrderResultModel;
  colorsList: Color[];
  workList: BaseDropDownViewModel<number>[];
  jobStateList: any[];
  selectedJobState: any;

  protezPricerList: HashTable<number>;
  jobSum: string[];
  helper: Helper;
  jobSumm: Tuple<string, number>[];

  deleteTitle: string = '';
  titles = {
    deleteOrderTitle: 'Удалить заказы?',
    deleteLastScanTitle: 'Удалить последнего сканирующего техника?'
  };
  showDeleteDialog: boolean = false;
  deleteCallBack: () => void;

  hourListOrder = [];
  minutesList = [];
  selectedDueHour = -1;
  selectedDueMinute = -1;

  serachValues = [];

  constructor(
    private service: JobApiService,
    private personService: PersonApiService,
    private notify: NotificationService,
    private dictionaryApiService: DictionaryApiService,
    private datePipe: DatePipe,
    private personApiService: PersonApiService,
    private protezPriceApiService: ProtezPriceApiService,
    private jobStateService: JobStateApiService
  ) {
    this.helper = new Helper();
    this.jobSumm = [];
    this.jobs = {
      viewModelList: []
    } as BaseViewGridModel<ExtJobView>;

    this.jobStateGridRequestModel = {} as FilteredGridRequestViewModel<JobStateViewFilterModels>;

    this.hasntSalary = true;
    this.fieldOptions = {
      fulNumber: {
        title: 'Номер наряда',
        order: false
      },
      startDateStr: {
        title: 'Нач. Дата',
        order: false
      },
      clinicName: {
        title: 'Клиника',
        order: false
      },
      enrichedDueDate: {
        title: 'Срок',
        order: false
      },
      jobStateName: {
        title: 'Состояние',
        order: false
      },
      doctorFullName: {
        title: 'Доктор',
        order: false
      },
      patient: {
        title: 'Пациент',
        order: false
      },
      doctorComment: {
        title: 'Коментарий врача',
        order: false
      },
      technicanComment: {
        title: 'Коментарий техника',
        order: false
      },
      protez: {
        title: 'Протез',
        order: false
      },
      technicianFullName: {
        title: 'Техник',
        order: false
      },
      compulsory: {
        hide: true,
        color: 'custom-color',
        title: 'Приоритет'
      },
      price: {
        title: "Цена",
        order: false
      },
      currencyName: {
        title: "Валюта",
        order: false
      },
      workName: {
        title: "Тип работы",
        order: false
      },
      endDate: {
        title: "Заказ сделан",
        order: false
      },
      paid: {
        hide: true,
        title: "Оплачен",
        color: 'custom-color',
      },
      generate: {
        fieldType: {
          type: ControlType.link,
          defaultText: 'Распечатать заказ',
          value: (item: ExtJobView) =>
            `api/job/generateOrder/${item.jobId}`
        },
        order: false
      }
    };
    this.gridRequestModel = {
      filter: {
        filterModel: {}
      },
      orderName: 'jobId',
      orderBy: 2,
      pageSize: 50
    } as FilteredGridRequestViewModel<JobViewFilterModels>;
    this.currentPriceListCurrency;
    this.showDialog = false;
    this.selectedProtezViewList = [];
    this.selectedModel = {} as OrderModel;
    this.clinicList = [] as RegionDictionaryViewModel[];
    this.clinicListDialog = [] as RegionDictionaryViewModel[];
    this.doctorsList = [];
    this.selectedClinkItem = {} as RegionDictionaryViewModel;
    this.selectedClinkDialogItem = {} as RegionDictionaryViewModel;
    this.selectedDoctorItem = {} as Person;
    this.selectedColor = {} as Color;
    this.selectedWork = {} as BaseDropDownViewModel<number>;
    this.protezTypes = [];
    this.orderResult = this.setDefaultOrderResult();
    this.colorsList = [];
    this.workList = [];
    this.jobStateList = [];
    this.selectedJobState = {};
    this.protezPricerList = {};
  }

  transformDate = (date): string =>
    this.datePipe.transform(date, 'dd.MM.yyyy HH:mm');

  setDefaultOrderResult = (): OrderResultModel => {
    let dueDate = new Date().setHours(0, 0, 0, 0);

    return {
      fixedPreotezes: [],
      removablePreotezes: [],
      job: {},
      registeredPersonsNames: [],
      dueDate: new Date(dueDate)
    } as OrderResultModel;
  }

  ngOnInit() {
    this.initGrid();
    forkJoin(
      this.dictionaryApiService.getClinicList(),
      this.service.getProtezes(),
      this.dictionaryApiService.getColors(),
      this.dictionaryApiService.getWorks(),
      this.jobStateService.getAll(this.jobStateGridRequestModel)
    ).subscribe(pair => {
      let clinics = pair[0] as RegionDictionaryViewModel[];
      this.clinicList = [] as RegionDictionaryViewModel[];
      this.clinicList.push({ id: 0, name: "Все", currencyId: -1, currencyName: '' });
      this.clinicList.push(...clinics);
      this.selectedClinkItem = this.clinicList[0] || {} as RegionDictionaryViewModel;
      this.clinicListDialog = [] as RegionDictionaryViewModel[];
      this.clinicListDialog.push({
        id: 0, name: "Выберите клинику", currencyId
          : -1, currencyName: ''
      });
      this.clinicListDialog.push(...clinics);

      let protezTyeps = pair[1] as Protez[];
      this.protezTypes = [];
      this.protezTypes.push(...protezTyeps)

      let colors = pair[2] as Color[];
      this.colorsList = [];
      this.colorsList.push({ id: null, key: "", colorCode: "" });
      this.colorsList.push(...colors);

      let works = pair[3] as BaseDropDownViewModel<number>[];
      this.workList = [];
      this.workList.push(...works);

      for (let i = 8; i <= 20; i++) {
        this.hourListOrder.push({
          value: i,
          name: i
        });
      }

      this.jobStateList.push({ id: -1, name: '---', color: null });
      pair[4].viewModelList.forEach(js => {
        this.jobStateList.push(js);
      });
      //this.jobStateList = pair[4].viewModelList;
      console.log(this.jobStateList);

      this.minutesList.push({ value: 0, name: 0 });
      this.minutesList.push({ value: 15, name: 15 });
      this.minutesList.push({ value: 30, name: 30 });
      this.minutesList.push({ value: 45, name: 45 });

      this.getSerachValues();

    });
  }

  initGrid = () => {
    this.gridRequestModel.filter.filterModel.archived = false;
    this.service.getAll(this.gridRequestModel).subscribe((data) => {
      this.jobs = { ...data } as BaseViewGridModel<ExtJobView>;
      this.jobs.viewModelList.forEach(s => {
        this.extendModel(s);
      });
    });
  }

  extendModel = (model: JobViewModel): ExtJobView => {
    let s = model as ExtJobView;
    s.doctorFullName = fullDoctorName(s);
    s.technicianFullName = fullName(s.technicLastName, s.technicFirstName, s.technicMidleName);

    let grouped: HashTable<string[]> = {};
    s.protezes && s.protezes.forEach((val) => {
      if (!grouped[val.protezName]) {
        grouped[val.protezName] = [];
      }
      grouped[val.protezName].push(val.protezName);
    });
    s.fulNumber = `${s.clinicPrefix}-${s.number}`;
    s.protez = Object.keys(grouped).map((key) => `${key}-${grouped[key].length}шт`).join('; ').trim();
    s.enrichedDueDate = this.transformDate(this.helper.convertUtcStringToLocaleDate(s.dueDate));
    s.startDateStr = this.transformDate(this.helper.convertUtcStringToLocaleDate(s.startDate));

    return s;
  }

  gridRequestEvent = (model: GridRequestModel) => {
    this.gridRequestModel = { ...model as FilteredGridRequestViewModel<JobViewFilterModels>, filter: this.gridRequestModel.filter };
    this.initGrid();
  }

  cellClickEvent = (model: GridCellEvent<ExtJobView>) => {
    if ((model.click.srcElement as any).localName == 'a')
      return;

    this.initModelAndShow(model.data);
  }

  /*   cellClickEvent = (model: GridCellEvent<ExtJobView>) => {
      if (model.click.srcElement.localName == 'a')
        return;
  
      this.initModelAndShow(model.data);
    } */

  hourChanged(val) {
    this.orderResult.dueDate.setHours(val);
  }

  minutesChanged(val) {
    this.orderResult.dueDate.setMinutes(val);
  }

  initModelAndShow = (model: ExtJobView): void => {
    this.selectedColor = this.colorsList.find(s => s.key == model.colorKey) || {} as Color;
    this.selectedWork = this.workList.find(s => s.id == model.workId) || this.workList[0] || {} as BaseDropDownViewModel<number>;
    this.selectedJobState = this.jobStateList.find(s => s.id == model.jobStateId) || this.jobStateList[0] || {};

    this.orderResult = {
      job: { ...model },
      removablePreotezes: model.protezes ? model.protezes.filter(s => s.protezTypeId == 1) : [],
      fixedPreotezes: model.protezes ? model.protezes.filter(s => s.protezTypeId == 2) : [],
      /* removablePreotezes: model.protezes.filter(s => s.protezTypeId == 1),
      fixedPreotezes: model.protezes.filter(s => s.protezTypeId == 2), */
      technicanComment: model.technicanComment,
      doctorComment: model.doctorComment,
      comments: model.comments,
      colorId: this.selectedColor.id,
      workId: this.selectedWork.id,
      price: model.price,
      compulsory: model.compulsory,
      dueDate: this.helper.convertUtcStringToLocaleDate(model.dueDate),
      patient: model.patient,
      registeredPersonsNames: [],
      fulNumber: model.fulNumber,
      jobStateId: model.jobStateId,
      isSent: model.isSent
    } as OrderResultModel;

    this.selectedDueHour = this.orderResult.dueDate.getHours();
    this.selectedDueMinute = this.orderResult.dueDate.getMinutes();

    this.selectedClinkDialogItem = this.clinicListDialog.find(s => s.id == model.clinicId) || this.clinicListDialog[0];

    this.OnClinkDialogChange(this.selectedClinkDialogItem, () => {
      this.selectedDoctorItem = this.doctorsList.find(s => s.id == model.doctorId) || this.doctorsList[0];
    });

    this.service.getByJobId(model.jobId).subscribe((model) => this.selectedModel = model);
    this.personService.getRegisteredPersosnsByJobId(model.jobId).subscribe((result) => {
      this.orderResult.registeredPersonsNames = result;
    }, _ => _, () => this.showDialog = true);

  }

  closeDialog = ($event) => this.showDialog = false;


  updatePriceList = (done?: () => void, complite?: () => void): void => {

    let tmpValue = this.selectedClinkDialogItem.currencyId || this.orderResult.job.currencyId;
    if (this.currentPriceListCurrency) {
      if (tmpValue == this.currentPriceListCurrency) {
        if (done) {
          done();
        }
        complite();
        return;
      }
    }

    this.currentPriceListCurrency = tmpValue;

    this.protezPriceApiService.getByCurrencyId(this.currentPriceListCurrency).subscribe(s => {
      this.protezPricerList = {};
      s.forEach(s => {
        this.protezPricerList[s.protezId] = s.price
      });
      if (done)
        done();

      complite();
    });
  }

  calcPrice = (): void => {
    let price = 0;
    this.orderResult.fixedPreotezes.forEach(s => price += this.helper.valueFromObject(this.protezPricerList, s.protezId, 0));
    this.orderResult.removablePreotezes.forEach(s => price += this.helper.valueFromObject(this.protezPricerList, s.protezId, 0));
    this.orderResult.price = price;
  }



  scannedMessage = ($event: number) => {
    this.closeScanerDialog(null);
    this.service.viewOrder($event).subscribe((result) => {
      let extModel = this.extendModel({ ...result });
      this.initModelAndShow(extModel);
    });
  }

  OnClinkChange = ($event: RegionDictionaryViewModel) => {
    this.selectedClinkItem = $event;
  }

  OnClinkDialogChange = ($event: RegionDictionaryViewModel, calback?: () => void) => {
    this.selectedClinkDialogItem = $event;
    this.updatePriceList(() => {
      if (this.selectedClinkDialogItem.id != this.orderResult.job.clinicId)
        this.calcPrice();
    }, () => {
      this.orderResult.job.clinicId = this.selectedClinkDialogItem.id;
      this.orderResult.job.currencyId = this.selectedClinkDialogItem.currencyId;
      this.orderResult.job.currencyName = this.selectedClinkDialogItem.currencyName;

      this.personApiService.getPersonsByClinikId(this.selectedClinkDialogItem.id).subscribe((date) => {
        this.doctorsList = [];
        this.doctorsList.push({ id: null, lastName: "" } as Person);
        this.doctorsList.push(...date);
        if (calback)
          calback();
      });
    });
  }

  OnDoctorChange = ($event: Person) => {
    this.selectedDoctorItem = $event;
    this.orderResult.job.doctorId = this.selectedDoctorItem.id;
  }

  OnColorChange = ($event: Color) => {
    this.selectedColor = $event;
    this.orderResult.colorId = this.selectedColor.id;
  }

  OnWorkChange = ($event: BaseDropDownViewModel<number>) => {
    this.selectedWork = $event;
    this.orderResult.workId = this.selectedWork.id;
  }

  OnJobStateChange = ($event: any) => {
    this.selectedJobState = $event;
    this.orderResult.jobStateId = this.selectedJobState.id;
  }

  addOrder = ($event: any) => {
    this.selectedModel = {
      protezes: []
    } as OrderModel;
    this.selectedClinkDialogItem = {} as RegionDictionaryViewModel;
    this.selectedDoctorItem = {} as Person;
    this.selectedColor = {} as Color;
    this.selectedWork = {} as BaseDropDownViewModel<number>;
    this.orderResult = this.setDefaultOrderResult();
    this.selectedJobState = {};
    this.showDialog = true;

    if (this.selectedClinkItem && this.selectedClinkItem.id) {
      this.OnClinkDialogChange({ ...this.selectedClinkItem });
    }
  }

  OnCompanyChange = ($event: any /*BaseDropDownViewModel<number>*/) => {
    this.selectedClinkItem = { ...$event };
    this.onSearchClick();
    //this.selectedClinkItem.id = $event.id;
  }

  onSearchClick = () => {
    this.gridRequestModel.filter.filterModel.clinicId = this.selectedClinkItem.id == 0 ? null : this.selectedClinkItem.id;
    console.log(this.gridRequestModel);
    this.initGrid();
  }

  onProtezChange = ($event: ProtezView[]): void => {
    this.orderResult.fixedPreotezes = [];
    this.orderResult.fixedPreotezes.push(...$event);
    this.calcPrice();
  }

  onRemovableProtezChange = ($event: ProtezView[]): void => {
    this.orderResult.removablePreotezes = [];
    this.orderResult.removablePreotezes.push(...$event);
    this.calcPrice();
  }

  onTypeChange = ($event: Protez): void => {
    console.log($event);
    this.protezType = $event;
  }

  saveDialog = (): void => {
    let orderModel: OrderModel = {
      technicanComment: this.orderResult.technicanComment,
      doctorComment: this.orderResult.doctorComment,
      comments: this.orderResult.comments,
      doctorId: this.orderResult.job.doctorId,
      dueDate: this.orderResult.dueDate,
      id: this.orderResult.job.jobId,
      protezes: this.orderResult.fixedPreotezes.concat(this.orderResult.removablePreotezes),
      regionId: this.orderResult.job.clinicId,
      colorId: this.orderResult.colorId,
      compulsory: this.orderResult.compulsory,
      workId: this.orderResult.workId,
      price: this.orderResult.price,
      patient: this.orderResult.patient,
      jobStateId: this.orderResult.jobStateId,
      isSent: this.orderResult.isSent
    };
    this.service.createOrder(orderModel).subscribe(_ => {
      this.notify.showInfo("Заказ создан успешно");
      this.showDialog = false;
      this.initGrid();
    });
  }

  closeScanerDialog = ($event) => this.showQrScaner = false;

  runScanner = ($event) => {
    this.showQrScaner = true;
  }

  gridCheckedItemsEvent = (models: ExtJobView[]) => {
    this.gridRequestModel.selectedItems = [];
    this.gridRequestModel.selectedItems.push(...models.map(s => ({
      jobId: s.jobId
    } as JobViewFilterModels)));

    let groupedValues = this.helper.groupBy(models, s => s.currencyName);
    let groupResult = this.helper.aggregateGroup(groupedValues, 0, (value, item) => value + item.price);

    this.jobSumm = this.helper.mapGroupToObjectArray(groupResult);
  }

  addToArchive = () => {
    this.service.archived(this.gridRequestModel).subscribe(_ => {
      this.notify.showInfo("Заказы отправленны в архив");
      this.gridRequestModel.selectedItems = [];
      this.initGrid();
    });
  }

  approveDelete = (): void =>
    this.deleteCallBack();

  closeDeleteDialog = () =>
    this.showDeleteDialog = false;

  deleteOrder = () => {
    this.showDeleteDialog = true;
    this.deleteTitle = this.titles.deleteOrderTitle;
    this.deleteCallBack = () => {
      console.log(this.gridRequestModel);
      this.service.deleteOrder(this.gridRequestModel).subscribe(_ => {
        this.notify.showInfo("Заказы удалены успешно");
        this.gridRequestModel.selectedItems = [];
        this.closeDeleteDialog();
        this.initGrid();
      });
    }
  }

  removeLastTechnic = () => {
    this.showDeleteDialog = true;
    this.deleteTitle = this.titles.deleteLastScanTitle;
    this.deleteCallBack = () => {
      this.service.deleteLastTechnic(this.orderResult.job.jobId).subscribe(_ => {
        this.notify.showInfo("Техник удален с работы успешно");
        this.personService.getRegisteredPersosnsByJobId(this.orderResult.job.jobId).subscribe((result) => {
          this.orderResult.registeredPersonsNames = result;
        });
        this.closeDeleteDialog();
        this.showDialog = true;
      });

    }
  }

  getSerachValues = () => {
    this.service.getPatienSearchList().subscribe(s => {
      this.serachValues = [...s];
    });
  }

  onSerachTextChanged = (value: any) => {
    this.gridRequestModel.filter.searchText = value;
  }

  promptClick = (value: any) => {
    this.gridRequestModel.filter.searchText = value;
    this.onSearchClick();
  }
}
