import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';

@Injectable()
export class MenuService {

  private stateEventEmitter: Subject<boolean> = new Subject<boolean>();
  private state: boolean;

  public MenuService() {
    this.close();
  }

  public open = (): void => {
    this.updateState(true);
  }

  public close = (): void => {
    this.updateState(false);
  }

  public getState = (): Observable<boolean> => 
     this.stateEventEmitter.asObservable()


  public updateState = (state: boolean): void => {
    this.state = state;
    this.stateEventEmitter.next(this.state);
  }

}
