﻿//------------------------------------------------------------------------------
//  This code was generated by typescript template tool 'Typewriter'.
//
//  Changes to this file may cause incorrect behavior and will be lost if
//  the code is regenerated.
//------------------------------------------------------------------------------



export class JobTimerViewModel   {
    
    id: number;
    protezID: number;
    protezName: string;
    workTypeID: number;
    workTypeName: string;
    positionID: number;
    positionName: string;
    jobStateID: number;
    jobStateName: string;
    timeHour: number;
}




  
