﻿//------------------------------------------------------------------------------
//  This code was generated by typescript template tool 'Typewriter'.
//
//  Changes to this file may cause incorrect behavior and will be lost if
//  the code is regenerated.
//------------------------------------------------------------------------------


import { Injectable } from "@angular/core";
import { ResponseContentType, Response } from "@angular/http";
import { Observable } from 'rxjs';
import { BasApiService } from '../base.api.service';

import * as ApiTypes from "../../models";

@Injectable()
export class ValuesApiService {
  
  constructor(public baseService: BasApiService) {
   
  }
  
  get = (): Observable<string[]> =>
    this.baseService.get<string[]>(`api/Values`, {}, true, true);   
  
  getById = (id: number): Observable<string> =>
    this.baseService.get<string>(`api/Values/${id}`, {}, true, true);   
  
  post = (value: string): Observable<void> =>
    this.baseService.post<void>(`api/Values`, value, {}, true, true);   
  
  put = (id: number, value: string): Observable<void> =>
    this.baseService.put<void>(`api/Values/${id}`, value, {}, true, true);   
  
  delete = (id: number): Observable<void> =>
    this.baseService.delete<void>(`api/Values/${id}`, {}, true, true);   
  
  }

