import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";

@Injectable()
export class NotificationService {

  constructor(private toastr: ToastrService) {
    toastr.toastrConfig.enableHtml = true;
  }

  public showError = (message: string): void => {
    this.toastr.error(message);
  }

  public showWarning = (message: string): void => {
    this.toastr.warning(message);
  }

  public showInfo = (message: string): void => {
    this.toastr.info(message);
  }
}
