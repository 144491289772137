import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router"; 
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';
import { RemovaleProtezComponent } from "./removable-protez.component";
import { ListModule } from "../list/list.module";

@NgModule({
  declarations: [RemovaleProtezComponent],
  imports: [RouterModule, CommonModule, FormsModule, ListModule],
  exports: [RemovaleProtezComponent]
})

export class RemovaleProtezModule { }
