import { Injectable, Inject } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { Person, UserAuth, RoleType } from '../../models';
import { Router } from '@angular/router';
import { AuthApiService, PersonApiService } from '../../api';
import { HashTable } from '../../Helper';
import { retry } from 'rxjs/operators';

@Injectable()
export class AuthService {
  private userIsLogged: Subject<Person> = new Subject<Person>();
  private isChekUserInProgress: boolean;
  private person: Person;

  constructor(
    private router: Router,
    private authApi: AuthApiService,
    private personApi: PersonApiService
  ) {

  }

  public getUserData = (): Observable<Person> => {
    this.checkUserAuth();
    return this.userIsLogged.asObservable();
  }

  public saveUserSettings = <T>(settings: HashTable<T>): Observable<void> => {
    this.person.settings = JSON.stringify(settings);
   return this.personApi.saveSettings(this.person.settings);
  }

  public getUserSettings = <T>(): HashTable<T> => {
    if (!this.person.settings)
      return null;

    return JSON.parse(this.person.settings);
  }

  private checkUserAuth = (): void => {
    if (this.person) {
      this.userIsLogged.next({ ...this.person });
    }

    if (this.isChekUserInProgress)
      return;

    this.isChekUserInProgress = true;
    this.personApi.getCurrent().subscribe(data => {
      this.person = data;
      this.isChekUserInProgress = false;
      this.userIsLogged.next(this.person);
    }, error => {
      if (error.status == 400 || error.status == 401) {
        this.person = undefined;
        this.userIsLogged.next(this.person);
      }
      if (error.status == 401
        && this.router.routerState.snapshot.url == '/') {
        this.roleNavigationSwith();
      }
      this.isChekUserInProgress = false;
    });
  }

  public login = (userData: UserAuth): void => {
    this.authApi.login(userData)
      .subscribe(_ => {
        this.personApi.getCurrent().subscribe(person => {
          this.person = person;
          this.roleNavigationSwith();
        });
      });
  }

  private roleNavigationSwith = (): void => {
    if (this.person)
      switch (this.person.role) {
        case RoleType.Auditor:
          this.router.navigate(['auditor']);
          break;

        case RoleType.Technician:
          this.router.navigate(['techincian']);
          break;

        case RoleType.Clinic:
        case RoleType.Doctor:
          this.router.navigate(['clinic']);
          break;

        case RoleType.Admin:
          this.router.navigate(['administrator']);
          break;

        case RoleType.TaskMaster:
          this.router.navigate(['taskmaster']);
          break;
      }
    else
      this.router.navigate(['']);
  }

  public logout = () => {
    this.authApi.logOut().subscribe(_ => {
      this.person = undefined;
      this.roleNavigationSwith();
    });
  }
}
