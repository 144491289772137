import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router"; 
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms'; 
import { ListModule } from "../list/list.module";
import { SalaryPersonComponent } from "./salary-person.component";
import { GridModule } from "../grid/grid.module";
import { ModalWindownModule } from "../modal-window/modal-window.module";

@NgModule({
  declarations: [SalaryPersonComponent],
  imports: [GridModule, CommonModule, ModalWindownModule],
  exports: [SalaryPersonComponent]
})

export class SalaryPersonModule { }
