import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PersonViewModel, BaseDropDownViewModel, Person, BaseViewGridModel, RoleType } from '../../../models';
import { PersonApiService } from '../../../api';
import { NotificationService } from '../../services';
import { ControlType, IGridOptions } from '../grid/grid.component';
import { HashTable } from '../../../Helper';

@Component({
  selector: 'clinic-modal',
  templateUrl: './clinic-modal.component.html'
})
export class ClinicModalComponent implements OnInit {

  @Input() clinicModel: PersonViewModel;
  @Input() currencyList: BaseDropDownViewModel<number>[];
  @Input() isEditMode: boolean;

  @Output() createAndAddDoctorEvent = new EventEmitter<void>();

  doctorsInClinic: BaseViewGridModel<Person>;
  doctorList: BaseDropDownViewModel<number>[];
  selectedDoctorId: number;
  fieldOptionsDoctors: HashTable<IGridOptions>;




  constructor(private service: PersonApiService,
    private notify: NotificationService) {
    this.doctorList = [];

    this.fieldOptionsDoctors = {
      lastName: {
        title: 'Фамилия'
      },
      firstName: {
        title: 'Имя'
      },
      middleName: {
        title: 'Отчество'
      },
      //loginName: {
      //  title: 'Логин'
      //},
      generate: {
        fieldType: {
          type: ControlType.button,
          defaultText: 'Удалить',
          click: (item: Person) => this.removeDoctorFromClinic(item)

        },
        order: false
      }
    };

    this.doctorsInClinic = {
      viewModelList: []
    } as BaseViewGridModel<Person>;


  }

  ngOnInit() {
    this.initDoctorList();
    this.initClinicDoctorList(this.clinicModel.regionId);
  }

  initDoctorList = () => {
    this.service.getFreeDoctorList().subscribe(data => {
      this.selectedDoctorId = -1;
      this.doctorList = [];
      this.doctorList.push(...data);
    });
  }

  initClinicDoctorList = (clinicId: number) => {
    this.service.getPersonsByClinikId(clinicId).subscribe(data => {
      console.log('data');
      console.log(data);

      this.doctorsInClinic = {
        viewModelList: data,
        totalCount: data.length,
        pageNumber: 1,
        pageSize: 25
      } as BaseViewGridModel<Person>;
    });
  }

  addDoctorToClinick() {
    this.service.addDoctorToClinic(
      {
        personId: this.selectedDoctorId,
        regionId: this.clinicModel.regionId
      } as PersonViewModel)
      .subscribe(_ => {
        this.notify.showInfo("Запись сохранена успешно");
        this.initClinicDoctorList(this.clinicModel.regionId);
        this.initDoctorList();
      });
  }

  removeDoctorFromClinic(item: Person) {
    if (item) {
      this.service.removeDoctorFromClinic(item.id).subscribe(_ => {
        this.notify.showInfo("Запись удалена успешно");
        this.initClinicDoctorList(this.clinicModel.regionId);
        this.initDoctorList();
      });
    }
  }

  createAndAddDoctorToClinick() {
    this.createAndAddDoctorEvent.emit();
  }

}
