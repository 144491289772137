import { Component, EventEmitter, Output, Input, OnChanges, SimpleChanges, OnInit } from '@angular/core';

import { HashTable, Tuple, Helper } from '../../../Helper/index';
import { GridRequestModel, OrderType, GridCellEvent, BaseViewGridModel } from '../../../models';
import { InputValidationService, TranslationService } from '../../services';
import { retry } from 'rxjs/operators';


enum CheckBoxState {
  Checked,
  Unchecked,
  Disabled
}

export enum ControlType {
  text,
  button,
  ifButton,
  number,
  link,
  customValue,
  linkList,
  checkbox,
  bool,
  colorDiv
}

interface ExtGridRequestModel extends BaseViewGridModel<any> {
  totalCount: number;
  isPaginationHid: boolean;
  nextButtonStatus?: boolean;
  visibleStartDots?: boolean;
  visibleEndDots?: boolean;
  viewModelList: any[];
  countPages: number;
  viewModelSelectedList: HashTable<Tuple<any, boolean>>;
}


export interface IFieldType {
  type: ControlType;
  allowNull?: boolean;
  defaultText?: string;
  value?: (item: any) => string;
  values?: (item: any) => string[];
  click?: (item: any) => void;
  class?: string;
  hide?: (item: any) => boolean;
  settings?: IFieldTypeSettings;
}

export interface IFieldTypeSettings {
  showIfButton: (item: any) => boolean;
  ifButtonHideText: (item: any) => string;
}

export interface IGridOptions {
  title?: string;
  color?: string;
  fieldType?: IFieldType;
  order?: boolean;
  hide?: boolean;
}

@Component({
  selector: 'grid',
  templateUrl: './grid.component.html'
})
export class GridComponent implements OnChanges, OnInit {

  @Input() fieldOptions: HashTable<IGridOptions>;
  @Input() gridData: BaseViewGridModel<any>;
  @Input() hidePagination: boolean;
  @Input() checkBoxColumn: boolean;
  @Input() maxLengthOfInput: number = 6;
  @Input() maxLengthOfInputStr: string = "999999";
  @Output() gridRequest = new EventEmitter<GridRequestModel>();
  @Output() cellClickRequest = new EventEmitter<GridCellEvent<any>>();
  @Output() rowClickRequest = new EventEmitter<GridCellEvent<any>>();
  @Output() checkedItemsRequest = new EventEmitter<any[]>();

  public static checkColumName: string = "grid-checkbox-column";

  gridModel: ExtGridRequestModel;
  selectedItem: any;

  public orderTypes: any = OrderType;

  public globalCheck: boolean;

  protected helper: Helper;
  //public checkMap:  

  constructor(
    private inputValidation: InputValidationService,
    private translateService: TranslationService) {

    this.helper = new Helper();
  };

  ngOnInit(): void {
    this.fieldOptions[GridComponent.checkColumName] = {
      fieldType: {
        type: ControlType.checkbox
      },
      hide: false
    } as IGridOptions;

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["gridData"] && changes["gridData"].currentValue) {
      var importedData = changes["gridData"].currentValue as BaseViewGridModel<any>;
      let tmpOrderBy = OrderType.None;
      let tmpOrderName = "";
      let tmpPaginationHide = false;

      if (this.gridModel != null) {
        tmpOrderBy = this.gridModel.orderBy;
        tmpOrderName = this.gridModel.orderName;
        tmpPaginationHide = this.gridModel.isPaginationHid;
      }

      if (importedData.viewModelList.length != 0) {
        let selectedItems: HashTable<Tuple<any, boolean>> = {};
        this.globalCheck = false;
        importedData.viewModelList.forEach((elem) => {
          Object.keys(elem).map((key) => {
            if (elem[key] == null)
              elem[key] = ""
          });

          let hash: number = this.helper.hashCodeFromObject(elem);
          selectedItems[hash] = {
            key: elem,
            value: false
          } as Tuple<any, boolean>;

        });

        this.gridModel = {
          isPaginationHid: tmpPaginationHide,
          totalCount: importedData.totalCount,
          pageNumber: importedData.pageNumber,
          pageSize: importedData.pageSize,
          viewModelList: importedData.viewModelList,
          orderBy: tmpOrderBy,
          orderName: tmpOrderName,
          countPages: Math.ceil(importedData.totalCount / importedData.pageSize),
          viewModelSelectedList: selectedItems
        } as ExtGridRequestModel;
      }
      else
        this.gridModel = {
          isPaginationHid: false,
          totalCount: 0,
          pageNumber: 0,
          pageSize: 0,
          viewModelList: [],
          orderBy: tmpOrderBy,
          orderName: tmpOrderName,
          countPages: 1,
          viewModelSelectedList: {}
        } as ExtGridRequestModel;
    }

    if (this.gridModel
      && changes["hidePagination"]
      && changes["hidePagination"].currentValue != undefined) {
      this.gridModel.isPaginationHid = this.hidePagination == true;
    }
  }

  getHeaders = (): string[] => {
    let columnMap = this.optionsFilterAndMap((key, item) => !item.hide && key != GridComponent.checkColumName, (key, item) => key);
    if (this.checkBoxColumn) {
      columnMap.unshift(...[GridComponent.checkColumName]);
    }
    return columnMap;
  }

  getTitle = (key: string): string =>
    this.translateService.translate(this.fieldOptions[key].title);

  optionsFilter = (filterCallback: (key: string, item: IGridOptions) => boolean): string[] =>
    Object.keys(this.fieldOptions).filter((key) => {
      return filterCallback(key, this.fieldOptions[key]);
    });

  optionsFilterAndMap = <T>(filterCallback: (key: string, item: IGridOptions) => boolean,
    mapCallBack: (key: string, item: IGridOptions) => T): T[] =>
    this.optionsFilter(filterCallback).map((key) => mapCallBack(key, this.fieldOptions[key]));


  getFiledType = (key: string): ControlType =>
    this.fieldOptions[key].fieldType instanceof Object ? this.fieldOptions[key].fieldType.type : ControlType.text;


  isInputTypeNumber = (key: string): boolean =>
    this.getFiledType(key) == ControlType.number;

  isInputTypeButton = (key: string): boolean =>
    this.getFiledType(key) == ControlType.button;

  isInputTypeIfButton = (key: string): boolean =>
    this.getFiledType(key) == ControlType.ifButton;

  isLinkButonType = (key: string): boolean =>
    this.getFiledType(key) == ControlType.link;

  isCustomValueType = (key: string): boolean =>
    this.getFiledType(key) == ControlType.customValue;

  isColorValueType = (key: string): boolean =>
    this.getFiledType(key) == ControlType.colorDiv;

  isBoolValueType = (key: string): boolean =>
    this.getFiledType(key) == ControlType.bool;

  isInputTypeText = (key: string): boolean =>
    this.getFiledType(key) == ControlType.text;

  isLinkListButonType = (key: string): boolean =>
    this.getFiledType(key) == ControlType.linkList;

  isCustomColor = (key: string): boolean =>
    !!this.fieldOptions[key].color;

  isCheckBoxColumn = (key: string): boolean =>
    GridComponent.checkColumName == key;

  isShow = (key: string, model: any): boolean => {
    if (this.fieldOptions[key].fieldType && this.fieldOptions[key].fieldType.hide)
      return !this.fieldOptions[key].fieldType.hide(model);

    return true;
  }


  getValue = (key: string, model: any): string => {
    if (this.fieldOptions[key].fieldType && this.fieldOptions[key].fieldType.value)
      return this.translateService.translate(this.fieldOptions[key].fieldType.value(model));

    return model[key];
  }

  getValues = (key: string, model: any): string[] => {
    if (this.fieldOptions[key].fieldType && this.fieldOptions[key].fieldType.values)
      return this.fieldOptions[key].fieldType.values(model);

    return model[key];
  }

  getText = (key: string): string =>
    this.translateService.translate(this.fieldOptions[key].fieldType.defaultText);

  getIfButtonVisible = (key: string, model: any) => {
    return this.fieldOptions[key].fieldType.settings.showIfButton(model);
  }

  getIfButtonHideText = (key: string, model: any) => {
    return this.fieldOptions[key].fieldType.settings.ifButtonHideText(model);
  }

  getCustomCollored = (viewModel: HashTable<any>): string =>
    this.optionsFilterAndMap((key, item) => !!item.color, (key, item) => `${item.color}-${key}-${this.isBoolValueType(key) ? this.getValue(key, viewModel) : viewModel[key]}`).join(' ').trim();

  getClass = (key: string): string =>
    this.fieldOptions[key].fieldType.class || "";


  isCustomField = (key: string): boolean =>
    !!this.fieldOptions[key].fieldType;


  isNullAllowed = (key: string): boolean =>
    this.fieldOptions[key].fieldType && this.fieldOptions[key].fieldType.allowNull;

  isGridDataInitialized = (): boolean =>
    !!this.gridModel;


  getPages = () => {
    var pages: Array<number>;
    var pageToStart: number = this.gridModel.pageNumber - 5;
    var lastShowPage: number = this.gridModel.pageNumber + 5;

    if (pageToStart <= 0)
      pageToStart = 1;

    if (lastShowPage >= this.gridModel.countPages)
      lastShowPage = this.gridModel.countPages;

    pages = new Array();
    while (pageToStart <= lastShowPage) {
      pages.push(pageToStart);
      pageToStart++;
    }
    return pages;
  }

  getNextPage = () => {
    this.gridModel.pageNumber++;
    if (this.gridModel.pageNumber > this.gridModel.countPages)
      this.gridModel.pageNumber = this.gridModel.countPages;
    else
      this.onEmitEvent();
  }

  getPreviousPage = (): void => {
    this.gridModel.pageNumber--;
    if (this.gridModel.pageNumber < 1)
      this.gridModel.pageNumber = 1;
    else
      this.onEmitEvent();
  }

  getPage = (page: number) => {
    this.gridModel.pageNumber = page;
    this.onEmitEvent();
  }

  onChangeCounPerPage = (value) => {
    this.gridModel.pageSize = value;
    this.onEmitEvent();
  }

  onEmitEvent = (): void => {
    const modelCopy = { ...this.gridModel };
    this.gridRequest.emit(modelCopy as GridRequestModel);
  }

  onSelectCell = (selectedModel: any, event: MouseEvent): void => {
    if (event.defaultPrevented)
      return;

    const clone = { ...selectedModel };
    this.selectedItem = clone;
    this.cellClickRequest.emit({ data: this.selectedItem, click: event } as GridCellEvent<any>);
  }

  onSelectRow = (selectedModel: any, $event: Event): void => {
    if ($event.defaultPrevented)
      return;

    const clone = { ...selectedModel };
    this.selectedItem = clone;
    this.rowClickRequest.emit(this.selectedItem);
  }

  getOrderState = (fieldKey: string): OrderType => {
    if (this.gridModel.orderName != fieldKey)
      return OrderType.None;

    return this.gridModel.orderBy;
  }

  getOrderClassByKey = (key: string) => {
    if (key == GridComponent.checkColumName)
      return GridComponent.checkColumName;

    if (this.fieldOptions[key] && this.fieldOptions[key].order === false)
      return "";

    switch (this.getOrderState(key)) {
      case OrderType.None:
        return 'sorting';
      case OrderType.Asc:
        return 'sorting sorting_asc';
      case OrderType.Desc:
        return 'sorting sorting_desc';
      default:
        return '';
    }
  }

  sort = (keyField: string): void => {
    if ((this.fieldOptions[keyField] && this.fieldOptions[keyField].order === false) || keyField == GridComponent.checkColumName)
      return;

    if (this.gridModel.orderName != keyField) {
      this.gridModel.orderName = keyField;
      this.gridModel.orderBy = OrderType.Asc;
    }
    else {
      this.gridModel.orderBy = (this.gridModel.orderBy == OrderType.Asc) ? OrderType.Desc : OrderType.Asc;
    }
    this.onEmitEvent();
  }

  onPageChanged = (selectedPage: number): void => {
    this.gridModel.pageNumber = selectedPage;
    this.onEmitEvent();
  }

  onSelectLink = (key, viewModel: any, $event: Event): void => {
    if (this.fieldOptions[key].fieldType.click)
      this.fieldOptions[key].fieldType.click(viewModel);
    else
      window.open(this.getValue(key, viewModel));
    $event.preventDefault();
  }

  onSelectLinkList = (key, viewModel, link, $event: Event): void => {
    if (this.fieldOptions[key].fieldType.click)
      this.fieldOptions[key].fieldType.click(viewModel);
    else
      window.open(link);
    $event.preventDefault();
  }

  onButtonClick = (key, viewModel, $event: Event): void => {
    if (this.fieldOptions[key].fieldType.click) {
      $event.preventDefault();
      this.fieldOptions[key].fieldType.click(viewModel);
    }
  }

  onCheckedChange = (viewModel: any, $event: Event): void => {
    let hash: number = this.helper.hashCodeFromObject(viewModel);
    this.gridModel.viewModelSelectedList[hash].value = !this.gridModel.viewModelSelectedList[hash].value;
    this.globalCheck = Object.values(this.gridModel.viewModelSelectedList).every(model => model.value);
    $event.preventDefault();
    this.emitCheckBoxEvent();
  }

  onGlobalCheckBox = ($event: Event): void => {
    Object.keys(this.gridModel.viewModelSelectedList).forEach(key => {
      this.gridModel.viewModelSelectedList[key].value = this.globalCheck
    });
    this.emitCheckBoxEvent();
  }

  getHash = (model: object): number =>
    this.helper.hashCodeFromObject(model);


  emitCheckBoxEvent = (): void => {
    this.checkedItemsRequest.emit(Object.values(this.gridModel.viewModelSelectedList).filter(item => item.value).map(item => item.key));
  }

  getCheckItem = (viewModel: object): Tuple<any, boolean> =>
    this.gridModel.viewModelSelectedList[this.getHash(viewModel)] || {} as Tuple<any, boolean>;

}
