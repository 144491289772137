import { Component, OnInit } from '@angular/core';
/* import { HashTable } from 'src/Helper/index'; */
import { HashTable } from '../../../../Helper/index';
import { JobStateApiService } from '../../../../api/index';
import { ControlType, IGridOptions } from '../../../components/grid/grid.component';
import { BaseViewGridModel, FilteredGridRequestViewModel, GridRequestModel, JobStateViewFilterModels, JobStateViewModel } from '../../../../models/index';
import { NotificationService } from '../../../services';

@Component({
  selector: 'app-job-state',
  templateUrl: './job-state.component.html',
  styleUrls: ['./job-state.component.less']
})
export class JobStateComponent implements OnInit {
  gridData: BaseViewGridModel<JobStateViewModel>;
  gridRequestModel: FilteredGridRequestViewModel<JobStateViewFilterModels>;
  fieldOptions: HashTable<IGridOptions>;
  selectedModel: JobStateViewModel;
  showDeleteDialog: boolean;
  showDialog: boolean;


  constructor(private jobStateService: JobStateApiService,
    private notify: NotificationService) {
    this.gridData = {
      viewModelList: []
    } as BaseViewGridModel<JobStateViewModel>;

    this.gridRequestModel = {} as FilteredGridRequestViewModel<JobStateViewFilterModels>;

    this.fieldOptions = {
      name: {
        title: 'Наименование'
      },
      color: {
        title: 'Цвет',
        fieldType: {
          type: ControlType.colorDiv
        }
      },
      delete: {
        fieldType: {
          type: ControlType.button,
          defaultText: 'Удалить',
          class: 'btn btn-danger',
          click: (item: JobStateViewModel) => this.deleteButtonClick(item)
        },
        order: false
      }
    };
  }

  ngOnInit() {
    this.initGrid();
  }

  initGrid = () => {
    this.jobStateService.getAll(this.gridRequestModel).subscribe(s => {
      this.gridData = { ...s };
    });
  }

  gridRequestEvent = (model: GridRequestModel) => {
    this.gridRequestModel = model as FilteredGridRequestViewModel<JobStateViewFilterModels>;
    this.initGrid();
  }

  addItem = (): void => {
    this.selectedModel = {} as JobStateViewModel;
    this.showDialog = true;
  }

  rowClickEvent = (model: JobStateViewModel) => {
    this.selectedModel = { ...model };
    this.showDialog = true;
  }

  saveDialog = (): void => {
    this.jobStateService.save(this.selectedModel).subscribe(_ => {
      this.notify.showInfo("Запись сохранена успешно");
      this.showDialog = false;
      this.initGrid();
    });
  }

  deleteButtonClick = (model: JobStateViewModel) => {
    this.showDeleteDialog = true;
    this.selectedModel = { ...model };
  }

  approveDelete = (model: JobStateViewModel) => {
    if (!this.selectedModel) {
      this.showDeleteDialog = false;
      return;
    }

    this.jobStateService.delete(this.selectedModel.id).subscribe(_ => {
      this.notify.showInfo("Запись удалена!");
      this.showDeleteDialog = false;
      this.initGrid();
    });
  }

  closeDeleteDialog = ($event) => this.showDeleteDialog = false;

  closeDialog = ($event) => this.showDialog = false;

}
