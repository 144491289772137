import { Component, OnInit } from '@angular/core';
import { JobApiService, PersonApiService, DictionaryApiService, WorkApiService } from '../../../../api/index';
import { FilteredGridRequestViewModel, BaseViewGridModel, GridRequestModel, PersonViewFilterModels, WorkViewModel, PersonType, BaseDropDownViewModel, PersonTypeViewModel, Work, WorkViewFilterModels, PersonViewModel, Person, PersonModel } from '../../../../models/index';
import { HashTable, fullDoctorName, fullPersonName } from '../../../../Helper/index';
import { retry } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NotificationService } from '../../../services';
import { DatePipe } from '@angular/common';
import { IGridOptions, ControlType } from '../../../components/grid/grid.component';


@Component({
  selector: 'work',
  templateUrl: './work.component.html',
  providers: [DatePipe]
})
export class WorkComponent implements OnInit {

  gridData: BaseViewGridModel<WorkViewModel>;
  fieldOptions: HashTable<IGridOptions>;
  gridRequestModel: FilteredGridRequestViewModel<WorkViewFilterModels>;
  showDialog: boolean;
  showDeleteDialog: boolean;
  selectedModel: WorkViewModel;
  selectedWork: Work;
  doctors: Person[];
  currentDoctor: string;

  constructor(
    private service: WorkApiService,
    private notify: NotificationService,
    private datePipe: DatePipe,
    private personApiService: PersonApiService
  ) {
    this.gridData = {
      viewModelList: []
    } as BaseViewGridModel<WorkViewModel>;

    this.gridRequestModel = {} as FilteredGridRequestViewModel<WorkViewFilterModels>;

    this.fieldOptions = {
      name: {
        title: 'Наименование'
      },
      generate: {
        fieldType: {
          type: ControlType.button,
          defaultText: 'Удалить',
          class: 'btn btn-danger',
          click: (item: WorkViewModel) => this.deleteButtonClick(item)
        },
        order: false
      }
    };
    this.showDialog = false;
    this.selectedModel = {} as WorkViewModel;


  }

  ngOnInit() {

    this.initGrid();
  }

  initGrid = () => {
    this.service.getAll(this.gridRequestModel).subscribe((data) => {
      this.gridData = { ...data };
    });
  }

  transformDate = (date): string =>
    this.datePipe.transform(date, 'dd.MM.yyyy HH:mm');

  gridRequestEvent = (model: GridRequestModel) => {
    this.gridRequestModel = model as FilteredGridRequestViewModel<WorkViewFilterModels>;
    this.initGrid();
  }

  rowClickEvent = (model: WorkViewModel) => {
    this.selectedModel = { ...model };
    this.showDialog = true;

  }

  addItem = (): void => {
    this.selectedModel = {} as WorkViewModel;
    this.showDialog = true;
  }

  closeDialog = ($event) => this.showDialog = false;

  saveDialog = (): void => {
    this.service.save(this.selectedModel).subscribe(_ => {
      this.notify.showInfo("Запись сохранена успешно");
      this.showDialog = false;
      this.initGrid();
    });
  }

  deleteButtonClick = (model: WorkViewModel) => {
    this.showDeleteDialog = true;
    this.selectedModel = { ...model };
  }

  approveDelete = (model: WorkViewModel) => {
    console.log(model);
    if (!this.selectedModel) {
      this.showDeleteDialog = false;
      return;
    }

    this.service.delete(this.selectedModel.id).subscribe(_ => {
      this.notify.showInfo("Запись удалена!");
      this.showDeleteDialog = false;
      this.initGrid();
    });
  }

  closeDeleteDialog = ($event) => this.showDeleteDialog = false;
}
