import { Component, OnInit, Output, EventEmitter, NgZone, Input } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { AuthService } from '../../services/index';
import { Person } from '../../../models';

@Component({
  selector: 'client-header',
  templateUrl: './client-header.component.html'
})
export class ClientHeaderComponent implements OnInit{    
  viberCode: string;
  viberUrl: SafeUrl;
  person: Person;

  constructor(private authSerivice: AuthService,
    private sanitizer: DomSanitizer) {
    
  }

  ngOnInit(): void {
    this.authSerivice.getUserData().subscribe(s => {
      this.person = { ...s };

      this.viberUrl = this.sanitize('viber://pa?chatURI=adentalstudio&text=' + this.person.viberCode);
    });
  }

  logOut = (): void => {
    this.authSerivice.logout();
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

}
