import { HashTable } from "./hash-table";
import { IGridOptions } from "../app/components/grid/grid.component";
import { Observable } from "rxjs";
import { UrlMatcher } from "@angular/router";
import { Response } from "@angular/http";
import { Tuple } from ".";

export class Helper {

  constructor() {
  }

  mapGridSettings = (gridOptions: HashTable<IGridOptions>, userOptions: HashTable<IGridOptions>): void =>
    Object.keys(userOptions).forEach(key => {
      if (gridOptions[key])
        gridOptions[key].hide = userOptions[key].hide;
    });

  hashCodeFromText = (text: string): number => {
    let hash: number = 0,
      i: number,
      chr: number;

    if (text.length === 0) return hash;
    for (i = 0; i < text.length; i++) {
      chr = text.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr;
      hash |= 0;
    }
    return hash;
  };

  hashCodeFromObject = (item: object): number =>
    this.hashCodeFromText(JSON.stringify(item));

  valueFromObject = (obj: object, key: any, defaultValue: any): any => {
    if (key in obj)
      return obj[key];

    return defaultValue;
  }


  download = (func: () => Observable<Response>, optons: HashTable<any>, isNewWindow: boolean = true, fileName: string = null): void => {
    func().subscribe(data => {
      var disposition = data.headers.get('Content-Disposition');
      var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      var matches = filenameRegex.exec(disposition);
      var fileNameFromServer = null;
      if (matches != null && matches[1]) {
        fileNameFromServer = matches[1].replace(/['"]/g, '');
        fileNameFromServer = decodeURI(fileNameFromServer);
      }
      this.createAndDownloadBlobFile(data.blob(), optons, fileName || fileNameFromServer, isNewWindow);
    });
  }

  groupByNumber = <T>(items: T[], keyFunc: (item: T) => number): HashTable<T[]> => {
    let grouped: HashTable<T[]> = {};
    items.forEach((item, number, array) => {
      if (!grouped[keyFunc(item)])
        grouped[keyFunc(item)] = [];

      grouped[keyFunc(item)].push(item);
    });
    return grouped;
  }

  groupBy = <T>(items: T[], keyFunc: (item: T) => string): HashTable<T[]> => {
    let grouped: HashTable<T[]> = {};
    items.forEach((item, number, array) => {
      if (!grouped[keyFunc(item)])
        grouped[keyFunc(item)] = [];

      grouped[keyFunc(item)].push(item);
    });
    return grouped;
  }

  aggregateGroup = <T, K>(items: HashTable<T[]>, defaultValue: K, action: (value: K, nextItem: T) => K): HashTable<K> => {
    let grouped: HashTable<K> = {};
    Object.keys(items).forEach((key) => {
      let result: K = defaultValue;
      items[key].forEach((item) => {
        result = action(result, item);
      });
      grouped[key] = result;
    });
    return grouped;
  }

  mapGroupToObjectArray = <V>(items: HashTable<V>): Tuple<string, V>[] => {
    let result: Tuple<string, V>[] = [];
    Object.keys(items).forEach((key) => {
      result.push({ key: key, value: items[key] });
    });
    return result;
  }

  createAndDownloadBlobFile = (body: Blob, options: HashTable<any>, filename: string, isNewWindow: boolean): void => {
    var blob = new Blob([body], options);
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, filename);
    }
    else {
      var link = document.createElement('a');
      if (link.download !== undefined) {
        var url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        if (isNewWindow)
          link.setAttribute('target', "_blank");
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  convertUtcStringToLocaleDate(dtStr) {
    if (!dtStr)
      return null;

    let dt = new Date(dtStr);
    let offset = new Date().getTimezoneOffset() * -1;
    dt.setMinutes(dt.getMinutes() + offset);
    return dt;
  }
}
