﻿//------------------------------------------------------------------------------
//  This code was generated by typescript template tool 'Typewriter'.
//
//  Changes to this file may cause incorrect behavior and will be lost if
//  the code is regenerated.
//------------------------------------------------------------------------------


import { Injectable } from "@angular/core";
import { ResponseContentType, Response } from "@angular/http";
import { Observable } from 'rxjs';
import { BasApiService } from '../base.api.service';
import {GridApiService} from './grid.api.service';
import * as ApiTypes from "../../models";

@Injectable()
export class ProtezApiService extends GridApiService<ApiTypes.ProtezViewModel,ApiTypes.ProtezViewFilterModels>{
  
  constructor(public baseService: BasApiService) {
   super(baseService);
    this.apiKey = "api/protez";
  }
  
  delete = (id: number): Observable<void> =>
    this.baseService.delete<void>(`api/Protez/${id}`, {}, true, true);   
  
  save = (model: ApiTypes.ProtezViewModel): Observable<void> =>
    this.baseService.post<void>(`api/Protez`, model, {}, true, true);   
  
  }

