import { Component, EventEmitter, Output, Input, OnChanges, SimpleChanges, OnInit, ViewChild, ElementRef, forwardRef, OnDestroy } from '@angular/core';

import { ControlValueAccessor, Validator, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl, NgModel } from '@angular/forms';
import { NgForm } from '@angular/forms';
import { HashTable } from '../../../Helper/index';
import { ProtezView } from '../../../models';
import { ToothModel } from './tooth.component';
import { forEach } from '@angular/router/src/utils/collection';
import { Protez } from '../../../models/server/Dental-DataModel-Models-Protez';

class ExtProtezView extends ProtezView {
  vertical: number;
  horisontal: number;
  toothNumber: number;
}


class Position {
  vertical: number;
  horisontal: number;
}

@Component({
  selector: 'protez',
  templateUrl: './protez.component.html'
})
export class ProtezComponent implements OnChanges, OnInit {
  @Input() protezData: ProtezView[];
  @Input() isEditable: boolean;
  @Input() toothType: Protez;
  @Output() change: EventEmitter<ProtezView[]> = new EventEmitter<ProtezView[]>();

  enrichedData: ExtProtezView[];
  initialData: HashTable<ProtezView>;
  models: HashTable<ToothModel[]>;

  constructor() {
    this.enrichedData = [];
    this.initDefaultProtez();
    this.models = {};
  }


  private initDefaultProtez = (): void => {
    this.initialData = {};
    //Верхняя челюсть с 1 по 16 позиции зубов
    //Нижняя челюсть с 21 по 36 позицици зубов

    for (let i: number = 0; i < 16; i++) {
      this.initialData[i] = {
        state: 0,
        protezName: '-',
        position: i
      } as ProtezView;
    }

    for (let i: number = 20; i < 36; i++) {
      this.initialData[i] = {
        state: 0,
        protezName: '-',
        position: i
      } as ProtezView;
    }
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["protezData"] && changes["protezData"].currentValue) {
      this.initDefaultProtez();

      for (let tooth of this.protezData) {
        let toothClone = { ...tooth };
        toothClone.position--;
        if (toothClone.protezTypeId == 2)
          this.initialData[toothClone.position] = toothClone;
      }

      this.enrichedData = Object.values(this.initialData).map(this.enrichData);

      this.models = {};
      this.models[11] = this.filterProtez(11);
      this.models[12] = this.filterProtez(12);
      this.models[21] = this.filterProtez(21);
      this.models[22] = this.filterProtez(22);
    }
  }

  enrichData = (protez: ProtezView): ExtProtezView => {
    let protezPosition = protez.position;
    let toothPositionVertical: Position = this.getPosition(protezPosition, 20);
    let toothPositionHorisontal: Position = this.getPosition(toothPositionVertical.vertical, 8);
    return {
      ...protez as ExtProtezView,
      horisontal: toothPositionVertical.horisontal + 1,
      vertical: toothPositionHorisontal.horisontal + 1,
      toothNumber: toothPositionHorisontal.vertical + 1
    };
  }

  filterProtez = (quarter: number): ToothModel[] => {
    if (!this.enrichedData || this.enrichedData.length == 0)
      return [];

    let position: Position = this.getPosition(quarter, 10);

    let quart = this.enrichedData.filter((protez) => {
      return position.horisontal == protez.horisontal && position.vertical == protez.vertical;
    }).map((protez) => {
      let tooth = {
        position: position.vertical == 1 ? 9 - protez.toothNumber : protez.toothNumber,
        state: protez.state,
        type: { id: protez.protezId, name: protez.protezName, protezTypeId: 2 } as Protez,
        realPosition: protez.position
      } as ToothModel;
      return tooth;
    });

    return quart;
  }

  getPosition = (num: number, delimeter: number): Position => {
    let val = num % delimeter;
    let res = (num - val) / delimeter;

    return {
      horisontal: res,
      vertical: val
    } as Position;
  }

  onToothChange = ($event: ToothModel): void => {
    let protezes: ToothModel[] = [];
    protezes.push(...this.models[11]);
    protezes.push(...this.models[12]);
    protezes.push(...this.models[21]);
    protezes.push(...this.models[22]);
    let views = protezes.filter(s => s.state != 0).map(s => {
      return {
        position: s.realPosition + 1,
        protezId: s.type.id,
        protezTypeId: 2,
        protezName: s.name,
        state: s.state
      } as ProtezView
    });
    this.change.emit(views);
  }

}
