import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'gallery',
  templateUrl: './gallery.component.html'
})
export class GalleryComponent {

  messages: string[];
  constructor() {
    this.messages = [];
  }

  doMessage = (text: string) => {
    this.messages.push(text);
  }
}
