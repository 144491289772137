import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { ProtezComponent } from "./protez.component";
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';
import { ToothComponent } from "./tooth.component";
import { DropDownModule } from "../drop-down/drop-down.module";

@NgModule({
  declarations: [ProtezComponent, ToothComponent],
  imports: [RouterModule, CommonModule, FormsModule, DropDownModule],
  exports: [ProtezComponent, ToothComponent]
})

export class ProtezModule { }
