import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { PersonViewModel, BaseDropDownViewModel, RoleType, BaseViewGridModel, UserFileItemGridViewModel, ClinicAuditorViewModel } from '../../../models';
import { PersonApiService, PersonCustomApiService, DictionaryApiService } from '../../../api';
import { NotificationService } from '../../services';
import { IGridOptions, ControlType } from '../grid/grid.component';
import { HashTable } from '../../../Helper';
import { Observable } from 'rxjs';
import { commonSetting } from '../../services/common.settings';

@Component({
  selector: 'user-modal',
  templateUrl: './user-modal.component.html'
})
export class UserModalComponent implements OnInit {

  @Input() userModel: PersonViewModel;
  @Input() selectedRole: BaseDropDownViewModel<number>;
  @Input() isEditMode: boolean;
  @Input() positions: BaseDropDownViewModel<number>[];
  @Input() workTypes: BaseDropDownViewModel<number>[];

  personTypesList = RoleType;
  userFilesListGrid: BaseViewGridModel<UserFileItemGridViewModel>;
  fileToUpload: File = null;
  fieldOptionsUserFiles: HashTable<IGridOptions>;
  fieldOptionsClinicAuditor: HashTable<IGridOptions>;
  clinicsByAuditorlistGrid: BaseViewGridModel<ClinicAuditorViewModel>;
  selectedClinicAuditorId: number;
  clinicAuditorListDD: BaseDropDownViewModel<number>[];

  selectedPosition: BaseDropDownViewModel<number>;
  selectedWorkType: BaseDropDownViewModel<number>;

  themeName = commonSetting.datePickerName;

  constructor(
    private service: PersonApiService,
    private personCustomService: PersonCustomApiService,
    private notify: NotificationService,
    private dictionarySerivce: DictionaryApiService,
  ) {

    this.selectedPosition = {} as BaseDropDownViewModel<number>;
    this.selectedWorkType = {} as BaseDropDownViewModel<number>;

    this.userFilesListGrid = {
      viewModelList: []
    } as BaseViewGridModel<UserFileItemGridViewModel>;

    this.fieldOptionsUserFiles = {
      name: {
        title: 'Файл'
      },
      download: {
        fieldType: {
          type: ControlType.link,
          defaultText: 'Скачать',
          value: (item: UserFileItemGridViewModel) =>
            `files/${item.systemName}`
        },
        order: false
      },
      generate: {
        fieldType: {
          type: ControlType.button,
          defaultText: 'Удалить',
          click: (item: UserFileItemGridViewModel) => this.removeUserFile(item)
        },
        order: false
      }
    };

    this.fieldOptionsClinicAuditor = {
      clinicName: {
        title: 'Клиника'
      },
      generate: {
        fieldType: {
          type: ControlType.button,
          defaultText: 'Удалить',
          click: (item: ClinicAuditorViewModel) => this.removeClinicFromAuditor(item)
        },
        order: false
      }
    };

    this.clinicsByAuditorlistGrid = {
      viewModelList: []
    } as BaseViewGridModel<ClinicAuditorViewModel>;

    this.clinicAuditorListDD = [];
    this.workTypes = [];
    this.positions = [];
  }

  ngOnInit() {
    this.initUserFilesListGrid(this.userModel.personId)
    this.initClinicByAuditorGrid(this.userModel.personId);
    this.initClinicAuditorListDD();
    this.selectedWorkType = this.workTypes.filter(s => s.id == this.userModel.workTypeId)[0] || {} as BaseDropDownViewModel<number>;
    this.selectedPosition = this.positions.filter(s => s.id == this.userModel.positionId)[0] || {} as BaseDropDownViewModel<number>;
  }


  initUserFilesListGrid = (personId: number) => {
    if (personId) {
      this.service.getUserFiles(personId).subscribe(data => {
        console.log('initUserFilesListGrid');
        console.log(data);

        this.userFilesListGrid = {
          viewModelList: data,
          totalCount: data.length,
          pageNumber: 1,
          pageSize: 25
        } as BaseViewGridModel<UserFileItemGridViewModel>;
      });
    }
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    console.log(this.fileToUpload);

    const formData: FormData = new FormData();
    formData.append('fileItem', this.fileToUpload, this.fileToUpload.name);
    formData.append('personId', this.userModel.personId.toString());

    this.personCustomService.uploadUserFile(formData).subscribe(s => {
      this.notify.showInfo("Файл сохранен успешно");
      this.initUserFilesListGrid(this.userModel.personId);
    });
  }

  removeUserFile(item: UserFileItemGridViewModel) {
    console.log(item);
    if (item) {
      this.service.deleteUserFile(item.id).subscribe(_ => {
        this.notify.showInfo("Запись удалена успешно");
        this.initUserFilesListGrid(this.userModel.personId);
      });
    }
  }

  initClinicByAuditorGrid = (auditorId: number) => {
    this.service.getClinicListByAuditor(auditorId).subscribe(data => {
      console.log('initClinicByAuditor');
      console.log(data);

      this.clinicsByAuditorlistGrid = {
        viewModelList: data,
        totalCount: data.length,
        pageNumber: 1,
        pageSize: 25
      } as BaseViewGridModel<ClinicAuditorViewModel>;
    });
  }

  addClinicToAuditor() {
    console.log(this.selectedClinicAuditorId);

    if (this.selectedClinicAuditorId <= 0 || this.userModel.personId <= 0)
      return;

    this.service.addClinicToAuditor(
      {
        clinicId: this.selectedClinicAuditorId,
        auditorId: this.userModel.personId
      } as ClinicAuditorViewModel)
      .subscribe(s => {
        this.notify.showInfo("Запись сохранена успешно");
        this.initClinicAuditorListDD();
        this.initClinicByAuditorGrid(this.userModel.personId);
      });
  }

  removeClinicFromAuditor(item: ClinicAuditorViewModel) {
    console.log(item);
    if (item) {
      this.service.removeClinicFromAuditor(item).subscribe(_ => {
        this.notify.showInfo("Запись удалена успешно");
        this.initClinicAuditorListDD();
        this.initClinicByAuditorGrid(this.userModel.personId);
      });
    }
  }

  initClinicAuditorListDD = () => {
    if (!this.userModel || this.userModel.personId <= 0)
      return;

    this.service.getAvailableClinics(this.userModel.personId).subscribe(data => {
      this.selectedClinicAuditorId = -1;
      this.clinicAuditorListDD = [];
      this.clinicAuditorListDD.push(...data);
    });
  }

  OnWorkTypeChange = ($event: BaseDropDownViewModel<number>): void => {
    this.selectedWorkType = $event;
    this.userModel.workTypeId = this.selectedWorkType.id;
  }

  OnPositionChange = ($event: BaseDropDownViewModel<number>): void => {
    this.selectedPosition = $event;
    this.userModel.positionId = this.selectedPosition.id;
  }

}
