﻿//------------------------------------------------------------------------------
//  This code was generated by typescript template tool 'Typewriter'.
//
//  Changes to this file may cause incorrect behavior and will be lost if
//  the code is regenerated.
//------------------------------------------------------------------------------

import { IModel } from "./Dental-DataModel-Interfaces-IModel";
import { RoleType } from "./Dental-DataModel-CustomTypes-RoleType";

export class PersonType  implements IModel {
    
    id: number;
    name: string;
    roleType: RoleType;
}




  
