import { Component, OnInit, Output, EventEmitter, NgZone, Input } from '@angular/core';
import { MenuService } from '../../../../services/index';

@Component({
  selector: 'main-header',
  templateUrl: './main-header.component.html'
})
export class MainHeaderComponent implements OnInit {
  
  menuIsOpen: boolean;

  constructor(private menuSerivice: MenuService) {
    this.menuIsOpen = false;
  }

  ngOnInit() {
    this.menuSerivice.getState().subscribe((state) => {
      this.menuIsOpen = state;
    });
  }

  menuClick = (): void => {
    this.menuSerivice.updateState(!this.menuIsOpen);
  }

}
