﻿//------------------------------------------------------------------------------
//  This code was generated by typescript template tool 'Typewriter'.
//
//  Changes to this file may cause incorrect behavior and will be lost if
//  the code is regenerated.
//------------------------------------------------------------------------------

import { IBaseModel } from "./Dental-DataModel-Interfaces-IBaseModel";

export class ProtezView  implements IBaseModel {
    
    jobID: number;
    protezTypeId: number;
    protezId: number;
    protezName: string;
    position: number;
    state: number;
}




  
