﻿//------------------------------------------------------------------------------
//  This code was generated by typescript template tool 'Typewriter'.
//
//  Changes to this file may cause incorrect behavior and will be lost if
//  the code is regenerated.
//------------------------------------------------------------------------------


import { Injectable } from "@angular/core";
import { ResponseContentType, Response } from "@angular/http";
import { Observable } from 'rxjs';
import { BasApiService } from '../base.api.service';

import * as ApiTypes from "../../models";


export class GridApiService<TResult,TFilter> {
  
  protected baseService: BasApiService;
  protected apiKey:string;
  
  constructor( baseService: BasApiService) {
   this.baseService = baseService;
  }
  
  getAll = (queryModel: ApiTypes.FilteredGridRequestViewModel<TFilter>): Observable<ApiTypes.BaseViewGridModel<TResult>> =>
    this.baseService.post<ApiTypes.BaseViewGridModel<TResult>>(this.apiKey+`/all`, queryModel, {}, true, true);
  
  }

