﻿//------------------------------------------------------------------------------
//  This code was generated by typescript template tool 'Typewriter'.
//
//  Changes to this file may cause incorrect behavior and will be lost if
//  the code is regenerated.
//------------------------------------------------------------------------------


import { Injectable } from "@angular/core";
import { ResponseContentType, Response } from "@angular/http";
import { Observable } from 'rxjs';
import { BasApiService } from '../base.api.service';
import {GridApiService} from './grid.api.service';
import * as ApiTypes from "../../models";

@Injectable()
export class PositionApiService extends GridApiService<ApiTypes.PositionViewModel,ApiTypes.PositionViewFilterModels>{
  
  constructor(public baseService: BasApiService) {
   super(baseService);
    this.apiKey = "api/position";
  }
  
  save = (model: ApiTypes.PositionViewModel): Observable<void> =>
    this.baseService.post<void>(`api/Position/save`, model, {}, true, true);   
  
  delete = (id: number): Observable<void> =>
    this.baseService.delete<void>(`api/Position/${id}`, {}, true, true);   
  
  }

