import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'nav-menu',
  templateUrl: './nav-menu.component.html'
})
export class NavMenuComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
