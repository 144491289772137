﻿//------------------------------------------------------------------------------
//  This code was generated by typescript template tool 'Typewriter'.
//
//  Changes to this file may cause incorrect behavior and will be lost if
//  the code is regenerated.
//------------------------------------------------------------------------------



export class SalaryBookViewModel   {
    
    protezId: number;
    protezName: string;
    workTypeId?: number;
    workTypeName: string;
    price?: number;
    percentage?: number;
    personId?: number;
    personPositionName: string;
    lastName: string;
    middleName: string;
    firstName: string;
    personIds: number[];
}




  
