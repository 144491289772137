import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from "@angular/router";

import { LayoutAuditorComponent } from './layout-auditor.component';
import { HomeComponent } from './home/home.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { AuthGuard } from '../../services/index';
import * as components from '../../components';
import { HttpClientModule } from '@angular/common/http';

const layoutAuditorRoutes: Routes = [{
  path: 'auditor',
  component: LayoutAuditorComponent,
  canActivate: [AuthGuard],
  children: [
    { path: '', component: HomeComponent, data: { title: 'Home' } }
  ]
}];

let imports: any = [
  BrowserModule,
  FormsModule,
  HttpClientModule,
  RouterModule.forChild(layoutAuditorRoutes)
];

//add all modules for component
imports.push(...Object.keys(components).map(key => components[key]));

@NgModule({
  declarations: [
    LayoutAuditorComponent,
    HomeComponent,
    NavMenuComponent
  ],
  imports: [imports],
  providers: [],
  exports: [LayoutAuditorComponent, HomeComponent, NavMenuComponent]
})
export class LayoutAuditorModule { }
