export const commonSetting = {
    /*
    $theme-gray:      #777;
    $theme-green:     #5cb85c;
    $theme-blue:      #5bc0de;
    $theme-dark-blue: #337ab7;
    $theme-red:       #d9534f;
    $theme-orange:    #f0ad4e;
     */
    datePickerName: 'theme-dark-blue'
};