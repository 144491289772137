import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from "@angular/router";

import { HomeComponent } from './home.component';
import * as components from '../../../components';
import { HttpClientModule } from '@angular/common/http';

let imports: any = [
  BrowserModule,
  FormsModule,
  HttpClientModule
];

//add all modules for component
imports.push(...Object.keys(components).map(key => components[key]));

@NgModule({
  declarations: [
    HomeComponent 
  ],
  imports: [imports],
  providers: [],
  exports: [
    HomeComponent 
  ]
})
export class HomeModule { }
//FYI: This module used for  taskmaster and techincian role, same page with same flow
