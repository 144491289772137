import { Component, OnInit } from '@angular/core';
import { FilteredGridRequestViewModel, JobStateViewFilterModels, JobTimerViewModel, PositionViewFilterModels, ProtezViewFilterModels, WorkTypeViewFilterModels } from '../../../../models/index';
import { JobStateApiService, JobTimerApiService, PositionApiService, ProtezApiService, WorkTypeApiService } from '../../../../api/index';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-job-timer-item',
  templateUrl: './job-timer-item.component.html',
  styleUrls: ['./job-timer-item.component.less']
})
export class JobTimerItemComponent implements OnInit {
  currentStepNum = 0;
  nextStepStr = 'Дальше';
  prevStepStr = 'Назад';

  protezList = [];
  protezRequestModel: FilteredGridRequestViewModel<ProtezViewFilterModels>;
  currentItem = {
    protezID: -1,
    workTypeID: -1,
    positionID: -1,
    jobStateID: -1,
    timeHour: 0
  } as JobTimerViewModel;

  workTypeList = [];
  workTypeRequestModel: FilteredGridRequestViewModel<WorkTypeViewFilterModels>;

  positionList = [];
  positionRequestModel: FilteredGridRequestViewModel<PositionViewFilterModels>;

  jobStateList = [];
  jobStateRequestModel: FilteredGridRequestViewModel<JobStateViewFilterModels>;
  itemId = -1;

  constructor(private protezService: ProtezApiService,
    private workTypeService: WorkTypeApiService,
    private positionService: PositionApiService,
    private jobStateService: JobStateApiService,
    private jobTimerService: JobTimerApiService,
    private router: Router,
    private route: ActivatedRoute) {
    this.protezRequestModel = {
      pageSize: 1000000,
      pageNumber: 1,
      filter: {
        filterModel: {}
      }
    } as FilteredGridRequestViewModel<ProtezViewFilterModels>;

    this.workTypeRequestModel = {      
      pageSize: 1000000,
      pageNumber: 1
    } as FilteredGridRequestViewModel<WorkTypeViewFilterModels>;
    
    this.positionRequestModel = {      
      pageSize: 1000000,
      pageNumber: 1
    } as FilteredGridRequestViewModel<PositionViewFilterModels>;
    
    this.jobStateRequestModel = {
      pageSize: 1000000,
      pageNumber: 1
    } as FilteredGridRequestViewModel<JobStateViewFilterModels>;
  }

  ngOnInit() {
    this.route.params.subscribe(s => {
      this.itemId = s['id'];
      console.log(this.itemId);

      if (this.itemId) {
        this.jobTimerService.getById(s['id']).subscribe(t => {
          this.currentItem = { ...t };
        });
      }

      this.getData();
    });
  }

  getData = () => {
    this.getProtezList();
    this.getWorkTypeList();
    this.getPositionList();
    this.getJobStateList();
  }

  getProtezList = () => {
    this.protezService.getAll(this.protezRequestModel).subscribe(s => {
      this.protezList = [...s.viewModelList];
      console.log(this.protezList);
    });
  }

  getWorkTypeList = () => {
    this.workTypeService.getAll(this.workTypeRequestModel).subscribe(s => {
      this.workTypeList = [...s.viewModelList];
      console.log(this.workTypeList);
    });
  }

  getPositionList = () => {
    this.positionService.getAll(this.positionRequestModel).subscribe(s => {
      this.positionList = [...s.viewModelList];
      console.log(this.positionList);
    });
  }

  getJobStateList = () => {
    this.jobStateService.getAll(this.jobStateRequestModel).subscribe(s => {
      this.jobStateList = [...s.viewModelList];
      console.log(this.jobStateList);
    });
  }

  onNextStep = (stepNum: number) => {
    this.currentStepNum = stepNum;

    console.log(this.currentItem);
  }

  onPrevSterp = (stepNum: number) => {
    this.currentStepNum = stepNum;
  }

  onSaveClick = () => {
    this.jobTimerService.save(this.currentItem).subscribe(s => {
      this.router.navigate(['/administrator/job-timer'])
    });
  }

}
