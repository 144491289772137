﻿//------------------------------------------------------------------------------
//  This code was generated by typescript template tool 'Typewriter'.
//
//  Changes to this file may cause incorrect behavior and will be lost if
//  the code is regenerated.
//------------------------------------------------------------------------------


import { Injectable } from "@angular/core";
import { ResponseContentType, Response } from "@angular/http";
import { Observable } from 'rxjs';
import { BasApiService } from '../base.api.service';
import {GridApiService} from './grid.api.service';
import * as ApiTypes from "../../models";

@Injectable()
export class SalaryApiService extends GridApiService<ApiTypes.SalaryViewModel,ApiTypes.SalaryViewFilterModels>{
  
  constructor(public baseService: BasApiService) {
   super(baseService);
    this.apiKey = "api/salary";
  }
  
  applySallary = (queryModel: ApiTypes.FilteredGridRequestViewModel<ApiTypes.SalaryViewFilterModels>): Observable<void> =>
    this.baseService.post<void>(`api/Salary/apply-sallary`, queryModel, {}, true, true);   
  
  accurateSallary = (sallaryId: number): Observable<void> =>
    this.baseService.put<void>(`api/Salary/apply-sallary/${sallaryId}`, null, {}, true, true);   
  
  getSallary = (): Observable<ApiTypes.SalaryViewModel[]> =>
    this.baseService.get<ApiTypes.SalaryViewModel[]>(`api/Salary/list`, {}, true, true);   
  
  getArchiveSalary = (queryModel: ApiTypes.SalaryArchiveViewFilterModels): Observable<ApiTypes.SalaryViewModel[]> =>
    this.baseService.post<ApiTypes.SalaryViewModel[]>(`api/Salary/archive-list`, queryModel, {}, true, true);   
  
  getStartDate = (): Observable<any> =>
    this.baseService.get<any>(`api/Salary/GetStartDate`, {}, true, true);   
  
  userConfirmSallary = (sallaryId: number): Observable<void> =>
    this.baseService.post<void>(`api/Salary/apply-user-confirm/${sallaryId}`, null, {}, true, true);   
  
  }

