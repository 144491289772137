import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateTimePickerComponent } from './date-time-picker.component';
import { DatePickerModule } from '../date-picker/date-picker.module';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    DatePickerModule,
    TimepickerModule.forRoot()
  ],
  declarations: [DateTimePickerComponent],
  exports:[DateTimePickerComponent]
})
export class DateTimePickerModule { }
