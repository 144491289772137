﻿//------------------------------------------------------------------------------
//  This code was generated by typescript template tool 'Typewriter'.
//
//  Changes to this file may cause incorrect behavior and will be lost if
//  the code is regenerated.
//------------------------------------------------------------------------------

import { IDeleteModel } from "./Dental-DataModel-Interfaces-IDeleteModel";

export class Work  implements IDeleteModel {
    
    id: number;
    name: string;
    deleteDate?: any;
}




  
