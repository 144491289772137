import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { LayoutAuditorModule } from './pages/auditor/layout-auditor.module';
import { LayoutAuthModule } from './pages/auth/layout-auth.module';
import { LayoutClinicModule } from './pages/clinic/layout-clinic.module';
import { LayoutTechincianModule } from './pages/techincian/layout-techincian.module';
import { LayoutAdministratorModule } from './pages/admin/layout-admin.module';
import { registerLocaleData, CommonModule } from '@angular/common';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TabsModule } from 'ngx-bootstrap/tabs'

import * as api from '../api';
import * as services from '../app/services';
import { LayoutTaskmasterModule } from './pages/taskmaster/layout-taskmaster.module';
import { OrderItemEditComponent } from './components/order-item-edit/order-item-edit.component';
import { ColorPickerModule } from 'ngx-color-picker';


export const createTranslateLoader = (http: HttpClient) =>
  new TranslateHttpLoader(http, './assets/i18n/', '.json');

let providers: any = [];
//added custom services
providers.push(...Object.keys(services).map(key => services[key]));

//added api services
providers.push(...Object.keys(api).map(key => api[key]));

providers.push({ provide: "windowObject", useValue: window });

@NgModule({
  declarations: [
    AppComponent,
    OrderItemEditComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    RouterModule.forRoot([
      { path: '**', redirectTo: '/' },
    ]),
    LayoutAuditorModule,
    LayoutAuthModule,
    LayoutClinicModule,
    LayoutTechincianModule,
    LayoutAdministratorModule,
    LayoutTaskmasterModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    HttpClientModule,
    ColorPickerModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    CommonModule,
    BsDatepickerModule.forRoot(),
    TabsModule.forRoot()
  ],
  bootstrap: [AppComponent],
  providers: [providers],
  exports: [
    ColorPickerModule
  ]
})
export class AppModule { }
