import { Injectable } from "@angular/core";
//import { BasApiService } from "../../api";
import { Observable } from "rxjs";
import { BasApiService } from '../base.api.service';

@Injectable()
export class PersonCustomApiService {
  apiKey = "api/person";
  constructor(public baseService: BasApiService) {


  }

  uploadUserFile = (form: FormData): Observable<void> => {
    return this.baseService.post<void>(`api/Person/uploaduserfile`, form, {}, true, true);
  }
}
