import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-input-autocomplete',
  templateUrl: './input-autocomplete.component.html',
  styleUrls: ['./input-autocomplete.component.less']
})
export class InputAutocompleteComponent implements OnInit {
  @Input() values: string[] = [];
  @Output() serachTextChanged = new EventEmitter<string>();
  @Output() promptClick = new EventEmitter<string>();


  suggestions: string[] = [];
  serachText = '';
  searchChanged = false;

  constructor() { }

  ngOnInit() {
  }

  onInputChange(event: any) {
    this.searchChanged = true;
    let val = event.target.value.toLowerCase();

    this.serachText = val;
    this.serachTextChanged.emit(this.serachText);

    if (val.length == 0) {
      this.suggestions = [];
      return;
    }

    this.suggestions = this.values
      .filter(c => c.toLowerCase().startsWith(val))
      .slice(0, 5);
  }

  onItemClick = (item: any) => {
    this.serachText = item;
    this.searchChanged = false;
    //this.serachTextChanged.emit(this.serachText);
    this.promptClick.emit(this.serachText);
  }

}

