import { Component, OnInit } from '@angular/core';
import { JobApiService, PersonApiService, DictionaryApiService, CurrencyApiService } from '../../../../api/index';
import { FilteredGridRequestViewModel, BaseViewGridModel, GridRequestModel, PersonViewFilterModels, CurrencyViewModel, PersonType, BaseDropDownViewModel, PersonTypeViewModel, Currency, CurrencyViewFilterModels, PersonViewModel, Person, PersonModel } from '../../../../models/index';
import { HashTable, fullDoctorName, fullPersonName } from '../../../../Helper/index';
import { retry } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NotificationService } from '../../../services';
import { DatePipe } from '@angular/common';
import { IGridOptions, ControlType } from '../../../components/grid/grid.component';


@Component({
  selector: 'currency',
  templateUrl: './currency.component.html',
  providers: [DatePipe]
})
export class CurrencyComponent implements OnInit {

  gridData: BaseViewGridModel<CurrencyViewModel>;
  fieldOptions: HashTable<IGridOptions>;
  gridRequestModel: FilteredGridRequestViewModel<CurrencyViewFilterModels>;
  showDialog: boolean;
  showDeleteDialog: boolean;
  selectedModel: CurrencyViewModel;
  selectedCurrency: Currency;
  doctors: Person[];
  currentDoctor: string;

  constructor(
    private service: CurrencyApiService,
    private notify: NotificationService,
    private datePipe: DatePipe,
    private personApiService: PersonApiService
  ) {
    this.gridData = {
      viewModelList: []
    } as BaseViewGridModel<CurrencyViewModel>;

    this.gridRequestModel = {} as FilteredGridRequestViewModel<CurrencyViewFilterModels>;

    this.fieldOptions = {
      name: {
        title: 'Наименование'
      },
      exchange: {
        title: 'Курс'
      },
      generate: {
        fieldType: {
          type: ControlType.button,
          defaultText: 'Удалить',
          class: 'btn btn-danger',
          click: (item: CurrencyViewModel) => this.deleteButtonClick(item)
        },
        order: false
      }
    };
    this.showDeleteDialog = false;
    this.showDialog = false;
    this.selectedModel = {} as CurrencyViewModel;
  }

  ngOnInit() {

    this.initGrid();
  }

  initGrid = () => {
    this.service.getAll(this.gridRequestModel).subscribe((data) => {
      this.gridData = { ...data };
    });
  }

  transformDate = (date): string =>
    this.datePipe.transform(date, 'dd.MM.yyyy HH:mm');

  gridRequestEvent = (model: GridRequestModel) => {
    this.gridRequestModel = model as FilteredGridRequestViewModel<CurrencyViewFilterModels>;
    this.initGrid();
  }

  addItem = (): void => {
    this.selectedModel = {} as CurrencyViewModel;
    this.showDialog = true;
  }

  rowClickEvent = (model: CurrencyViewModel) => {
    this.selectedModel = { ...model };
    this.showDialog = true;

  }

  closeDialog = ($event) => this.showDialog = false;
  closeDeleteDialog = ($event) => this.showDeleteDialog = false;

  saveDialog = (): void => {
    this.service.save(this.selectedModel).subscribe(_ => {
      this.notify.showInfo("Запись сохранена успешно");
      this.showDialog = false;
      this.initGrid();
    });
  }

  deleteButtonClick = (model: CurrencyViewModel) => {
    this.showDeleteDialog = true;
    this.selectedModel = { ...model };
  }

  approveDelete = (model: CurrencyViewModel) => {
    console.log(model);
    if (!this.selectedModel) {
      this.showDeleteDialog = false;
      return;
    }

    this.service.delete(this.selectedModel.id).subscribe(_ => {
      this.notify.showInfo("Запись удалена!");
      this.showDeleteDialog = false;
      this.initGrid();
    });
  }
}
