import { Component, OnInit } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { DictionaryApiService, PersonApiService, ProtezPriceApiService } from '../../../../api';
import { JobApiService } from '../../../../api/generated/job.api.service';
import { HashTable, Helper } from '../../../../Helper';
import { BaseDropDownViewModel, Color, OrderModel, Person, Protez, RegionDictionaryViewModel } from '../../../../models';
import { ExtJobView } from '../../../../models/custom/ExtJobView';
import { OrderResultModel } from '../../../../models/custom/OrderResultModel';
import { NotificationService } from '../../../services';
import { commonSetting } from '../../../services/common.settings';

@Component({
  selector: 'app-order-diary',
  templateUrl: './order-diary.component.html',
  styleUrls: ['./order-diary.component.less']
})
export class OrderDiaryComponent implements OnInit {
  selectedDate = new Date();
  hourList = [];
  orders = [];
  selectedColor: Color;
  selectedWork: BaseDropDownViewModel<number>;
  orderResult: OrderResultModel;
  colorsList: Color[];
  workList: BaseDropDownViewModel<number>[];
  selectedClinkDialogItem: RegionDictionaryViewModel;
  clinicListDialog: RegionDictionaryViewModel[];
  clinicList: RegionDictionaryViewModel[];
  showDialog: boolean;
  protezTypes: Protez[];
  selectedClinkItem: RegionDictionaryViewModel;
  selectedDoctorItem: Person;
  doctorsList: Person[];
  currentPriceListCurrency: number;
  helper: Helper;
  protezPricerList: HashTable<number>;
  selectedModel: OrderModel;
  protezType: Protez;

  hourListOrder = [];
  minutesList = [];
  selectedDueHour = 0;
  selectedDueMinute = 0;
  themeName = commonSetting.datePickerName;

  constructor(private jobService: JobApiService,
    private dictionaryApiService: DictionaryApiService,
    private personApiService: PersonApiService,
    private protezPriceApiService: ProtezPriceApiService,
    private notify: NotificationService) {
    this.helper = new Helper();
    this.selectedModel = {} as OrderModel;
  }

  ngOnInit() {
    //this.initHours();
    this.getOrders();

    forkJoin(
      this.dictionaryApiService.getClinicList(),
      this.jobService.getProtezes(),
      this.dictionaryApiService.getColors(),
      this.dictionaryApiService.getWorks()
    ).subscribe(pair => {
      let clinics = pair[0] as RegionDictionaryViewModel[];
      this.clinicList = [] as RegionDictionaryViewModel[];
      this.clinicList.push({ id: 0, name: "Все", currencyId: -1, currencyName: '' });
      this.clinicList.push(...clinics);
      this.selectedClinkItem = this.clinicList[0] || {} as RegionDictionaryViewModel;
      this.clinicListDialog = [] as RegionDictionaryViewModel[];
      this.clinicListDialog.push({
        id: 0, name: "Выберите клинику", currencyId
          : -1, currencyName: ''
      });
      this.clinicListDialog.push(...clinics);

      let protezTyeps = pair[1] as Protez[];
      this.protezTypes = [];
      this.protezTypes.push(...protezTyeps)

      let colors = pair[2] as Color[];
      this.colorsList = [];
      this.colorsList.push({ id: null, key: "", colorCode: "" });
      this.colorsList.push(...colors);

      let works = pair[3] as BaseDropDownViewModel<number>[];
      this.workList = [];
      this.workList.push(...works);
    });


    for (let i = 8; i <= 20; i++) {
      this.hourListOrder.push({
        value: i,
        name: i
      });
    }

    this.minutesList.push({ value: 0, name: 0 });
    this.minutesList.push({ value: 15, name: 15 });
    this.minutesList.push({ value: 30, name: 30 });
    this.minutesList.push({ value: 45, name: 45 });
  }

  initHours() {
    this.hourList = [];
    for (let i = 8; i <= 20; i++) {
      this.hourList.push(
        {
          hour: i,
          orders: []
        });
    }
  }

  selectedDateChanged($event) {
    this.selectedDate = new Date($event);
    this.getOrders();
  }

  getOrders() {
    this.initHours();

    this.jobService.getOrderByDate(this.selectedDate.toISOString()).subscribe(
      s => {
        this.orders = [...s];

        let dtOffset = new Date().getTimezoneOffset() / -60;

        this.hourList.forEach(element => {
          element.orders = [...this.orders.filter(o => (o.orderHour + dtOffset) == element.hour)];
        });

        console.log(this.hourList);
      }
    );
  }

  initModelAndShow = (model: ExtJobView): void => {
    this.selectedColor = this.colorsList.find(s => s.key == model.colorKey) || {} as Color;
    this.selectedWork = this.workList.find(s => s.id == model.workId) || this.workList[0] || {} as BaseDropDownViewModel<number>;

    this.orderResult = {
      job: { ...model },
      removablePreotezes: model.protezes ? model.protezes.filter(s => s.protezTypeId == 1) : [],
      fixedPreotezes: model.protezes ? model.protezes.filter(s => s.protezTypeId == 2) : [],
      technicanComment: model.technicanComment,
      doctorComment: model.doctorComment,
      comments: model.comments,
      colorId: this.selectedColor.id,
      workId: this.selectedWork.id,
      price: model.price,
      compulsory: model.compulsory,
      dueDate: this.helper.convertUtcStringToLocaleDate(model.dueDate),
      patient: model.patient,
      registeredPersonsNames: [],
      fulNumber: model.fulNumber
    } as OrderResultModel;

    
    this.selectedDueHour = this.orderResult.dueDate.getHours();
    this.selectedDueMinute = this.orderResult.dueDate.getMinutes();

    this.selectedClinkDialogItem = this.clinicListDialog.find(s => s.id == model.clinicId) || this.clinicListDialog[0];

    this.OnClinkDialogChange(this.selectedClinkDialogItem, () => {
      this.selectedDoctorItem = this.doctorsList.find(s => s.id == model.doctorId) || this.doctorsList[0];
    });

    this.jobService.getByJobId(model.jobId).subscribe((model) => this.selectedModel = model);
    this.personApiService.getRegisteredPersosnsByJobId(model.jobId).subscribe((result) => {
      this.orderResult.registeredPersonsNames = result;
    }, _ => _, () => this.showDialog = true);

  }

  OnClinkDialogChange = ($event: RegionDictionaryViewModel, calback?: () => void) => {
    this.selectedClinkDialogItem = $event;
    this.updatePriceList(() => {
      if (this.selectedClinkDialogItem.id != this.orderResult.job.clinicId)
        this.calcPrice();
    }, () => {
      this.orderResult.job.clinicId = this.selectedClinkDialogItem.id;
      this.orderResult.job.currencyId = this.selectedClinkDialogItem.currencyId;
      this.orderResult.job.currencyName = this.selectedClinkDialogItem.currencyName;

      this.personApiService.getPersonsByClinikId(this.selectedClinkDialogItem.id).subscribe((date) => {
        this.doctorsList = [];
        this.doctorsList.push({ id: null, lastName: "" } as Person);
        this.doctorsList.push(...date);
        if (calback)
          calback();
      });
    });
  }

  updatePriceList = (done?: () => void, complite?: () => void): void => {

    let tmpValue = this.selectedClinkDialogItem.currencyId || this.orderResult.job.currencyId;
    if (this.currentPriceListCurrency) {
      if (tmpValue == this.currentPriceListCurrency) {
        if (done) {
          done();
        }
        complite();
        return;
      }
    }

    this.currentPriceListCurrency = tmpValue;

    this.protezPriceApiService.getByCurrencyId(this.currentPriceListCurrency).subscribe(s => {
      this.protezPricerList = {};
      s.forEach(s => {
        this.protezPricerList[s.protezId] = s.price
      });
      if (done)
        done();

      complite();
    });
  }

  calcPrice = (): void => {
    let price = 0;
    this.orderResult.fixedPreotezes.forEach(s => price += this.helper.valueFromObject(this.protezPricerList, s.protezId, 0));
    this.orderResult.removablePreotezes.forEach(s => price += this.helper.valueFromObject(this.protezPricerList, s.protezId, 0));
    this.orderResult.price = price;
  }

  closeDialog = ($event) => this.showDialog = false;

  saveDialog = (): void => {
    let orderModel: OrderModel = {
      technicanComment: this.orderResult.technicanComment,
      doctorComment: this.orderResult.doctorComment,
      comments: this.orderResult.comments,
      doctorId: this.orderResult.job.doctorId,
      dueDate: this.orderResult.dueDate,
      id: this.orderResult.job.jobId,
      protezes: this.orderResult.fixedPreotezes.concat(this.orderResult.removablePreotezes),
      regionId: this.orderResult.job.clinicId,
      colorId: this.orderResult.colorId,
      compulsory: this.orderResult.compulsory,
      workId: this.orderResult.workId,
      price: this.orderResult.price,
      patient: this.orderResult.patient,
      isSent: this.orderResult.isSent
    };
    this.jobService.createOrder(orderModel).subscribe(_ => {
      this.notify.showInfo("Заказ создан успешно");
      this.showDialog = false;
      this.getOrders();
    });
  }

  hourChanged(val) {
    this.orderResult.dueDate.setHours(val);
  }

  minutesChanged(val) {
    this.orderResult.dueDate.setMinutes(val);
  }

  onTypeChange = ($event: Protez): void => {
    this.protezType = $event;
  }
}
