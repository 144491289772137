﻿//------------------------------------------------------------------------------
//  This code was generated by typescript template tool 'Typewriter'.
//
//  Changes to this file may cause incorrect behavior and will be lost if
//  the code is regenerated.
//------------------------------------------------------------------------------

import { GridRequestModel } from "./Dental-WebCore-Models-GridRequestModel";
import { SearchFilter } from "./Dental-WebCore-Models-SearchFilter";

export class FilteredGridRequestViewModel<T> extends GridRequestModel  {
    
    selectedItems: T[];
    filter: SearchFilter<T>;
}




  
