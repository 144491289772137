﻿//------------------------------------------------------------------------------
//  This code was generated by typescript template tool 'Typewriter'.
//
//  Changes to this file may cause incorrect behavior and will be lost if
//  the code is regenerated.
//------------------------------------------------------------------------------


export * from "./auth.api.service";
export * from "./commonsettings.api.service";
export * from "./currency.api.service";
export * from "./dictionary.api.service";
export * from "./job.api.service";
export * from "./jobstate.api.service";
export * from "./jobtimer.api.service";
export * from "./person.api.service";
export * from "./position.api.service";
export * from "./protez.api.service";
export * from "./protezprice.api.service";
export * from "./region.api.service";
export * from "./regionprotez.api.service";
export * from "./salarybook.api.service";
export * from "./salary.api.service";
export * from "./values.api.service";
export * from "./work.api.service";
export * from "./worktype.api.service";
