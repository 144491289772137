import { Component, OnInit } from '@angular/core';
import { JobApiService, PersonApiService, DictionaryApiService, CurrencyApiService, RegionApiService } from '../../../../api/index';
import { FilteredGridRequestViewModel, BaseViewGridModel, JobView, GridRequestModel, JobViewModel, JobViewFilterModels, PersonView, PersonViewFilterModels, RegionViewModel, PersonType, BaseDropDownViewModel, PersonTypeViewModel, Currency, RegionViewFilterModels, PersonViewModel, Person, PersonModel, CurrencyViewModel } from '../../../../models/index';
import { HashTable, fullDoctorName, fullPersonName } from '../../../../Helper/index';
import { retry } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NotificationService } from '../../../services';
import { DatePipe } from '@angular/common';
import { IGridOptions, ControlType } from '../../../components/grid/grid.component';


@Component({
  selector: 'regions',
  templateUrl: './regions.component.html',
  providers: [DatePipe]
})
export class RegionsComponent implements OnInit {

  regions: BaseViewGridModel<RegionViewModel>;
  fieldOptions: HashTable<IGridOptions>;
  gridRequestModel: FilteredGridRequestViewModel<RegionViewFilterModels>;
  showDialog: boolean;
  currencyList: CurrencyViewModel[];
  selectedModel: RegionViewModel;
  selectedCurrency: Currency;
  doctors: Person[];
  currentDoctor: string;

  constructor(
    private service: RegionApiService,
    private notify: NotificationService,
    private datePipe: DatePipe,
    private dictionaryApiService: DictionaryApiService,
    private personApiService: PersonApiService
  ) {
    this.regions = {
      viewModelList: []
    } as BaseViewGridModel<RegionViewModel>;

    this.fieldOptions = {
      prefix: {
        title: 'Префикс'
      },
      email: {
        title: 'Email'
      },
      address: {
        title: 'Аддресс'
      },
/*       phone: {
        title: 'Телефон'
      }, */
      advanced: {
        title: 'Дополнитльно'
      },
      name: {
        title: 'Название'
      },
      baseCurrency: {
        title: 'Валюта',
        fieldType: {
          type: ControlType.customValue,
          value: (item: RegionViewModel) => {
            let currency = this.currencyList.filter(s => s.id == item.currencyId)[0];
            if (currency)
              return currency.name;

            return null;
          }
        }
      },
    };

    this.selectedCurrency = {} as Currency;
    this.gridRequestModel = {} as FilteredGridRequestViewModel<RegionViewFilterModels>;

    this.showDialog = false;
    this.selectedModel = {} as RegionViewModel;


  }

  ngOnInit() {

    this.dictionaryApiService.getCurrencies().subscribe(currencies => {
      this.currencyList = [];
      this.currencyList.push(...currencies);
    });

    this.initGrid();
  }

  initGrid = () => {
    this.service.getAll(this.gridRequestModel).subscribe((data) => {
      this.regions = data;
    });
  }

  transformDate = (date): string =>
    this.datePipe.transform(date, 'dd.MM.yyyy HH:mm');

  gridRequestEvent = (model: GridRequestModel) => {
    this.gridRequestModel = model as FilteredGridRequestViewModel<JobViewFilterModels>;
    this.initGrid();
  }

  addUser = (): void => {
    this.selectedModel = {} as RegionViewModel;
    this.showDialog = true;
  }

  rowClickEvent = (model: RegionViewModel) => {
    this.selectedModel = { ...model };
    this.selectedCurrency = this.currencyList.filter(s => s.id == this.selectedModel.currencyId)[0] || {} as Currency;
    this.personApiService.getPersonsByClinikId(this.selectedModel.id).subscribe(data => {
      this.doctors = [];
      this.doctors.push(...data);
      this.showDialog = true;
    });

  }

  closeDialog = ($event) => this.showDialog = false;

  OnCurrencyChange = ($event: Currency) =>
    this.selectedCurrency = $event;


  saveDialog = (): void => {
    this.selectedModel.currencyId = this.selectedCurrency.id;
    this.service.save(this.selectedModel).subscribe(_ => {
      this.notify.showInfo("Запись сохранена успешно");
      this.showDialog = false;
      this.initGrid();
    });
  }

  addDoctor = (): void => {
    this.personApiService.savePerson({
      lastName: this.currentDoctor,
      personTypeId: 5,
      regionId: this.selectedModel.id
    } as PersonModel).subscribe(_ => {
      this.personApiService.getPersonsByClinikId(this.selectedModel.id).subscribe(values => {
        this.doctors = [];
        this.doctors.push(...values);
      });
    });
  }
}
