import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Protez, UserAuth, PersonViewModel, Person } from '../../../../models';
import { JobApiService } from '../../../../api';
import { HashTable } from '../../../../Helper';
import { forEach } from '@angular/router/src/utils/collection';
import { AuthService } from '../../../services';
import { Observable } from 'rxjs';


@Component({
  selector: 'tooth-type-selector',
  templateUrl: './tooth-type-selector.component.html'
})
export class ToothTypeSelectorComponent implements OnInit {

  @Output() typeChange: EventEmitter<Protez> = new EventEmitter<Protez>();

  protezTypes: Protez[];
  defaultItem: Protez = { id: 0 } as Protez;
  selectorList: HashTable<Protez>;
  currentItem: Protez;
  curentIndex: number;
  itemsCount: number = 5;
  constructor(
    private service: JobApiService,
    private authService: AuthService
  ) {
    this.curentIndex = 0;
    this.protezTypes = [];
    this.selectorList = {};
    for (let i = 0; i < this.itemsCount; i++) {
      this.selectorList[i] = this.defaultItem;
    }
  }


  ngOnInit() {
    this.service.getProtezes().subscribe(data => {
      this.protezTypes = data.filter(s => s.protezTypeId == 2);
      for (let i = 0; i < this.itemsCount; i++) {
        this.selectorList[i] = this.protezTypes[0] || this.defaultItem;
      }
      let userSettings = this.authService.getUserSettings<Protez>();       
      this.selectorList = Object.keys(userSettings).length == 0 ? this.selectorList : userSettings;
      this.currentItem = this.selectorList[this.curentIndex]; 
      if (!userSettings) {
        this.authService.saveUserSettings(this.selectorList).subscribe(_ => _);
      }
      this.typeChange.emit(this.currentItem);
    });
  }

  getIndexes = (): string[] => Object.keys(this.selectorList || {});

  onIndexChange = (index: number): void => {
    this.curentIndex = index;
    this.currentItem = this.selectorList[this.curentIndex];
    this.typeChange.emit(this.currentItem);
  }

  onTypeSelect = ($event: Protez, index: number) => {
    this.selectorList[index] = $event;
    this.authService.saveUserSettings(this.selectorList).subscribe(_ => {
      if (index == this.curentIndex) {
        this.currentItem = this.selectorList[this.curentIndex];
        this.typeChange.emit(this.currentItem);
      }
    });
  }
}
