﻿//------------------------------------------------------------------------------
//  This code was generated by typescript template tool 'Typewriter'.
//
//  Changes to this file may cause incorrect behavior and will be lost if
//  the code is regenerated.
//------------------------------------------------------------------------------

import { ProtezView } from "./Dental-DataModel-Models-View-ProtezView";

export class OrderModel   {
    
    id?: number;
    colorId?: number;
    compulsory: boolean;
    doctorId: number;
    regionId: number;
    technicanComment: string;
    doctorComment: string;
    dueDate: any;
    price: number;
    patient: string;
    protezes: ProtezView[];
    workId: number;
    jobStateId?: number;
    isSent: boolean;
    comments: string;
}




  
