import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";

@Injectable()
export class TranslationService {
  constructor(private translateService: TranslateService) { }

  public translate = (message: string): string => {
    if (message) {
      let res = this.translateService.instant(message);
      return res ? res : message;
    }
    return "";
  }
}
