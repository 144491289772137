import { Component, Input, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { SalaryViewModel } from '../../../models/index';

@Component({
  selector: 'app-archive-salary-person',
  templateUrl: './archive-salary-person.component.html',
  styleUrls: ['./archive-salary-person.component.less']
})
export class ArchiveSalaryPersonComponent implements OnInit {
  @Input("data") selectedModel: SalaryViewModel;
  @Input() showSalaryDetails: boolean = true;

  showDialog2: boolean = false;
  showDialogDetails: boolean = false;
  currentItem: any;

  constructor(
    private datePipe: DatePipe) { }

  ngOnInit() {
  }

  transformDate = (date): string =>
    this.datePipe.transform(date, 'dd.MM.yyyy HH:mm');

  getProtezCount = (item: any) => {
    let protezCount = 0;

    if (item.salaryJobProtezList) {
      item.salaryJobProtezList.forEach(element => {
        protezCount = protezCount + element.cnt;
      });
    }

    return protezCount;
  }

  getSeleryWorkSum = (item: any) => {
    let sum = 0;

    if (item.salaryJobProtezList) {
      item.salaryJobProtezList.forEach(element => {
        sum = sum + element.cnt * element.price;
      });
    }

    return sum;
  }

  salryRowClick = (item: any) => {
    this.showDialog2 = true;
    this.currentItem = { ...item };
    console.log(this.currentItem);
  }

  closeDialog2 = () => {
    this.currentItem = {};
    this.showDialog2 = false;
  }

  showSalaryDetailsClick = () => {
    this.showDialogDetails = true;
  }

  closSalaryDetailseDialog = () => {
    this.showDialogDetails = false;
  }

}
