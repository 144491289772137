﻿//------------------------------------------------------------------------------
//  This code was generated by typescript template tool 'Typewriter'.
//
//  Changes to this file may cause incorrect behavior and will be lost if
//  the code is regenerated.
//------------------------------------------------------------------------------

import { UserAuth } from "./Dental-BusinesLayer-Models-UserAuth";
import { RoleType } from "./Dental-DataModel-CustomTypes-RoleType";

export class RegisterUser extends UserAuth  {
    
    role: RoleType;
}




  
