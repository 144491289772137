import { Component, OnInit } from '@angular/core';
import { SalaryApiService } from '../../../../api/index';
import { FilteredGridRequestViewModel, BaseViewGridModel, GridRequestModel, SalaryViewModel, SalaryViewFilterModels, JobViewModel, RoleType, SalaryJobViewModel } from '../../../../models/index';
import { HashTable, fullDoctorName, fullPersonName, fullName } from '../../../../Helper/index';
import { retry } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NotificationService } from '../../../services';
import { DatePipe } from '@angular/common';
import { IGridOptions, ControlType } from '../../../components/grid/grid.component';
import { commonSetting } from '../../../services/common.settings';


@Component({
  selector: 'salary',
  templateUrl: './salary.component.html',
  styleUrls: ['./salary.component.less'],
  providers: [DatePipe]
})
export class SalaryComponent implements OnInit {

  salary: BaseViewGridModel<SalaryViewModel>;
  selectedModel: SalaryViewModel;
  fieldOptions: HashTable<IGridOptions>;

  gridRequestModel: FilteredGridRequestViewModel<SalaryViewFilterModels>;
  showDialog: boolean;
  showAccurateDialog: boolean;
  personTypesList = RoleType;
  themeName = commonSetting.datePickerName;

  private readonly notConfirmedRecord: string = 'Пользователь не подтвердил эту зп!';
  private readonly newSalaryRecord: string = 'Новая зп!';
  private readonly salaryPayErrorRecord: string = 'Пользователь не подтвердил получение зп! Нельзя выдавать новую!';

  constructor(
    private service: SalaryApiService,
    private notify: NotificationService,
    private datePipe: DatePipe
  ) {

    this.selectedModel = {
      salaryWorks: {
        viewModelList: []
      } as BaseViewGridModel<SalaryJobViewModel>
    } as SalaryViewModel;

    this.fieldOptions = {
      workType: {
        title: 'Цех'
      },
      fullName: {
        title: 'ФИО',
        fieldType: {
          value: (item: SalaryViewModel) => fullName(item.lastName, item.firstName, item.middleName),
          type: ControlType.customValue
        }
      },
      value: {
        title: 'ЗП, грн'
      },
      isAccrued: {
        title: 'Выдано',
        hide: true,
        color: 'custom-color'
      },
      canAccrued: {
        title: 'Можно выдать',
        hide: true,
        color: 'custom-color'
      },
      appliedDate: {
        title: 'Сформирован',
        fieldType: {
          value: (item: SalaryViewModel) => (!item.dateCreation).toString(),
          type: ControlType.bool
        },
        color: 'custom-color',
        hide: true
      },
      endDate: {
        title: 'По',
        fieldType: {
          value: (item: SalaryViewModel) => this.transformDate(item.endDate),
          type: ControlType.customValue
        }
      },
      generated: {
        fieldType: {
          defaultText: 'Выдать',
          class: 'btn btn-danger',
          //type: ControlType.button,
          type: ControlType.ifButton,
          settings: {
            showIfButton: (item: SalaryViewModel) => item.canAccrued,
            ifButtonHideText: (item: SalaryViewModel) => this.getSalaryError(item)
          },
          click: (item: SalaryViewModel) => this.accurateSallary(item)
        },
        order: false
      }
    };



    this.gridRequestModel = {
      filter: {
        filterModel: {
          end: new Date()
        }
      }
    } as FilteredGridRequestViewModel<SalaryViewFilterModels>;
    this.salary = {
      viewModelList: []
    } as BaseViewGridModel<SalaryViewModel>;
    this.showDialog = false;
  }

  ngOnInit() {
    this.service.getStartDate().subscribe(s => {
      console.log(s);
      let startDate = new Date(s);
      let endDate = new Date();

      if (endDate < startDate) {
        endDate = new Date(startDate);
      }
      this.gridRequestModel.filter.filterModel.start = startDate;
      this.gridRequestModel.filter.filterModel.end = endDate;
    });
  }

  accurateSallary = (model: SalaryViewModel) => {
    this.showAccurateDialog = true;
    this.selectedModel = model;
  }

  approveAccurate = () => {
    console.log(this.selectedModel);
    if (!this.selectedModel) {
      this.showAccurateDialog = false;
      return;
    }

    this.service.accurateSallary(this.selectedModel.id).subscribe(_ => {
      this.notify.showInfo(`Зарплата выдана ${fullName(this.selectedModel.lastName, this.selectedModel.firstName, this.selectedModel.middleName)}`);
      this.showAccurateDialog = false;
      this.initGrid();
    });
  }

  closeAccurateDialog = ($event) => this.showAccurateDialog = false;

  initGrid = () => {
    this.service.getAll(this.gridRequestModel).subscribe((data) => {
      this.salary = data;
    });
  }

  transformDate = (date): string =>
    this.datePipe.transform(date, 'dd.MM.yyyy HH:mm');

  gridRequestEvent = (model: GridRequestModel) => {
    this.gridRequestModel = { ...model as FilteredGridRequestViewModel<SalaryViewFilterModels>, filter: this.gridRequestModel.filter };
    this.initGrid();
  }


  rowClickEvent = (model: SalaryViewModel) => {
    this.selectedModel = model;
    this.showDialog = true;
    console.log(this.selectedModel);
  }

  onSearchClick = () => {
    this.initGrid();
  }

  onSaveClick = () => {
    this.service.applySallary(this.gridRequestModel).subscribe(() => {
      this.notify.showInfo("Зарплатный лист зафиксирован");
      this.initGrid();
    });
  }

  closeDialog = () => this.showDialog = false;

  caclcSalary = ($event) => {


  }
  fullName = fullName;

  private getSalaryError = (item: SalaryViewModel): string => {
    if (item.accruededAndNotUserConfirmed)
      return this.notConfirmedRecord;

    if (!item.canAccrued) {
      if (item.dateCreation) {
        return this.salaryPayErrorRecord;
      } else {
        return this.newSalaryRecord;
      }
    }

    return '';
  }
}
