import { Component, OnInit } from '@angular/core';
import { MenuService } from '../../../services/menu.service';

@Component({
  selector: 'nav-menu',
  templateUrl: './nav-menu.component.html'
})
export class NavMenuComponent implements OnInit {

  constructor(private menuService: MenuService) { }

  ngOnInit() {
  }

  menuHover = (): void => {
    this.menuService.open();
  }

  closeMenu = (): void => {
    this.menuService.close();
  }
}
