import { Component, OnInit } from '@angular/core';
import { DictionaryApiService, RegionProtezApiService } from '../../../../api/index';
import { FilteredGridRequestViewModel, BaseViewGridModel, GridRequestModel, BaseDropDownViewModel, ProtezType, RegionProtezViewFilterModels, RegionProtezViewModel } from '../../../../models/index';
import { HashTable, fullDoctorName, fullPersonName } from '../../../../Helper/index';
import { retry } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NotificationService } from '../../../services';
import { DatePipe } from '@angular/common';
import { IGridOptions, ControlType } from '../../../components/grid/grid.component';
import { forkJoin } from 'rxjs';


@Component({
  selector: 'region-protez',
  templateUrl: './region-protez.component.html',
  providers: [DatePipe]
})
export class RegionProtezComponent implements OnInit {

  gridData: BaseViewGridModel<RegionProtezViewModel>;
  fieldOptions: HashTable<IGridOptions>;
  gridRequestModel: FilteredGridRequestViewModel<RegionProtezViewFilterModels>;
  showDialog: boolean;
  selectedModel: RegionProtezViewModel;

  protezTypes: BaseDropDownViewModel<number>[];
  filterPprotezTypes: BaseDropDownViewModel<number>[];
  selectedProtezType: BaseDropDownViewModel<number>;
  selectedProtezFilterItem: BaseDropDownViewModel<number>;

  currencyTypes: BaseDropDownViewModel<number>[];
  filterCurrencyTypes: BaseDropDownViewModel<number>[];
  selectedCurrencyType: BaseDropDownViewModel<number>;
  selectedFilterCurrencyTypes: BaseDropDownViewModel<number>;

  clinickList: BaseDropDownViewModel<number>[];
  filterClinickList: BaseDropDownViewModel<number>[];
  selectedClinick: BaseDropDownViewModel<number>;
  selectedFilteredClinick: BaseDropDownViewModel<number>;

  showDeleteDialog: boolean;


  constructor(
    private service: RegionProtezApiService,
    private notify: NotificationService,
    private datePipe: DatePipe,
    private dictionaryService: DictionaryApiService
  ) {
    this.gridData = {
      viewModelList: []
    } as BaseViewGridModel<RegionProtezViewModel>;

    this.gridRequestModel = {
      filter: {
        filterModel: {}
      }
    } as FilteredGridRequestViewModel<RegionProtezViewFilterModels>;

    this.selectedProtezFilterItem = {} as BaseDropDownViewModel<number>;
    this.selectedProtezType = {} as BaseDropDownViewModel<number>;
    this.selectedClinick = {} as BaseDropDownViewModel<number>;
    this.selectedFilteredClinick = {} as BaseDropDownViewModel<number>;
    this.selectedCurrencyType = {} as BaseDropDownViewModel<number>;
    this.selectedFilterCurrencyTypes = {} as BaseDropDownViewModel<number>;

    this.showDialog = false;
    this.selectedModel = {} as RegionProtezViewModel;
    this.protezTypes = [];
    this.filterPprotezTypes = [];
    this.currencyTypes = [];
    this.filterClinickList = [];
    this.filterCurrencyTypes = [];
    this.clinickList = [];

    this.fieldOptions = {
      regionName: {
        title: 'Регион',
        order: false
      },
      protezName: {
        title: 'Протез',
        order: false
      },
      originalPrice: {
        title: 'Оригинальная цена',
        order: false
      },
      price: {
        title: 'Индивидуальная цена',
        order: false
      },
      currencyName: {
        title: 'Валюта',
        order: false
      },
      delete: {
        fieldType: {
          type: ControlType.button,
          defaultText: 'Удалить',
          class: 'btn btn-danger',
          click: (item: RegionProtezViewModel) => this.deleteButtonClick(item)
        },
        order: false
      }
    };
  }

  ngOnInit() {
    forkJoin(
      this.dictionaryService.getProtezes(),
      this.dictionaryService.getCurrencyTypes(),
      this.dictionaryService.getClinicList(),
    ).subscribe((data) => {
      this.protezTypes = [];
      this.protezTypes.push(...data[0]);

      this.filterPprotezTypes = [];
      this.filterPprotezTypes.push({ id: 0, name: "Все" });
      this.filterPprotezTypes.push(...data[0]);

      this.currencyTypes = [];
      this.currencyTypes.push(...data[1]);

      this.filterCurrencyTypes = [];
      this.filterCurrencyTypes.push({ id: 0, name: "Все" });
      this.filterCurrencyTypes.push(...data[1]);

      this.clinickList = [];
      this.clinickList.push(...data[2]);

      this.filterClinickList = [];
      this.filterClinickList.push({ id: 0, name: "Все" });
      this.filterClinickList.push(...data[2]);

    });
    this.initGrid();
  }

  initGrid = () => {
    this.service.getAll(this.gridRequestModel).subscribe((data) => {
      this.gridData = { ...data };
    });
  }

  transformDate = (date): string =>
    this.datePipe.transform(date, 'dd.MM.yyyy HH:mm');

  gridRequestEvent = (model: GridRequestModel) => {
    this.gridRequestModel = { ...model as FilteredGridRequestViewModel<RegionProtezViewFilterModels>, filter: this.gridRequestModel.filter };
    this.initGrid();
  }

  rowClickEvent = (model: RegionProtezViewModel) => {
    this.selectedModel = { ...model };
    this.selectedProtezType = { ... this.protezTypes.filter(s => s.id == this.selectedModel.protezId)[0] } || {} as BaseDropDownViewModel<number>;
    this.selectedCurrencyType = { ... this.currencyTypes.filter(s => s.id == this.selectedModel.currencyId)[0] } || {} as BaseDropDownViewModel<number>;
    this.selectedClinick = { ... this.clinickList.filter(s => s.id == this.selectedModel.regionId)[0] } || {} as BaseDropDownViewModel<number>;

    this.showDialog = true;

  }

  addItem = (): void => {
    this.selectedModel = {} as RegionProtezViewModel;
    this.selectedProtezType = {} as BaseDropDownViewModel<number>;
    this.selectedCurrencyType = {} as BaseDropDownViewModel<number>;
    this.selectedClinick = {} as BaseDropDownViewModel<number>;
    this.showDialog = true;
  }

  closeDialog = ($event) => this.showDialog = false;

  saveDialog = (): void => {
    this.selectedModel.currencyId = this.selectedCurrencyType.id;
    this.selectedModel.protezId = this.selectedProtezType.id;
    this.selectedModel.regionId = this.selectedClinick.id;
    this.service.save(this.selectedModel).subscribe(_ => {
      this.notify.showInfo("Запись сохранена успешно");
      this.showDialog = false;
      this.initGrid();
    });
  }


  deleteButtonClick = (model: RegionProtezViewModel) => {
    this.showDeleteDialog = true;
    this.selectedModel = { ...model };
  }

  approveDelete = (model: RegionProtezViewModel) => {
    if (!this.selectedModel) {
      this.showDeleteDialog = false;
      return;
    }

    this.service.delete(this.selectedModel.regionId, this.selectedModel.protezId, this.selectedModel.currencyId).subscribe(_ => {
      this.notify.showInfo("Запись удалена!");
      this.showDeleteDialog = false;
      this.initGrid();
    });
  }


  OnProtezTypeChange = ($event: BaseDropDownViewModel<number>) =>
    this.selectedProtezType = $event;

  OnFilterProtezTypeChange = ($event: BaseDropDownViewModel<number>) =>
    this.selectedProtezFilterItem = $event;

  OnCurrencyTypeChange = ($event: BaseDropDownViewModel<number>) =>
    this.selectedCurrencyType = $event;

  OnFitlerCurrencyTypeChange = ($event: BaseDropDownViewModel<number>) =>
    this.selectedFilterCurrencyTypes = $event;

  OnClientListChange = ($event: BaseDropDownViewModel<number>) =>
    this.selectedClinick = $event;

  OnFitlerClientListChange = ($event: BaseDropDownViewModel<number>) =>
    this.selectedFilteredClinick = $event;

  closeDeleteDialog = () => this.showDeleteDialog = false;

  onSearchClick = () => {
    this.gridRequestModel.filter.filterModel.protezTypeId = this.selectedProtezFilterItem.id == 0 ? null : this.selectedProtezFilterItem.id;
    this.gridRequestModel.filter.filterModel.clinickId = this.selectedFilteredClinick.id == 0 ? null : this.selectedFilteredClinick.id;
    this.gridRequestModel.filter.filterModel.currencyId = this.selectedFilterCurrencyTypes.id == 0 ? null : this.selectedFilterCurrencyTypes.id;
    this.initGrid();
  }

}
