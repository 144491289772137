import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArchiveSalaryPersonComponent } from './archive-salary-person.component';
import { ModalWindownModule } from '../modal-window/modal-window.module';

@NgModule({
  imports: [
    CommonModule, ModalWindownModule
  ],
  declarations: [ArchiveSalaryPersonComponent],
  exports: [ArchiveSalaryPersonComponent]
})
export class ArchiveSalaryPersonModule { }
