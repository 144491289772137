﻿//------------------------------------------------------------------------------
//  This code was generated by typescript template tool 'Typewriter'.
//
//  Changes to this file may cause incorrect behavior and will be lost if
//  the code is regenerated.
//------------------------------------------------------------------------------



export class JobViewFilterModels   {
    
    jobId?: number;
    clinicId?: number;
    dueDateFrom: any;
    dueDateTo: any;
    personId?: number;
    compulsory: boolean;
    archived: boolean;
    jobNumber: string;
}




  
