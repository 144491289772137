﻿//------------------------------------------------------------------------------
//  This code was generated by typescript template tool 'Typewriter'.
//
//  Changes to this file may cause incorrect behavior and will be lost if
//  the code is regenerated.
//------------------------------------------------------------------------------


import { Injectable } from "@angular/core";
import { ResponseContentType, Response } from "@angular/http";
import { Observable } from 'rxjs';
import { BasApiService } from '../base.api.service';

import * as ApiTypes from "../../models";

@Injectable()
export class AuthApiService {
  
  constructor(public baseService: BasApiService) {
   
  }
  
  login = (userData: ApiTypes.UserAuth): Observable<void> =>
    this.baseService.post<void>(`api/Auth/login`, userData, {}, true, true);   
  
  logOut = (): Observable<void> =>
    this.baseService.get<void>(`api/Auth/logout`, {}, true, true);   
  
  }

