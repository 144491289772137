﻿//------------------------------------------------------------------------------
//  This code was generated by typescript template tool 'Typewriter'.
//
//  Changes to this file may cause incorrect behavior and will be lost if
//  the code is regenerated.
//------------------------------------------------------------------------------

import { IModel } from "./Dental-DataModel-Interfaces-IModel";
import { Protez } from "./Dental-DataModel-Models-Protez";
import { WorkType } from "./Dental-DataModel-Models-WorkType";
import { Position } from "./Dental-DataModel-Models-Position";
import { JobState } from "./Dental-DataModel-Models-JobState";

export class JobTimer  implements IModel {
    
    id: number;
    protezID: number;
    protez: Protez;
    workTypeID: number;
    workType: WorkType;
    positionID: number;
    position: Position;
    jobStateID: number;
    jobState: JobState;
    timeHour: number;
}




  
