import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'price',
  templateUrl: './price.component.html'
})
export class PriceComponent{
  
  messages: string[];
  constructor() {
    this.messages = [];
  }

  doMessage = (text: string) => {
    this.messages.push(text);
   }
}
