﻿//------------------------------------------------------------------------------
//  This code was generated by typescript template tool 'Typewriter'.
//
//  Changes to this file may cause incorrect behavior and will be lost if
//  the code is regenerated.
//------------------------------------------------------------------------------

import { IModel } from "./Dental-DataModel-Interfaces-IModel";
import { Region } from "./Dental-DataModel-Models-Region";
import { Employee } from "./Dental-DataModel-Models-Employee";
import { Work } from "./Dental-DataModel-Models-Work";
import { Color } from "./Dental-DataModel-Models-Color";
import { JobState } from "./Dental-DataModel-Models-JobState";

export class Job  implements IModel {
    
    id: number;
    regionId: number;
    region: Region;
    employeeId?: number;
    employee: Employee;
    number: number;
    doctorId?: number;
    doctor: Employee;
    workId?: number;
    work: Work;
    colorId?: number;
    color: Color;
    sum: number;
    startDate: any;
    endDate?: any;
    archived: boolean;
    archivedDate?: any;
    dueDate?: any;
    compulsory: boolean;
    doctorComment: string;
    technicanComment: string;
    lastChangedPersonId: number;
    jobStateId?: number;
    jobState: JobState;
    lastMessageLimitDate?: any;
    isNeedCheck?: boolean;
    lastChangeStatusDate?: any;
    lastScanDate?: any;
    lastChangedPerson: Employee;
    patient: string;
    isSent: boolean;
    comments: string;
}




  
