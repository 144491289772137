import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-simple-calendar-inline',
  templateUrl: './simple-calendar-inline.component.html',
  styleUrls: ['./simple-calendar-inline.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class SimpleCalendarInlineComponent implements OnInit {
  @Input() options: any;
  @Input() date = new Date();
  @Output() dateChanged = new EventEmitter<any>();

  month: any;
  next: any;
  previous: any;
  label: any;
  activeDates: any;
  todaysDate = new Date();
  selectedDate = new Date(this.date.getTime());

  constructor() {
    this.date.setDate(1);
  }

  ngOnInit() {
    this.month = document.querySelectorAll('[data-calendar-area="month"]')[0];
    this.next = document.querySelectorAll('[data-calendar-toggle="next"]')[0];
    this.previous = document.querySelectorAll('[data-calendar-toggle="previous"]')[0];
    this.label = document.querySelectorAll('[data-calendar-label="month"]')[0];
    this.date.setDate(1);
    this.createMonth();
    this.createListeners();
  }

  createListeners() {
    this.next.addEventListener('click', s => {
      this.clearCalendar();
      let nextMonth = this.date.getMonth() + 1;
      this.date.setMonth(nextMonth);
      this.createMonth();
    })


    // Clears the calendar and shows the previous month
    this.previous.addEventListener('click', s => {
      this.clearCalendar();
      let prevMonth = this.date.getMonth() - 1;
      this.date.setMonth(prevMonth);
      this.createMonth();
    })
  }

  createMonth() {
    let currentMonth = this.date.getMonth();
    this.date.setDate(1);
    while (this.date.getMonth() === currentMonth) {
      this.createDay(
        this.date.getDate(),
        this.date.getDay(),
        this.date.getFullYear()
      )
      this.date.setDate(this.date.getDate() + 1);
    }
    // while loop trips over and day is at 30/31, bring it back
    /* this.date.setDate(1); */
    this.date.setMonth(this.date.getMonth() - 1);

    this.label.innerHTML = this.monthsAsString(this.date.getMonth()) + ' ' + this.date.getFullYear();
    this.dateClicked();
  }

  createDay(num, day, year) {
    let newDay = document.createElement('div');
    let dateEl = document.createElement('span');
    dateEl.innerHTML = num;
    newDay.className = 'vcal-date';
    newDay.setAttribute('data-calendar-date', this.date.toString());

    // if it's the first day of the month
    if (num === 1) {
      if (day === 0) {
        newDay.style.marginLeft = (6 * 14.28) + '%'
      } else {
        newDay.style.marginLeft = ((day - 1) * 14.28) + '%'
      }
    }

    if (this.options && this.options.disablePastDays && this.date.getTime() <= this.todaysDate.getTime() - 1) {
      newDay.classList.add('vcal-date--disabled');
    } else {
      newDay.classList.add('vcal-date--active');
      newDay.setAttribute('data-calendar-status', 'active');
    }

    if (this.date.toDateString() === this.todaysDate.toDateString()) {
      newDay.classList.add('vcal-date--today');
    }

    if (this.date.toDateString() === this.selectedDate.toDateString()) {
      newDay.classList.add('vcal-date--selected');
    }

    newDay.appendChild(dateEl);
    this.month.appendChild(newDay);
  }

  dateClicked() {
    this.activeDates = document.querySelectorAll('[data-calendar-status="active"]');
    for (let i = 0; i < this.activeDates.length; i++) {
      this.activeDates[i].addEventListener('click', s => {
        /* let picked = document.querySelectorAll('[data-calendar-label="picked"]')[0];
        picked.innerHTML = this.dataset.calendarDate; */

        this.date = new Date(s.currentTarget.dataset.calendarDate);
        this.removeActiveClass();
        s.currentTarget.classList.add('vcal-date--selected');
        this.selectedDate = new Date(this.date.getTime());

        this.dateChanged.emit(this.selectedDate);
      });
    }
  }

  onTodayDateClick = () => {
    this.clearCalendar();
    this.date = new Date();
    this.selectedDate = new Date();
    this.createMonth();

    this.dateChanged.emit(this.selectedDate);
  }

  monthsAsString(monthIndex) {
    return [
      'Январь',
      'Февраль',
      'Март',
      'Апрель',
      'Май',
      'Июнь',
      'Июль',
      'Август',
      'Сентябрь',
      'Октябрь',
      'Ноябрь',
      'Декабрь'
    ][monthIndex]
  }

  clearCalendar() {
    this.month.innerHTML = '';
  }

  removeActiveClass() {
    for (let i = 0; i < this.activeDates.length; i++) {
      this.activeDates[i].classList.remove('vcal-date--selected');
    }
  }


}



/* var vanillaCalendar = {
   month: document.querySelectorAll('[data-calendar-area="month"]')[0],
   next: document.querySelectorAll('[data-calendar-toggle="next"]')[0],
   previous: document.querySelectorAll('[data-calendar-toggle="previous"]')[0],
   label: document.querySelectorAll('[data-calendar-label="month"]')[0],
   activeDates: null,
   date: new Date(),
   todaysDate: new Date(),
 
   init: function (options) {
     this.options = options
     this.date.setDate(1)
     this.createMonth()
     this.createListeners()
   },
 
   createListeners: function () {
     var _this = this
     this.next.addEventListener('click', function () {
       _this.clearCalendar()
       var nextMonth = _this.date.getMonth() + 1
       _this.date.setMonth(nextMonth)
       _this.createMonth()
     })
     // Clears the calendar and shows the previous month
     this.previous.addEventListener('click', function () {
       _this.clearCalendar()
       var prevMonth = _this.date.getMonth() - 1
       _this.date.setMonth(prevMonth)
       _this.createMonth()
     })
   },
 
   createDay: function (num, day, year) {
     var newDay = document.createElement('div')
     var dateEl = document.createElement('span')
     dateEl.innerHTML = num
     newDay.className = 'vcal-date'
     newDay.setAttribute('data-calendar-date', this.date)
 
     // if it's the first day of the month
     if (num === 1) {
       if (day === 0) {
         newDay.style.marginLeft = (6 * 14.28) + '%'
       } else {
         newDay.style.marginLeft = ((day - 1) * 14.28) + '%'
       }
     }
 
     if (this.options.disablePastDays && this.date.getTime() <= this.todaysDate.getTime() - 1) {
       newDay.classList.add('vcal-date--disabled')
     } else {
       newDay.classList.add('vcal-date--active')
       newDay.setAttribute('data-calendar-status', 'active')
     }
 
     if (this.date.toString() === this.todaysDate.toString()) {
       newDay.classList.add('vcal-date--today')
     }
 
     newDay.appendChild(dateEl)
     this.month.appendChild(newDay)
   },
 
   dateClicked: function () {
     var _this = this
     this.activeDates = document.querySelectorAll(
       '[data-calendar-status="active"]'
     )
     for (var i = 0; i < this.activeDates.length; i++) {
       this.activeDates[i].addEventListener('click', function (event) {
         var picked = document.querySelectorAll(
           '[data-calendar-label="picked"]'
         )[0]
         picked.innerHTML = this.dataset.calendarDate
         _this.removeActiveClass()
         this.classList.add('vcal-date--selected')
       })
     }
   },
 
   createMonth: function () {
     var currentMonth = this.date.getMonth()
     while (this.date.getMonth() === currentMonth) {
       this.createDay(
         this.date.getDate(),
         this.date.getDay(),
         this.date.getFullYear()
       )
       this.date.setDate(this.date.getDate() + 1)
     }
     // while loop trips over and day is at 30/31, bring it back
     this.date.setDate(1)
     this.date.setMonth(this.date.getMonth() - 1)
 
     this.label.innerHTML =
       this.monthsAsString(this.date.getMonth()) + ' ' + this.date.getFullYear()
     this.dateClicked()
   },
 
   
 
  
 
   
 } */