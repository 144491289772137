import { Component, EventEmitter, Output, Input, OnChanges, SimpleChanges, OnInit, ViewChild, ElementRef, forwardRef, OnDestroy } from '@angular/core';
import { BodyService } from '../../services';
import { ControlValueAccessor, Validator, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl, NgModel } from '@angular/forms';
import { NgForm } from '@angular/forms';
import { HashTable } from '../../../Helper/index';
import { Subscription } from 'rxjs';

@Component({
  selector: 'drop-down',
  templateUrl: './drop-down.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropDownComponent),
      multi: true,
    }, {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DropDownComponent),
      multi: true,
    }]
})
export class DropDownComponent implements OnChanges, OnInit, ControlValueAccessor, Validator {
  @Input() enableSearch: boolean;
  @Input() displayingText: string;
  @Input() dropDownData: HashTable<any>[];
  @Input() selectedItem: HashTable<any>;
  @Input() showTooltip: boolean;
  @Input() className: string;
  @Output() itemClickRequest = new EventEmitter<any>();

  @ViewChild("dropDownButton") dropDownButton: ElementRef;
  @ViewChild("searchInput") searchInput: ElementRef;

  toggler: boolean;
  ddModel: HashTable<any>[];
  ddViewModel: HashTable<any>[];
  currentItem: HashTable<any>;
  currentElement: Element;
  currentDisplayingText: string;
  currentSearchString: string;

  private bodyServiceSubscription: Subscription;

  constructor(private bodyService: BodyService) {
    this.toggler = false;

    this.ddModel = [];
    this.ddViewModel = [];
    this.currentItem = {};
    this.currentDisplayingText = '';
    this.showTooltip = false;

  }

  ngOnInit() {
    this.currentElement = this.dropDownButton.nativeElement as Element;

    this.bodyServiceSubscription = this.bodyService.getClick().subscribe(event => {
      //var el = event.srcElement || event.target as Element;
      var el = event.srcElement || event.target as any;
      if (!(this.currentElement == el || this.currentElement == el.parentElement || el.className.indexOf('drop-down-search-box') > -1)) {
        this.toggler = false;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["selectedItem"] && changes["selectedItem"].currentValue && this.currentItem != changes["selectedItem"].currentValue) {
      this.currentItem = changes["selectedItem"].currentValue as HashTable<any>;
      this.currentSearchString = this.getDisplayingText(this.currentItem);
      this.getDisplayingSelectText();
    }

    if (changes["dropDownData"] && changes["dropDownData"].currentValue) {
      this.ddModel = [];
      this.ddViewModel = [];
      this.ddModel.push(...this.dropDownData);
      this.ddViewModel.push(...this.dropDownData);

      if (!this.currentItem || (this.currentItem && Object.keys(this.currentItem).length == 0)) {
        this.currentItem = this.ddModel[0];
        this.currentSearchString = this.getDisplayingText(this.currentItem);
        this.getDisplayingSelectText();

        if (this.currentItem) {
          setTimeout(() => {
            this.itemClickRequest.emit(this.currentItem);
          }, 100);
        }
      }
    }
  }

  ngOnDestroy() {
    this.bodyServiceSubscription.unsubscribe();
  }

  getDisplayingText = (item: HashTable<any>): string => {
    if (!item)
      return '';
    return item[this.displayingText] || '';
  }

  getDisplayingSelectText = (): string => {

    if (!this.currentItem)
      return "";

    let res = this.currentItem[this.displayingText] as string;

    this.currentDisplayingText = res;

  }

  isDisplayingText = (item: HashTable<any>): boolean => {
    return !item || item[this.displayingText] != '';
  }


  toggleButton = ($event: MouseEvent): void => {
    this.toggler = !this.toggler;
  }

  onSelectItem = (selectedModel: HashTable<any>): void => {
    this.toggler = false;
    if (this.currentItem != selectedModel) {
      this.currentItem = selectedModel;
      this.currentSearchString = this.getDisplayingText(selectedModel);
      this.itemClickRequest.emit(this.currentItem);
      this.getDisplayingSelectText();
    }
    this.propagateChange(this.currentItem);
  }
  onSearchInputFocus() {
    this.ddViewModel = [];
    this.ddViewModel.push(...this.ddModel);
    this.currentSearchString = "";
    this.toggler = true;
  }
  onSearch(event: any): void {
    console.log(event);
    this.ddViewModel = [];
    if (!this.currentSearchString) {
      this.ddViewModel.push(...this.ddModel);
      return;
    }
    let searchString = this.currentSearchString.toLowerCase();
    let result = this.ddModel.filter(item => this.getDisplayingText(item).toLowerCase().indexOf(searchString) > -1);
    this.ddViewModel.push(...result);

  }

  public writeValue = (obj: any): void => {
    if (obj) {
      this.currentItem = obj;
    }
  };

  public registerOnChange = (fn: any): void => { this.propagateChange = fn; };

  public registerOnTouched = (): void => { };

  private propagateChange = (_: any) => { };

  public validate(c: FormControl) {
    return (true) ? null : {
      jsonParseError: {
        valid: false,
      },
    };
  }
  displaySelect = () => {
    if (/iP(od|hone|ad)/i.test(window.navigator.userAgent)) {
      return true;
    }
    return false;
  }
}
