﻿//------------------------------------------------------------------------------
//  This code was generated by typescript template tool 'Typewriter'.
//
//  Changes to this file may cause incorrect behavior and will be lost if
//  the code is regenerated.
//------------------------------------------------------------------------------

import { PersonView } from "./Dental-DataModel-Models-View-PersonView";

export class PersonViewDto extends PersonView  {
    
    password: string;
    changePassword: boolean;
    createRegionId?: number;
}




  
