﻿//------------------------------------------------------------------------------
//  This code was generated by typescript template tool 'Typewriter'.
//
//  Changes to this file may cause incorrect behavior and will be lost if
//  the code is regenerated.
//------------------------------------------------------------------------------

import { PriceViewModel } from "./Dental-WebCore-Models-PriceViewModel";

export class ProtezViewModel   {
    
    id: number;
    protezTypeId: number;
    name: string;
    prices: PriceViewModel[];
}




  
