import { Component, OnInit } from '@angular/core';
import { JobApiService, PersonApiService, PositionApiService } from '../../../../api/index';
import { FilteredGridRequestViewModel, BaseViewGridModel, GridRequestModel, PositionViewModel, Person, PositionViewFilterModels, PersonModel } from '../../../../models/index';
import { HashTable, Helper } from '../../../../Helper/index';
import { retry } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NotificationService } from '../../../services';
import { DatePipe } from '@angular/common';
import { IGridOptions, ControlType } from '../../../components/grid/grid.component';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';


@Component({
  selector: 'position',
  templateUrl: './position.component.html',
  providers: [DatePipe]
})
export class PositionComponent implements OnInit {

  gridData: BaseViewGridModel<PositionViewModel>;
  fieldOptions: HashTable<IGridOptions>;
  gridRequestModel: FilteredGridRequestViewModel<PositionViewFilterModels>;
  showDialog: boolean;
  showDeleteDialog: boolean;
  showPersosnDialog: boolean;
  selectedModel: PositionViewModel;
  persons: PersonModel[];
  selectedPersons: PersonModel[];
  currentDoctor: string;

  constructor(
    private service: PositionApiService,
    private notify: NotificationService,
    private datePipe: DatePipe,
    private personApiService: PersonApiService,
    private router: Router
  ) {
    this.gridData = {
      viewModelList: []
    } as BaseViewGridModel<PositionViewModel>;

    this.selectedPersons = [];
    this.persons = [];

    this.gridRequestModel = {} as FilteredGridRequestViewModel<PositionViewFilterModels>;

    this.fieldOptions = {
      name: {
        title: 'Наименование'
      },
      editUsers: {
        fieldType: {
          type: ControlType.button,
          defaultText: 'Пользователи',
          class: 'btn btn-warning',
          click: (item: PositionViewModel) => this.editUsers(item)
        },
        order: false
      },
      delete: {
        fieldType: {
          type: ControlType.button,
          defaultText: 'Удалить',
          class: 'btn btn-danger',
          click: (item: PositionViewModel) => this.deleteButtonClick(item)
        },
        order: false
      }
    };
    this.showDialog = false;
    this.showDeleteDialog = false;
    this.selectedModel = {} as PositionViewModel;
  }

  ngOnInit() {
    forkJoin(
      this.service.getAll(this.gridRequestModel),
      this.personApiService.getTechnicians()
    ).subscribe((data) => {
      this.gridData = data[0];
      this.persons = data[1];
    });
  }

  initGrid = () => {
    this.service.getAll(this.gridRequestModel).subscribe((data) => {
      this.gridData = { ...data };
    });
  }

  transformDate = (date): string =>
    this.datePipe.transform(date, 'dd.MM.yyyy HH:mm');

  gridRequestEvent = (model: GridRequestModel) => {
    this.gridRequestModel = model as FilteredGridRequestViewModel<PositionViewFilterModels>;
    this.initGrid();
  }

  rowClickEvent = (model: PositionViewModel) => {
    this.selectedModel = { ...model };
    this.showDialog = true;

  }

  addItem = (): void => {
    this.selectedModel = {} as PositionViewModel;
    this.showDialog = true;
  }

  editUsers = (item: PositionViewModel): void => {
    this.selectedModel = item;
    this.personApiService.getPersonsByPositionId(this.selectedModel.id).subscribe(data => {
      let map: HashTable<PersonModel[]> = new Helper().groupByNumber(data, s => s.id);
      this.selectedPersons = this.persons.filter(s => !!map[s.id]);
      this.showPersosnDialog = true;
    });
  }

  closeDialog = ($event) => this.showDialog = false;

  saveDialog = (): void => {
    this.service.save(this.selectedModel).subscribe(_ => {
      this.notify.showInfo("Запись сохранена успешно");
      this.showDialog = false;
      this.initGrid();
    });
  }

  deleteButtonClick = (model: PositionViewModel) => {
    this.showDeleteDialog = true;
    this.selectedModel = { ...model };
  }

  approveDelete = (model: PositionViewModel) => {
    if (!this.selectedModel) {
      this.showDeleteDialog = false;
      return;
    }

    this.service.delete(this.selectedModel.id).subscribe(_ => {
      this.notify.showInfo("Запись удалена!");
      this.showDeleteDialog = false;
      this.initGrid();
    });
  }

  closeDeleteDialog = ($event) => this.showDeleteDialog = false;

  closePeronDialog = ($event) => this.showPersosnDialog = false;


  selectedPerson = (items: PersonModel[]) => {
    this.selectedPersons = items;
  }

  savePersons = () => {
    this.personApiService.savePersonPosition({ state: this.selectedModel.id, items: this.selectedPersons.map(s => s.id) }).subscribe(_ => {
      this.notify.showInfo("Записи сохранены успешо!");
    });
  }
}
