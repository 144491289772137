﻿//------------------------------------------------------------------------------
//  This code was generated by typescript template tool 'Typewriter'.
//
//  Changes to this file may cause incorrect behavior and will be lost if
//  the code is regenerated.
//------------------------------------------------------------------------------

import { Region } from "./Dental-DataModel-Models-Region";

export class RegionViewModel extends Region  {
    
}




  
