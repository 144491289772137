import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CheckListComponent } from "./check-list.component";
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [CheckListComponent],
    imports: [RouterModule, CommonModule, FormsModule],
  exports: [CheckListComponent]
})

export class CheckListModule { }
