import { Component, OnInit } from '@angular/core';
import { JobApiService, PersonApiService, DictionaryApiService, WorkApiService, SalaryBookApiService } from '../../../../api/index';
import { FilteredGridRequestViewModel, BaseViewGridModel, GridRequestModel, PersonViewFilterModels, SalaryBookViewModel, PersonType, BaseDropDownViewModel, PersonTypeViewModel, Work, SalaryBookViewFilterModels, PersonViewModel, Person, PersonModel, WorkShopPositionViewModel, ProtezPriceViewModel } from '../../../../models/index';
import { HashTable, fullDoctorName, fullPersonName, fullName } from '../../../../Helper/index';
import { retry } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NotificationService } from '../../../services';
import { DatePipe } from '@angular/common';
import { IGridOptions, ControlType } from '../../../components/grid/grid.component';
import { fail } from 'assert';
import { forkJoin } from 'rxjs';


class SalaryBookViewModelExt extends SalaryBookViewModel {
  protezList: ProtezPriceViewModel[];
  workTypeList: BaseDropDownViewModel<number>[];
  userList: PersonModel[];

  selectedProtez: ProtezPriceViewModel;
  selectedWorkTypeList: BaseDropDownViewModel<number>[];
  selectedUserList: PersonModel[];
}

@Component({
  selector: 'salary-book',
  templateUrl: './salary-book.component.html',
  providers: [DatePipe]
})
export class SalaryBookComponent implements OnInit {

  gridData: BaseViewGridModel<SalaryBookViewModel>;
  fieldOptions: HashTable<IGridOptions>;
  gridRequestModel: FilteredGridRequestViewModel<SalaryBookViewFilterModels>;
  showDialog: boolean;
  showEditDialog: boolean;
  showBulkEditDialog: boolean;
  showBulkEditButton: boolean;
  showDeleteDialog: boolean;
  selectedModel: SalaryBookViewModelExt;

  protezList: ProtezPriceViewModel[];
  workTypeList: BaseDropDownViewModel<number>[];

  filterProtezList: ProtezPriceViewModel[];
  filterWorkTypeList: BaseDropDownViewModel<number>[];

  bulkPrice?: number;
  bulkPercentage?: number;
  editRequestModel: FilteredGridRequestViewModel<SalaryBookViewModel>;

  selectedProtezFitler: ProtezPriceViewModel;
  selectedWorkTypeFitler: BaseDropDownViewModel<number>;

  constructor(
    private service: SalaryBookApiService,
    private notify: NotificationService,
    private datePipe: DatePipe,
    private personApiService: PersonApiService,
    private dictionarySerivce: DictionaryApiService
  ) {
    this.gridData = {
      viewModelList: []
    } as BaseViewGridModel<SalaryBookViewModel>;

    this.gridRequestModel = {
      filter: {
        filterModel: {}
      }
    } as FilteredGridRequestViewModel<SalaryBookViewFilterModels>;

    this.editRequestModel = {
    } as FilteredGridRequestViewModel<SalaryBookViewModel>;

    this.fieldOptions = {
      protezName: {
        title: 'Протез',
        order: false
      },
      workTypeName: {
        title: 'Цех',
        order: false
      },
      personPositionName: {
        title: 'Должность',
        order: false
      },
      fullName: {
        title: 'ФИО',
        fieldType: {
          type: ControlType.customValue,
          value: (item: SalaryBookViewModel) => this.fullName(item.lastName, item.firstName, item.middleName)
        },
        order: false
      },
      price: {
        title: 'Цена',
        order: false
      },
      percentage: {
        title: '% от цеха',
        order: false
      },
      delete: {
        fieldType: {
          type: ControlType.button,
          defaultText: 'Удалить',
          class: 'btn btn-danger',
          click: (item: SalaryBookViewModelExt) => this.deleteButtonClick(item)
        },
        order: false
      }
    };
    this.showDialog = false;
    this.cleanModel();
  }

  fullName = fullName;

  cleanModel = () => this.selectedModel = {
    protezList: [],
    workTypeList: [],
    userList: [],

    selectedProtez: {},
    selectedWorkTypeList: [],
    selectedUserList: [],
  } as SalaryBookViewModelExt;

  ngOnInit() {
    forkJoin(
      this.dictionarySerivce.getProtezesWithUkrPrices(),
      this.dictionarySerivce.getWorkTypes()
    ).subscribe(data => {
      this.protezList = [];
      this.protezList.push(...data[0]);

      this.workTypeList = [];
      this.workTypeList.push(...data[1]);

      this.filterProtezList = [];
      this.selectedProtezFitler = { id: 0, name: "Все", currencyId: 0, price: 0, protezId: 0 };
      this.filterProtezList.push(this.selectedProtezFitler);
      this.filterProtezList.push(...data[0]);

      this.filterWorkTypeList = [];
      this.selectedWorkTypeFitler = { id: 0, name: "Все" };
      this.filterWorkTypeList.push();
      this.filterWorkTypeList.push(this.selectedWorkTypeFitler);
      this.filterWorkTypeList.push(...data[1]);


    })

    this.initGrid();
  }

  initGrid = () => {
    this.service.getAll(this.gridRequestModel).subscribe((data) => {
      this.gridData = { ...data };
    });
  }

  transformDate = (date): string =>
    this.datePipe.transform(date, 'dd.MM.yyyy HH:mm');

  gridRequestEvent = (model: GridRequestModel) => {
    /* debugger; */
    this.gridRequestModel = { ...model as FilteredGridRequestViewModel<SalaryBookViewFilterModels>, filter: this.gridRequestModel.filter };
    //this.gridRequestModel = model as FilteredGridRequestViewModel<SalaryBookViewFilterModels>;
    this.initGrid();
  }

  rowClickEvent = (model: SalaryBookViewModelExt) => {
    this.selectedModel = { ...model };
    this.showEditDialog = true;
  }

  addItem = (): void => {
    this.cleanModel();
    this.selectedModel.protezList.push(...this.protezList);
    this.selectedModel.workTypeList.push(...this.workTypeList);

    this.selectedModel.selectedProtez = this.selectedModel.protezList[0] || {} as ProtezPriceViewModel;
    this.showDialog = true;
  }

  closeDialog = ($event) => this.showDialog = false;

  saveDialog = (): void => {
    this.selectedModel.personIds = [];
    this.selectedModel.personIds.push(...this.selectedModel.selectedUserList.map(s => s.id));
    this.selectedModel.protezId = this.selectedModel.selectedProtez.id;
    this.service.save(this.selectedModel).subscribe(_ => {
      this.notify.showInfo("Запись сохранена успешно");
      this.showDialog = false;
      this.initGrid();
    });
  }

  deleteButtonClick = (model: SalaryBookViewModelExt) => {
    this.showDeleteDialog = true;
    this.selectedModel = { ...model };
  }

  approveDelete = () => {
    if (!this.selectedModel) {
      this.showDeleteDialog = false;
      return;
    }

    this.service.delete(this.selectedModel.workTypeId, this.selectedModel.protezId, this.selectedModel.personId).subscribe(_ => {
      this.notify.showInfo("Запись удалена!");
      this.showDeleteDialog = false;
      this.initGrid();
    });
  }

  closeDeleteDialog = ($event) => this.showDeleteDialog = false;

  OnProtezChange = (item: ProtezPriceViewModel) => this.selectedModel.selectedProtez = item;

  OnWorkTypeListChange = (items: BaseDropDownViewModel<number>[]) => {
    this.selectedModel.selectedWorkTypeList = [];
    this.selectedModel.selectedWorkTypeList.push(...items);
    this.personApiService.getPersonsByWorkTypeIds({ state: 0, items: items.map(s => s.id) }).subscribe(data => {
      this.selectedModel.userList = data;
      this.selectedModel.selectedUserList = [];
    });
  }

  OnUserListChange = (items: PersonModel[]) => {
    this.selectedModel.selectedUserList = [];
    this.selectedModel.selectedUserList.push(...items);
  }

  saveEditDialog = () => {
    this.editRequestModel.selectedItems = [];
    this.editRequestModel.selectedItems.push(this.selectedModel);
    this.service.edit(this.editRequestModel).subscribe(_ => {
      this.notify.showInfo("Запись сохранена успешно");
      this.showEditDialog = false;
      this.initGrid();
    });
  }

  OnProtezFitlerChange = ($event: ProtezPriceViewModel) =>
    this.selectedProtezFitler = $event;

  OnWorkTypeFitlerChange = ($event: BaseDropDownViewModel<number>) =>
    this.selectedWorkTypeFitler = $event;

  gridCheckedItemsEvent = (models: SalaryBookViewModelExt[]) => {
    this.editRequestModel.selectedItems = [];
    this.editRequestModel.selectedItems.push(...models);
    this.showBulkEditButton = this.editRequestModel.selectedItems.length > 0;
  }

  closEditDialog = () => this.showEditDialog = false;

  bulkEdit = () => {
    this.bulkPrice = null;
    this.bulkPercentage = null;
    this.showBulkEditDialog = true;
  }

  closeBulkEdit = () => this.showBulkEditDialog = false;

  saveBulkEditDialog = () => {
    this.editRequestModel.selectedItems.forEach(item => {
      item.price = this.bulkPrice;
      item.percentage = this.bulkPercentage;
    });

    this.service.edit(this.editRequestModel).subscribe(_ => {
      this.notify.showInfo("Запись сохранена успешно");
      this.editRequestModel.selectedItems = [];
      this.showBulkEditDialog = false;
      this.initGrid();
    });
  }

  onSearchClick = () => {
    this.gridRequestModel.filter.filterModel.workTypeId = this.selectedWorkTypeFitler.id == 0 ? null : this.selectedWorkTypeFitler.id;
    this.gridRequestModel.filter.filterModel.protezId = this.selectedProtezFitler.id == 0 ? null : this.selectedProtezFitler.id;
    this.initGrid();
  }
}
