import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { ListComponent } from "./list.component";
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [ListComponent],
    imports: [RouterModule, CommonModule, FormsModule],
  exports: [ListComponent]
})

export class ListModule { }
