﻿//------------------------------------------------------------------------------
//  This code was generated by typescript template tool 'Typewriter'.
//
//  Changes to this file may cause incorrect behavior and will be lost if
//  the code is regenerated.
//------------------------------------------------------------------------------

import { SalaryJobProtezViewModel } from "./Dental-WebCore-Models-SalaryJobProtezViewModel";

export class SalaryJobViewModel   {
    
    id: number;
    createDate?: any;
    sum: number;
    jobName: string;
    regionName: string;
    salaryJobProtezList: SalaryJobProtezViewModel[];
}




  
