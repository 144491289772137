import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'layout-taskmaster',
  templateUrl: './layout-taskmaster.component.html'
})
export class LayoutTaskmasterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
