import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { DropDownComponent } from "./drop-down.component";
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';

@NgModule({
    declarations: [DropDownComponent],
    imports: [RouterModule, CommonModule, FormsModule],
    exports: [DropDownComponent]
})

export class DropDownModule { }
