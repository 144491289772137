﻿//------------------------------------------------------------------------------
//  This code was generated by typescript template tool 'Typewriter'.
//
//  Changes to this file may cause incorrect behavior and will be lost if
//  the code is regenerated.
//------------------------------------------------------------------------------

import { IPageableQueryModel } from "./Dental-WebCore-Models-IPageableQueryModel";

export class BasePageableQueryModel  implements IPageableQueryModel {
    
    pageNumber: number;
    pageSize: number;
}




  
