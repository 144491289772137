﻿//------------------------------------------------------------------------------
//  This code was generated by typescript template tool 'Typewriter'.
//
//  Changes to this file may cause incorrect behavior and will be lost if
//  the code is regenerated.
//------------------------------------------------------------------------------

import { JobView } from "./Dental-DataModel-Models-View-JobView";
import { ProtezView } from "./Dental-DataModel-Models-View-ProtezView";

export class JobViewModel extends JobView  {
    
    protezes: ProtezView[];
}




  
