import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'vacancy',
  templateUrl: './vacancy.component.html'
})
export class VacancyComponent{
  
  messages: string[];
  constructor() {
    this.messages = [];
  }

  doMessage = (text: string) => {
    this.messages.push(text);
   }
}
