import { Component, OnInit } from '@angular/core';
import { HashTable } from '../../../../Helper/index';
import { CommonSettingsApiService } from '../../../../api/index';
import { ControlType, IGridOptions } from '../../../components/grid/grid.component';
import { NotificationService } from '../../../services';
import { BaseViewGridModel, CommonSettingsViewFilterModels, CommonSettingsViewModel, FilteredGridRequestViewModel, GridRequestModel } from '../../../../models/index';

@Component({
  selector: 'app-other-settings',
  templateUrl: './other-settings.component.html',
  styleUrls: ['./other-settings.component.less']
})
export class OtherSettingsComponent implements OnInit {
  gridData: BaseViewGridModel<CommonSettingsViewModel>;
  gridRequestModel: FilteredGridRequestViewModel<CommonSettingsViewFilterModels>;
  fieldOptions: HashTable<IGridOptions>;
  showDialog: boolean;
  selectedModel: CommonSettingsViewModel;

  constructor(private commonSettingsService: CommonSettingsApiService,
    private notify: NotificationService) {
    this.gridData = {
      viewModelList: []
    } as BaseViewGridModel<CommonSettingsViewModel>;

    this.gridRequestModel = {} as FilteredGridRequestViewModel<CommonSettingsViewFilterModels>;

    this.fieldOptions = {
      name: {
        title: 'Название'
      },
      value: {
        title: 'Значение'
      }
    };
    
  }

  ngOnInit() {
    this.initGrid();
  }

  initGrid = () => {
    this.commonSettingsService.getAll(this.gridRequestModel).subscribe(s => {
      this.gridData = { ...s };
    });
  }

  gridRequestEvent = (model: GridRequestModel) => {
    this.gridRequestModel = model as FilteredGridRequestViewModel<CommonSettingsViewFilterModels>;
    this.initGrid();
  }

  rowClickEvent = (model: CommonSettingsViewModel) => {
    this.selectedModel = { ...model };
    this.showDialog = true;
  }

  saveDialog = (): void => {
    this.commonSettingsService.update(this.selectedModel).subscribe(_ => {
      this.notify.showInfo("Запись сохранена успешно");
      this.showDialog = false;
      this.initGrid();
    });
  }

  closeDialog = ($event) => this.showDialog = false;

}
