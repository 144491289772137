import { Component, OnInit } from '@angular/core';
import { JobApiService, PersonApiService, DictionaryApiService, ProtezApiService } from '../../../../api/index';
import { FilteredGridRequestViewModel, BaseViewGridModel, GridRequestModel, PersonViewFilterModels, ProtezViewModel, PersonType, BaseDropDownViewModel, PersonTypeViewModel, PersonViewModel, Person, PersonModel, ProtezType, PriceViewModel, CurrencyType, ProtezViewFilterModels } from '../../../../models/index';
import { HashTable, fullDoctorName, fullPersonName } from '../../../../Helper/index';
import { retry } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NotificationService } from '../../../services';
import { DatePipe } from '@angular/common';
import { IGridOptions, ControlType } from '../../../components/grid/grid.component';
import { forkJoin } from 'rxjs';


@Component({
  selector: 'protez-page',
  templateUrl: './protez-page.component.html',
  providers: [DatePipe]
})
export class ProtezPageComponent implements OnInit {

  gridData: BaseViewGridModel<ProtezViewModel>;
  fieldOptions: HashTable<IGridOptions>;
  gridRequestModel: FilteredGridRequestViewModel<ProtezViewFilterModels>;
  showDialog: boolean;
  selectedModel: ProtezViewModel;
  doctors: Person[];
  currentDoctor: string;
  protezTypes: ProtezType[];
  filterPprotezTypes: ProtezType[];
  currencyTypes: BaseDropDownViewModel<number>[];
  showDeleteDialog: boolean;
  selectedProtezType: ProtezType;
  selectedProtezFilterItem: ProtezType;

  constructor(
    private service: ProtezApiService,
    private notify: NotificationService,
    private datePipe: DatePipe,
    private personApiService: PersonApiService,
    private dictionaryService: DictionaryApiService
  ) {
    this.gridData = {
      viewModelList: []
    } as BaseViewGridModel<ProtezViewModel>;

    this.gridRequestModel = {
      filter: {
        filterModel: {}
      }
    } as FilteredGridRequestViewModel<ProtezViewFilterModels>;


    this.showDialog = false;
    this.selectedModel = {} as ProtezViewModel;
    this.protezTypes = [];
    this.filterPprotezTypes = [];
    this.currencyTypes = [];

    this.InitFieldOptions();
  }

  InitFieldOptions = (): void => {
    this.fieldOptions = {
      name: {
        title: 'Наименование'
      },
      protezType: {
        title: 'Тип протеза',
        fieldType: {
          type: ControlType.customValue,
          value: (item: ProtezViewModel) => {
            let type = this.protezTypes.filter((type) => item.protezTypeId == type.id)[0];
            if (type)
              return type.name;

            return null;
          }
        }
      }
    };

    this.currencyTypes.forEach(item => {
      this.fieldOptions[`priceType_${item.id}`] = {
        title: `Валюта, ${item.name}`,
        fieldType: {
          type: ControlType.customValue,
          value: (pItem: ProtezViewModel) => {
            let type = pItem.prices.filter((type) => item.id == type.currencyId)[0];
            if (type)
              return type.price.toLocaleString();

            return null;
          }
        }, order: false
      }
    });

    this.fieldOptions.delete = {
      fieldType: {
        type: ControlType.button,
        defaultText: 'Удалить',
        class: 'btn btn-danger',
        click: (item: ProtezViewModel) => this.deleteButtonClick(item)
      },
      order: false
    };
  }

  ngOnInit() {
    forkJoin(
      this.dictionaryService.getProtezTypes(),
      this.dictionaryService.getCurrencyTypes(),
    ).subscribe((data) => {
      this.protezTypes = [];
      this.protezTypes.push(...data[0]);

      this.filterPprotezTypes = [];
      this.filterPprotezTypes.push({ id: 0, name: "Все" });
      this.filterPprotezTypes.push(...data[0]);

      this.currencyTypes = [];
      this.currencyTypes.push(...data[1]);

      this.InitFieldOptions();
    });
    this.initGrid();
  }

  initGrid = () => {
    this.service.getAll(this.gridRequestModel).subscribe((data) => {
      this.gridData = { ...data };
    });
  }

  transformDate = (date): string =>
    this.datePipe.transform(date, 'dd.MM.yyyy HH:mm');

  gridRequestEvent = (model: GridRequestModel) => {
    this.gridRequestModel = { ...model as FilteredGridRequestViewModel<ProtezViewFilterModels>, filter: this.gridRequestModel.filter };
    this.initGrid();
  }

  rowClickEvent = (model: ProtezViewModel) => {
    this.selectedModel = { ...model };
    this.selectedModel.prices = model.prices.map(s => { return { ...s }; });
    this.selectedProtezType = { ... this.protezTypes.filter(s => s.id == this.selectedModel.protezTypeId)[0] || {} as ProtezType };

    this.showDialog = true;

  }

  addItem = (): void => {
    this.selectedModel = {
      prices: []
    } as ProtezViewModel;
    this.showDialog = true;
  }

  getPrice = (currencyType: BaseDropDownViewModel<number>): PriceViewModel => {
    let result = this.selectedModel.prices.filter(s => s.currencyId == currencyType.id)[0];
    if (result)
      return result;

    result = {
      currencyId: currencyType.id,
      price: 0,
      protezId: this.selectedModel.id
    } as PriceViewModel;
    this.selectedModel.prices.push(result);
    return result;

  }

  closeDialog = ($event) => this.showDialog = false;

  saveDialog = (): void => {
    this.selectedModel.protezTypeId = this.selectedProtezType.id;
    this.service.save(this.selectedModel).subscribe(_ => {
      this.notify.showInfo("Запись сохранена успешно");
      this.showDialog = false;
      this.initGrid();
    });
  }


  deleteButtonClick = (model: ProtezViewModel) => {
    this.showDeleteDialog = true;
    this.selectedModel = { ...model };
  }

  approveDelete = (model: ProtezViewModel) => {
    if (!this.selectedModel) {
      this.showDeleteDialog = false;
      return;
    }

    this.service.delete(this.selectedModel.id).subscribe(_ => {
      this.notify.showInfo("Запись удалена!");
      this.showDeleteDialog = false;
      this.initGrid();
    });
  }


  OnProtezTypeChange = ($event: ProtezType) =>
    this.selectedProtezType = $event;

  OnTypeFitlerChange = ($event: ProtezType) =>
    this.selectedProtezFilterItem = $event;

  closeDeleteDialog = ($event) => this.showDeleteDialog = false;


  onSearchClick = () => {
    this.gridRequestModel.filter.filterModel.protezTypeId = this.selectedProtezFilterItem.id == 0 ? null : this.selectedProtezFilterItem.id;
    this.initGrid();
  }

}
