﻿//------------------------------------------------------------------------------
//  This code was generated by typescript template tool 'Typewriter'.
//
//  Changes to this file may cause incorrect behavior and will be lost if
//  the code is regenerated.
//------------------------------------------------------------------------------


import { Injectable } from "@angular/core";
import { ResponseContentType, Response } from "@angular/http";
import { Observable } from 'rxjs';
import { BasApiService } from '../base.api.service';
import {GridApiService} from './grid.api.service';
import * as ApiTypes from "../../models";

@Injectable()
export class WorkTypeApiService extends GridApiService<ApiTypes.WorkTypeViewModel,ApiTypes.WorkTypeViewFilterModels>{
  
  constructor(public baseService: BasApiService) {
   super(baseService);
    this.apiKey = "api/workType";
  }
  
  save = (model: ApiTypes.WorkTypeViewModel): Observable<void> =>
    this.baseService.post<void>(`api/WorkType/save`, model, {}, true, true);   
  
  getUsersForPosition = (): Observable<ApiTypes.BaseDropDownViewModel<number>[]> =>
    this.baseService.get<ApiTypes.BaseDropDownViewModel<number>[]>(`api/WorkType/GetUsersForPosition`, {}, true, true);   
  
  delete = (id: number): Observable<void> =>
    this.baseService.delete<void>(`api/WorkType/${id}`, {}, true, true);   
  
  }

