﻿//------------------------------------------------------------------------------
//  This code was generated by typescript template tool 'Typewriter'.
//
//  Changes to this file may cause incorrect behavior and will be lost if
//  the code is regenerated.
//------------------------------------------------------------------------------

import { IModel } from "./Dental-DataModel-Interfaces-IModel";

export class Color  implements IModel {
    
    id: number;
    key: string;
    colorCode: string;
}




  
