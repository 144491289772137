﻿//------------------------------------------------------------------------------
//  This code was generated by typescript template tool 'Typewriter'.
//
//  Changes to this file may cause incorrect behavior and will be lost if
//  the code is regenerated.
//------------------------------------------------------------------------------

import { IBaseModel } from "./Dental-DataModel-Interfaces-IBaseModel";
import { SalaryType } from "./Dental-DataModel-CustomTypes-SalaryType";

export class SalaryBook  implements IBaseModel {
    
    protezId: number;
    workTypeId: number;
    state: SalaryType;
    personId: number;
}




  
