﻿//------------------------------------------------------------------------------
//  This code was generated by typescript template tool 'Typewriter'.
//
//  Changes to this file may cause incorrect behavior and will be lost if
//  the code is regenerated.
//------------------------------------------------------------------------------


import { Injectable } from "@angular/core";
import { ResponseContentType, Response } from "@angular/http";
import { Observable } from 'rxjs';
import { BasApiService } from '../base.api.service';

import * as ApiTypes from "../../models";

@Injectable()
export class ProtezPriceApiService {
  
  constructor(public baseService: BasApiService) {
   
  }
  
  getByCurrencyId = (id: number): Observable<ApiTypes.ProtezPrice[]> =>
    this.baseService.get<ApiTypes.ProtezPrice[]>(`api/ProtezPrice/currency/${id}`, {}, true, true);   
  
  }

