import { Component, EventEmitter, Output, Input, OnChanges, SimpleChanges, OnInit } from '@angular/core';

@Component({
    selector: 'grid-pager',
    templateUrl: './grid-pager.component.html'
})
export class GridPagerComponent implements OnInit, OnChanges {
    @Input() pageNumber: number = 1;
    @Input() countPages: number = 1;
    @Input() range: number = 5;
    @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();

    private pages: Array<number>;

    constructor() {
        this.pages = new Array<number>(this.range);
        for (var i = 0; i < this.range; i++) {
            this.pages[i] = i + 1;
        }
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.pages === undefined) return;
        if ((changes["pageNumber"] && changes["pageNumber"].currentValue)
            || (changes["countPages"] && changes["countPages"].currentValue)
            || (changes["range"] && changes["range"].currentValue)) {
            this.getPages((changes["pageNumber"] && changes["pageNumber"].previousValue) ? changes["pageNumber"].previousValue : this.pageNumber);
        }
    }

    private isValidPageNumber(page: number): boolean {
        return page > 0 && page <= this.countPages;
    }

    private isInRange(pageNumber: number): boolean {
        return (Math.max.apply(Math, this.pages) >= pageNumber) && (Math.min.apply(Math, this.pages) <= pageNumber);
    }


    private getPages = (previousPage: number): void => {
        var pagerRange = (this.countPages >= this.range) ? this.range : this.countPages;

        if (this.pages.length != pagerRange) this.pages = new Array<number>(pagerRange);
        if (this.isInRange(this.pageNumber)) return;

        var firstVisiblePage = this.pageNumber;

        var lastRangeIndex = Math.min(this.range, this.countPages);
        if (!this.isInRange(this.pageNumber)) {
            if (this.pageNumber > this.pages[this.range - 1]) {
                firstVisiblePage = (this.countPages - this.pageNumber >= pagerRange) ? this.pageNumber : this.countPages - this.range + 1;
            }
            else if (this.pageNumber < this.pages[0]) {
                firstVisiblePage = Math.max(1, this.pageNumber - this.range + 1);
            }
        }

        for (var i = 0; i < pagerRange; i++) {
            this.pages[i] = firstVisiblePage++;
        }
    }

    raisePageChange = (pageNumber: number, event: Event, disabled = false): void => {
        //ToDo: Validate page number
        if (disabled)
            return;

        this.pageChange.emit(pageNumber);
    }
}
