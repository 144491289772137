import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from "@angular/router";

import { AuthGuard } from '../../services/index';
import { ClientHeaderComponent } from './client-header.component';
import { HttpClientModule } from '@angular/common/http';



@NgModule({
  declarations: [
    ClientHeaderComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule
  ],
  providers: [],
  exports: [ClientHeaderComponent]
})
export class ClientHeaderModule { }
