import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'layout-clinic',
  templateUrl: './layout-clinic.component.html'
})
export class LayoutClinicComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
