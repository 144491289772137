﻿//------------------------------------------------------------------------------
//  This code was generated by typescript template tool 'Typewriter'.
//
//  Changes to this file may cause incorrect behavior and will be lost if
//  the code is regenerated.
//------------------------------------------------------------------------------

import { BasePageableQueryModel } from "./Dental-WebCore-Models-BasePageableQueryModel";
import { OrderType } from "./Dental-WebCore-Models-CustomTypes-OrderType";

export class GridRequestModel extends BasePageableQueryModel  {
    
    orderName: string;
    orderBy: OrderType;
}




  
