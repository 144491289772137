﻿//------------------------------------------------------------------------------
//  This code was generated by typescript template tool 'Typewriter'.
//
//  Changes to this file may cause incorrect behavior and will be lost if
//  the code is regenerated.
//------------------------------------------------------------------------------



export class RegionProtezViewModel   {
    
    regionId: number;
    regionName: string;
    price: number;
    protezId: number;
    protezName: string;
    currencyId: number;
    currencyName: string;
    originalPrice: number;
}




  
