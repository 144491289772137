import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from "@angular/router";

import { LayoutTechincianComponent } from './layout-techincian.component';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from '../../services/index';
import * as components from '../../components'; 
import { HomeModule } from './home/home.module';
import { HttpClientModule } from '@angular/common/http';

const layoutTechincianRoutes: Routes = [{
  path: 'techincian',
  component: LayoutTechincianComponent,
  canActivate: [AuthGuard],
  children: [
    { path: '', component: HomeComponent, data: { title: 'Home', headerTitle:'Пользователь - Техник' } }
  ]
}];


let imports: any = [
  BrowserModule,
  FormsModule,
  HttpClientModule,
  HomeModule,
  RouterModule.forChild(layoutTechincianRoutes)
];

//add all modules for component
imports.push(...Object.keys(components).map(key => components[key]));

@NgModule({
  declarations: [
    LayoutTechincianComponent
  ],
  imports: [imports],
  providers: [],
  exports: [LayoutTechincianComponent]
})
export class LayoutTechincianModule { }
