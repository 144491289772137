import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'home',
  templateUrl: './home.component.html'
})
export class HomeComponent{
  
  messages: string[];
  constructor() {
    this.messages = [];
  }

  doMessage = (text: string) => {
    this.messages.push(text);
   }
}
