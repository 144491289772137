import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.less']
})
export class DateTimePickerComponent implements OnInit {
  @Input() placeholder = '';
  @Input() showTodayBtn = true;
  @Input() dtValue = new Date();
  @Input() readonly = false;
  @Input() isMeridian = false;
  @Input() showSpinners = true;
  @Output() dtValueChange = new EventEmitter<any>();


  constructor() { }

  ngOnInit() {
  }

  onDtValueChange = ($event: any) => {
    this.dtValueChange.emit($event);
  }

  onTimeChange = ($event: any) => {
    this.dtValueChange.emit($event);
  }
}
