import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../../../../services/index';
import { UserAuth } from '../../../../../models/index';
import { NgForm } from '@angular/forms';


@Component({
  selector: 'login-form',
  templateUrl: './login-form.component.html'  
})
export class LoginFormComponent implements OnInit {
  ngOnInit(): void {
    this.form.resetForm();
  }

  user: UserAuth;

  @ViewChild("loginForm") form: NgForm;

  constructor(private authService: AuthService) {
    this.user = {} as UserAuth;
  }

  login = (form: NgForm): void => {
    if (!form.valid)
      return;

    this.authService.login(this.user);
  }
}
