import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { ModalFormWindowComponent } from "./modal-form-window.component";
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [ModalFormWindowComponent],
  imports: [RouterModule, CommonModule, FormsModule],
  exports: [ModalFormWindowComponent]
})

export class ModalFormWindownModule { }
