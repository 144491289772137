﻿//------------------------------------------------------------------------------
//  This code was generated by typescript template tool 'Typewriter'.
//
//  Changes to this file may cause incorrect behavior and will be lost if
//  the code is regenerated.
//------------------------------------------------------------------------------

import { FilteredGridRequestViewModel } from "./Dental-WebCore-Models-FilteredGridRequestViewModel";
import { UserInPositionViewFilterModels } from "./Dental-WebCore-Models-FilterModels-UserInPositionViewFilterModels";

export class UserPositionQueryModel extends FilteredGridRequestViewModel<UserInPositionViewFilterModels>  {
    
}




  
