import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'layout-admin',
  templateUrl: './layout-admin.component.html'
})
export class LayoutAdministratorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
