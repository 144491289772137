import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { ModalWindowComponent } from "./modal-window.component";
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [ModalWindowComponent],
  imports: [RouterModule, CommonModule, FormsModule],
  exports: [ModalWindowComponent]
})

export class ModalWindownModule { }
