import { Component, OnInit, Output, EventEmitter, NgZone, ViewChild, ElementRef } from '@angular/core';
//import { NgLog } from '../../../../Helper/index';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { NgLog, Camera } from '../../../Helper';

interface ICamera {
  id: string,
  name: string,
  facingMode: string
}

@NgLog()
@Component({
  selector: 'web-cam',
  templateUrl: './web-cam.component.html',
  styleUrls: ['./web-cam.component.less']
})
export class WebCamComponent implements OnInit {
  cameras: Camera[];
  errors: string[];
  @ViewChild("preview") video: ElementRef;
  @Output() videoItemOnInit = new EventEmitter<any>();
  @Output() camerasOnInit = new EventEmitter<Camera[]>();
  @Output() onError = new EventEmitter<any>();

  constraints: MediaStreamConstraints = {
    audio: false,
    video: true
  };

  stream: MediaStream;
  scanner: any;
  currentValue: string;
  isFirstInit: boolean = false;

  constructor(private zone: NgZone) {
    this.errors = [];
    this.cameras = [];
  }

  ngOnInit() {
    this.captureCam();
  }
  
  captureCam = (): void => {
    this.stopStreams();
    navigator.mediaDevices.getUserMedia(this.constraints).
      then(this.handleSuccess).catch(this.handleError);
  }
  
  handleSuccess = (stream: MediaStream): void => {
    console.log('Handle stream');
    this.stream = stream;
    let videoTracks: MediaStreamTrack[] = this.stream.getVideoTracks();
    stream.oninactive = function () {
      console.log('Stream inactive');
    };
    this.videoItemOnInit.emit(this.video.nativeElement)
    this.video.nativeElement.srcObject = stream;
    if (!this.isFirstInit) {
      Camera.getCameras().then((devices) => {
        this.zone.run(() => this.cameras = devices);
        console.log('Web-Cam camerasOnInit');
        this.camerasOnInit.emit(this.cameras);
      }).catch(this.handleError);
      this.isFirstInit = true;
    }
  }

  handleError = (error): void => {
    this.onError.emit(error);
  }

  stopStreams = (): void => {
    if (this.stream)
      for (let stream of this.stream.getVideoTracks()) {
        console.log('Web-Cam Stream stop');
        stream.stop();
      }
  }

  ngOnDestroy() {
    this.stopStreams();
  }

}
