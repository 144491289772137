import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule } from '@angular/forms';
import { QrScanerComponent } from "./qr-scaner.component";
import { WebCamComponent } from "../web-cam/web-cam.component";
import { ZXingScannerModule } from "@zxing/ngx-scanner";

@NgModule({
  declarations: [QrScanerComponent, WebCamComponent],
  imports: [RouterModule, CommonModule, FormsModule, ZXingScannerModule],
    exports: [QrScanerComponent, WebCamComponent]
})

export class QrScanerModule { }
