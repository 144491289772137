import { Person, JobView } from "../models";

export function fullDoctorName(job: JobView) {
  return fullName(job.doctorLastName, job.doctorFirstName, job.doctorMidleName);
};

export function fullPersonName(person: Person) {
  return fullName(person.lastName, person.firstName, person.middleName);
};

export function fullName(lastName: string, firstName: string, midleName: string) {
  return `${lastName || ''} ${firstName || ''} ${midleName || ''}`.trim();
}


