import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../../services/index';
import { NgForm } from '@angular/forms';

interface SliderItem {
  destop: string;
  mobile: string;
}

@Component({
  selector: 'slider',
  templateUrl: './slider.component.html'
})
export class SliderComponent {
  sliders: SliderItem[];
  current: SliderItem;
  currentSliderIndex: number;

  constructor() {
    this.currentSliderIndex = 0;
    this.sliders = [];
    this.sliders[0] = {
      destop: '../../../../../assets/Images/sliders/netflix1.png',
      mobile: '../../../../../assets/Images/sliders/netflix2.png'
    };
    this.sliders[1] = {
      destop: '../../../../../assets/Images/sliders/netflix2.png',
      mobile: '../../../../../assets/Images/sliders/netflix3.png'
    };
    this.sliders[2] = {
      destop: '../../../../../assets/Images/sliders/netflix3.png',
      mobile: '../../../../../assets/Images/sliders/netflix1.png'
    };
    this.sliders[3] = {
      destop: '../../../../../assets/Images/sliders/netflix2.png',
      mobile: '../../../../../assets/Images/sliders/netflix1.png'
    };
    this.sliders[4] = {
      destop: '../../../../../assets/Images/sliders/netflix3.png',
      mobile: '../../../../../assets/Images/sliders/netflix2.png'
    };
    this.current = this.sliders[this.currentSliderIndex];
  }

  selectSlideIndex = (index: number): void => {
    this.currentSliderIndex = index;
    this.updateCurrentSlider();
  }

  nextSlide = (): void => {
    this.currentSliderIndex++;
    if (this.currentSliderIndex >= this.sliders.length)
      this.currentSliderIndex = 0;
    this.updateCurrentSlider();
  }

  previousSlide = (): void => {
    this.currentSliderIndex--;
    if (this.currentSliderIndex < 0)
      this.currentSliderIndex = this.sliders.length-1;
    this.updateCurrentSlider();
  }

  private updateCurrentSlider = (): void => {
    this.current = this.sliders[this.currentSliderIndex];
  }
}
