import { Component, OnInit } from '@angular/core';
import { JobApiService } from '../../../../api/index';
import { FilteredGridRequestViewModel, BaseViewGridModel, JobViewModel, JobViewFilterModels } from '../../../../models/index';
import { HashTable, fullDoctorName, fullPersonName, fullName, Helper } from '../../../../Helper/index';
import { DatePipe } from '@angular/common';
import { IGridOptions, ControlType } from '../../../components/grid/grid.component';


class ExtJobView extends JobViewModel {
  doctorFullName: string;
  fulNumber: string;
  protez: string;
  enrichedDueDate: string;
  removableProtez: string;
}

@Component({
  selector: 'techincian',
  templateUrl: './techincian.component.html',
  providers: [DatePipe]
})
export class TechincianComponent implements OnInit {

  jobs: BaseViewGridModel<ExtJobView>;
  fieldOptions: HashTable<IGridOptions>;
  gridRequestModel: FilteredGridRequestViewModel<JobViewFilterModels>;
  helper: Helper;

  constructor(
    private service: JobApiService,
    private datePipe: DatePipe
  ) {
    this.helper = new Helper();
    this.jobs = {
      viewModelList: []
    } as BaseViewGridModel<ExtJobView>;

    this.fieldOptions = {
      fulNumber: {
        title: 'Номер наряда'
      },
      clinicName: {
        title: 'Клиника'
      },
      enrichedDueDate: {
        title: 'Срок'
      },
      protez: {
        title: 'Протез'
      },
      compulsory: {
        color: 'custom-color',
        hide: true
      },
      technic: {
        title: 'Техник',
        fieldType: {
          type: ControlType.customValue,
          value: (item: ExtJobView) =>
            fullName(item.technicLastName, item.technicFirstName, item.technicMidleName)

        }
      }
    };
    this.gridRequestModel = {} as FilteredGridRequestViewModel<JobViewFilterModels>;

  }

  ngOnInit() {
    this.initGrid();
  }

  initGrid = () => {
    this.service.getAllForDepartment(this.gridRequestModel).subscribe((data) => {
      this.jobs = { ...data } as BaseViewGridModel<ExtJobView>;
      this.jobs.viewModelList.forEach(s => {
        s.doctorFullName = fullDoctorName(s);

        let grouped: HashTable<string[]> = {};
        s.protezes.forEach((val) => {
          if (!grouped[val.protezName]) {
            grouped[val.protezName] = [];
          }
          grouped[val.protezName].push(val.protezName);
        });
        s.fulNumber = `${s.clinicPrefix}-${s.number}`;
        s.protez = Object.keys(grouped).map((key) => `${key}-${grouped[key].length}шт`).join('; ').trim();
        s.enrichedDueDate = this.transformDate(this.helper.convertUtcStringToLocaleDate(s.dueDate));

        let removableGrouped: HashTable<string[]> = {};
        let removable = s.protezes.filter((protez) => protez.protezTypeId == 1).forEach((val) => {
          if (!removableGrouped[val.protezName]) {
            removableGrouped[val.protezName] = [];
          }
          removableGrouped[val.protezName].push(val.protezName);
        });

        s.removableProtez = Object.keys(removableGrouped).map((key) => `${key}-${grouped[key].length}шт`).join('; ').trim();
      });
    });
  }

  transformDate = (date): string =>
    this.datePipe.transform(date, 'dd.MM.yyyy HH:mm');

}
