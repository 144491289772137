import { Component, EventEmitter, Output, OnChanges, SimpleChanges, OnInit, OnDestroy, Inject, Input, ViewChild, AfterViewInit, ContentChildren, QueryList } from '@angular/core';
import { Text } from '@angular/compiler/src/i18n/i18n_ast';
import { NgForm, NgModel } from '@angular/forms';

@Component({
  selector: 'modal-form-window',
  templateUrl: './modal-form-window.component.html'
})
export class ModalFormWindowComponent implements OnChanges, OnInit, OnDestroy, AfterViewInit  {

  @Output() closeDialogEvent = new EventEmitter<MouseEvent>();
  @Output() applyDialogEvent = new EventEmitter<MouseEvent>();
  @Input() isSaveVisible: boolean;
  @Input() applyButtonTitle: string;
  @Input() className: string;
  @Input() applyClassName: string;
  @Input() closeClassName: string; 

  @ViewChild(NgForm) public form: NgForm;
  @ContentChildren(NgModel) public models: QueryList<NgModel>;

  submited: boolean;

  constructor(@Inject("windowObject") private window: Window) {
    this.window.document.body.classList.add('show-dialog');
    this.isSaveVisible = false;
    this.applyButtonTitle = "Save";
    this.applyClassName = "btn-primary";
    this.closeClassName = "btn-primary";
    this.submited = false;
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {

  }

  ngAfterViewInit(): void {   
    let ngContentModels = this.models.toArray();
    ngContentModels.forEach((model) => {
      this.form.addControl(model);
    });
  }

  ngOnDestroy(): void {
    this.window.document.body.classList.remove('show-dialog');
  }

  close = (event: MouseEvent) => this.closeDialogEvent.emit(event);
  save = (event: MouseEvent) => this.applyDialogEvent.emit(event);

  submit = (event: MouseEvent, modelForm: NgForm) => {
    if (!modelForm.valid)
      return;

    this.applyDialogEvent.emit(event);
  }
}
