import { environment } from "../environments/environment";
export function NgLog(): ClassDecorator {
  return function (cls: any) {
    if (!environment.production) {
      // You can add/remove events for your needs
      const LIFECYCLE_HOOKS = [
        'ngOnInit',
        'ngOnChanges',
        'ngOnDestroy'
      ];
      const component = cls.name;

      LIFECYCLE_HOOKS.forEach(hook => {
        const original = cls.prototype[hook];

        cls.prototype[hook] = function (...args) {
          console.log(`%c ${component} - ${hook}`, `color: #4CAF50; font-weight: bold`, ...args);
          original && original.apply(this, args);
        }
      });
    }

  }
}
